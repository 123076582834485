import React, { useContext, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { Link, useHistory } from 'react-router-dom';
import { LoggedContext } from '../contexts/LoggedContext';
import { ToastContainer } from 'react-toastify';
import { Slide } from 'react-toastify';
import Footer from '../components/Footer/Footer';
import auth from "../services/auth";
import 'react-toastify/dist/ReactToastify.min.css';
import './LayoutLogged.css';

export default function LayoutLogged(props) {

  const history = useHistory();
  const context = useContext(LoggedContext);

  useEffect(() => {
    if (auth.getUsuario().role == 'gestor') {
      document.body.classList.add("modo-gestor");
    }
    return () => {
      document.body.classList.remove("modo-gestor");
    };
  }, []);

  function trocarDeEstamparia(event) {
    context.setEstamparia(
      context.estamparias.find(estamparia => estamparia.id == event.target.value)
    );
    const url = new URL(window.location.href);
    history.push('/gestor/trocar-estamparia?redirect=' + url.pathname);
  }

  return (
    <>
      <Helmet>
        <title>Avance Empreendedor</title>
      </Helmet>

      <ToastContainer
        position="top-center"
        draggable={false}
        hideProgressBar={true}
        closeOnClick={false}
        autoClose={4000}
        transition={Slide}
        theme="colored"
      />

      <div id="modalContainer"></div>

      <div className="container-fluid d-print-none" id="menu">
        {auth.getUsuario().role == 'gestor' && 
          <div className="row">
            <div className="col" id="navbarGestor">
              Olá, {auth.getUsuario().nome}, você está gerindo a  
              {context.estamparias && 
                <select 
                  id="selectEstamparias" 
                  value={context.estamparia.id} 
                  onChange={trocarDeEstamparia}
                >
                  {(context.estamparias||[]).map((estamparia, index) => (
                    <option value={estamparia.id} key={index}>
                      {estamparia.nome}
                    </option>
                  ))}
                </select>
              }
            </div>
          </div>
        }
        <div className="row">
          <div className="col">
            <nav className="navbar navbar-expand-lg navbar-dark">
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto">
                  <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown">
                      Ordens de Produção
                    </a>
                    <div className="dropdown-menu">
                      <Link className="dropdown-item" to="/op-estamparia">Estamparia</Link>
                      <Link className="dropdown-item" to="/op-tinta">Tintas</Link>
                    </div>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/cores">Cores</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/dashboard">Dashboard</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/calculadora/materia-prima">Calculadora</Link>
                  </li>
                  <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown">
                      Conta
                    </a>
                    <div className="dropdown-menu">
                      <Link className="dropdown-item" to="/configuracoes/empresa">Configurações</Link>
                      <Link className="dropdown-item" to="/logout">Sair</Link>
                    </div>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>

      <main className="container" id="main">
        <div className="row">
          <div className="col" id="routeContent">
            {context.estamparia.id > 0 ?
                props.children :
                <div>Selecione uma estamparia para gerir.</div>
            }
          </div>
        </div>
      </main>

      <Footer></Footer>
    </>
  );
}