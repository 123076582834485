import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import modalManager from '../../services/modalManager';
import ModalFornecedor from '../Fornecedor/ModalFornecedor';
import MateriaPrimaModel from '../../models/MateriaPrima';

export default function MateriaPrimaForm(props) {

  const [materiaPrima, setMateriaPrima] = useState({...MateriaPrimaModel});

  useEffect(() => {
    if (props.materiaPrima) {
      setMateriaPrima({...props.materiaPrima});
    }
  }, [props.materiaPrima]);

  async function save(e) {
    e.preventDefault();
    api.post('/materias-primas', materiaPrima)
      .then((response) => {
        if(props.onSaveSucess) props.onSaveSucess(response);
      })
      .catch((error) => {
        if (error.response.status < 500) {
          alert(error.response.data.description);
        } else {
          alert("Ocorreu um erro no servidor.");
        }
      });
  }

  function cancel() {
    if (props.onCancel) {
      props.onCancel();
    }
  }

  function onClickSelecionarFornecedor(e) {
    modalManager.mount(
      'modalFornecedor',
      <ModalFornecedor
        onSelect={onSelectFornecedor}
      />
    );
    modalManager.show('modalFornecedor');
  }

  function onSelectFornecedor(e, fornecedor) {
    materiaPrima.fornecedorId = fornecedor.id;
    materiaPrima.fornecedor = fornecedor;
    setMateriaPrima({...materiaPrima});
    modalManager.hide();
  }

  function onClickRemoverFornecedor(e, fornecedor) {
    materiaPrima.fornecedorId = null;
    materiaPrima.fornecedor = null;
    setMateriaPrima({...materiaPrima});
  }

  return (
    <>
      <form className="materiaprima-form" onSubmit={save}>
        <fieldset>
          <div className="form-row">
            <div className="form-group col-md-2">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="tipo"
                  id="pigmento-radio"
                  value="pigmento"
                  onChange={e => {
                    materiaPrima.tipo = e.target.value;
                    setMateriaPrima({ ...materiaPrima });
                  }}
                  checked={materiaPrima.tipo == 'pigmento'} />
                <label className="form-check-label" htmlFor="pigmento-radio">Pigmento</label>
              </div>
            </div>
            <div className="form-group col-md-2">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="tipo"
                  id="pasta-radio"
                  value="pasta"
                  onChange={e => {
                    materiaPrima.tipo = e.target.value;
                    setMateriaPrima({ ...materiaPrima });
                  }}
                  checked={materiaPrima.tipo == 'pasta'} />
                <label className="form-check-label" htmlFor="pasta-radio">Pasta</label>
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-12">
              <label>Código</label>
              <input
                type="text"
                className="form-control"
                value={materiaPrima.codigo}
                onChange={e => {
                  materiaPrima.codigo = e.target.value;
                  setMateriaPrima({ ...materiaPrima });
                }} />
            </div>
            <div className="form-group col-md-12">
              <label>Preço por Kg</label>
              <input
                type="text"
                className="form-control"
                value={materiaPrima.custo.toString().replace(/\./,',')}
                onChange={e => {
                  let v = e.target.value.replace(/\,/,'.');
                  materiaPrima.custo = isNaN(v) ? e.target.value : v;
                  setMateriaPrima({ ...materiaPrima });
                }}
                onBlur={e => {
                  if(isNaN(materiaPrima.custo)) {
                    alert('Preço inválido.');
                  }
                }}
              />
            </div>
            <div className="form-group col-md-12">
              <label>Lote</label>
              <input
                type="text"
                className="form-control"
                value={materiaPrima.lote}
                onChange={e => {
                  materiaPrima.lote = e.target.value;
                  setMateriaPrima({ ...materiaPrima });
                }} />
            </div>
            { ! materiaPrima.fornecedor && 
            <div className="form-group col-md-12">
              <button type="button" className="btn btn-secondary" onClick={onClickSelecionarFornecedor}>
                Selecionar Fornecedor
              </button>
            </div>
            }
            <div className="form-group col-md-12">
              {materiaPrima.fornecedor && 
                <table className="table table-striped table-sm">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Fornecedor</th>
                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{materiaPrima.fornecedor.nome}</td>
                      <td>
                        <button type="button" className="btn btn-secondary" onClick={onClickRemoverFornecedor}>
                          Remover
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              }
            </div>
          </div>
        </fieldset>
        <div className="btn-toolbar" role="toolbar">
          <div className="btn-group mr-2" role="group">
            <button type="button" className="btn btn-secondary" onClick={cancel}>
              Cancelar
            </button>
          </div>
          <div className="btn-group mr-2" role="group">
            <button type="submit" className="btn btn-primary">Salvar</button>
          </div>
        </div>
      </form>
    </>
  );
}