import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import api from '../../services/api';
import Pagination from '../../components/Pagination/Pagination';

export default function ClienteList({
  reload,
  filtros,
  onClickCadastrar /* (e) */, 
  onClickEditar /* (e, cliente) */,
  onSelect /* (e, cliente) */,
  showPageTitle = true
}) {

  const [pagina, setPagina] = useState(1);
  const [clientes, setClientes] = useState({
    data: [],
    current: 0,
    pages: 0,
    total: 0
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadClientes();
  }, [reload, pagina, filtros]);

  async function loadClientes() {
    let response;
    setIsLoading(true);
    try {
      response = await api.get(`/clientes?${buildSearchParams()}`);
    } catch (error) {
      setIsLoading(false);
      if (error.response.status < 500) {
        alert(error.response.data.description);
      } else {
        alert("Ocorreu um erro no servidor e não foi possível carregar os Clientes.");
      }
      return;
    }
    setIsLoading(false);
    setClientes(response.data);
  }

  function buildSearchParams() {
    const searchParams = new URLSearchParams();
    for (let filtro in filtros) {
      searchParams.set(filtro, filtros[filtro]);
    }
    searchParams.set('page', pagina);
    searchParams.set('limit', 15);
    return searchParams.toString();
  }

  function _onClickCadastrar(e) {
    if(onClickCadastrar) onClickCadastrar(e);
  }

  function _onClickEditar(e, cliente) {
    if(onClickEditar) onClickEditar(e, cliente);
  }

  function getPageTitle() {
    if (!showPageTitle) {
      return;
    }
    return <h2 className="page-title">Clientes</h2>;
  }

  return (
    <>
      {getPageTitle()}
      <p>
        <button type="button" className="btn btn-primary" onClick={e => _onClickCadastrar(e)}>
          Cadastrar Cliente
        </button>
      </p>
      <table className="table table-hover table-sm">
        <thead className="thead-dark">
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Nome</th>
            <th scope="col">Ações</th>
          </tr>
        </thead>
        <tbody>
          {isLoading &&
            <tr style={{padding:'20px', textAlign:'center'}}>
              <td colSpan="3">Carregando...</td>
            </tr>
          }
          {!isLoading && clientes.data.length == 0 &&
            <tr style={{padding:'20px', textAlign:'center'}}>
              <td colSpan="2">Não há clientes cadastrados ainda.</td>
            </tr>
          }
          {!isLoading && clientes.data.length > 0 && clientes.data.map((cliente, index) => (
            <tr key={index}>
              <td>{cliente.id}</td>
              <td>{cliente.nome}</td>
              <td>
                {onSelect &&
                  <button type="button" className="mr-1" onClick={e => onSelect(e, cliente)} title="Selecionar">
                    Selecionar
                  </button>
                }
                <button type="button" onClick={e => _onClickEditar(e, cliente)} title="Editar">
                  <i className="icofont-ui-edit"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {clientes.data.length > 0 &&
        <BrowserRouter>
          <Pagination
            workWithLocation={false}
            totalPages={clientes.pages}
            onNav={pg => {
              setPagina(pg);
            }}
          />
        </BrowserRouter>
      }
    </>
  );
}
