import React, { useContext, useState, useEffect } from 'react';
import appConfig from '../../AppConfig';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import useBodyClass from '../../hooks/body-class';
import LayoutLogged from '../LayoutLogged';
import InputTypeFloat from '../../components/Html/InputTypeFloat';
import InputTypeInt from '../../components/Html/InputTypeInt';
import api from '../../services/api';
import { LoggedContext } from '../../contexts/LoggedContext';
import Menu from './Menu';

export default function ConfiguracaoEmpresaPage() {

  const history = useHistory();
  const context = useContext(LoggedContext);
  const [savingFlag, setSavingFlag] = useState(false);
  const [configuracoes, setConfiguracoes] = useState({
    diasDeTrabalhoPorMes: 0,
    horasDiariasDeTrabalho: 0,
    quantidadeDeMaquinas: 0,
    custosFixosDaEmpresa: 0,
    aliquotaImposto: 0,
    lucroDesejado: 0
  });

  useBodyClass("page-configuracao");

  useEffect(() => {
    if (context.estamparia && context.estamparia.id > 0) {
      setConfiguracoes({ ...context.estamparia.configuracoes });
    }
  }, [context.estamparia]);

  async function handleSubmit(e) {
    e.preventDefault();
    setSavingFlag(true);
    const toastId = toast('Salvando...', {
      autoClose: false
    });
    let resp;
    try {
      resp = await api.post('/estamparias/configuracoes', configuracoes);
      setConfiguracoes({...resp.data.configuracoes});
    } catch (error) {
      let msg = (error.response.status < 500) 
        ? error.response.data.description
        : "Ocorreu um erro no servidor. Tente novamente ou entre em contato com o suporte.";
      toast.update(toastId, {
        render: msg,
        type: toast.TYPE.ERROR,
        autoClose: 10000
      });
      setSavingFlag(false);
      return;
    }
    try {
      await context.refreshEstamparia({...resp.data});
    } catch (error) {
      history.push(`${appConfig.baseURL}/logout`);
      return;
    }
    toast.update(toastId, {
      render: "Salvo com sucesso.",
      type: toast.TYPE.SUCCESS,
      autoClose: 4000,
    });
    setSavingFlag(false);
  }

  return (
    <LayoutLogged>
      <h2 className="page-title">Configurações</h2>
      <Menu active="producao" />
      <form onSubmit={handleSubmit}>
        <div className="form-group row">
          <label className="col-lg-3 col-form-label text-lg-right">Dias de Trabalho por Mês</label>
          <div className="col-lg-3">
            <InputTypeFloat
              className="form-control"
              value={configuracoes.diasDeTrabalhoPorMes}
              onChange={value => {
                configuracoes.diasDeTrabalhoPorMes = value;
                setConfiguracoes({ ...configuracoes });
              }}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-3 col-form-label text-lg-right">Horas Diárias de Trabalho</label>
          <div className="col-lg-3">
            <input 
              className="form-control"
              value={configuracoes.horasDiariasDeTrabalho}
              onChange={e => {
                configuracoes.horasDiariasDeTrabalho = e.target.value;
                setConfiguracoes({ ...configuracoes });
              }}
              type="time"
              style={{textAlign:'right'}}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-3 col-form-label text-lg-right">Quantidade de Máquinas</label>
          <div className="col-lg-3">
            <InputTypeInt
              className="form-control"
              value={configuracoes.quantidadeDeMaquinas}
              onChange={value => {
                configuracoes.quantidadeDeMaquinas = value;
                setConfiguracoes({ ...configuracoes });
              }}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-3 col-form-label text-lg-right">Custos Fixos da Empresa</label>
          <div className="col-lg-3 input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">R$</div>
            </div>
            <InputTypeFloat
              className="form-control"
              value={configuracoes.custosFixosDaEmpresa}
              onChange={value => {
                configuracoes.custosFixosDaEmpresa = value;
                setConfiguracoes({ ...configuracoes });
              }}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-3 col-form-label text-lg-right">Alíquota de Imposto</label>
          <div className="col-lg-3 input-group">
            <InputTypeFloat
              className="form-control"
              value={configuracoes.aliquotaImposto}
              onChange={value => {
                configuracoes.aliquotaImposto = value;
                setConfiguracoes({ ...configuracoes });
              }}
            />
            <div className="input-group-append">
              <div className="input-group-text">%</div>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-lg-3 col-form-label text-lg-right">Lucro Desejado</label>
          <div className="col-lg-3 input-group">
            <InputTypeFloat
              className="form-control"
              value={configuracoes.lucroDesejado}
              onChange={value => {
                configuracoes.lucroDesejado = value;
                setConfiguracoes({ ...configuracoes });
              }}
            />
            <div className="input-group-append">
              <div className="input-group-text">%</div>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-3"></div>
          <div className="col-lg-3">
            <button 
              disabled={savingFlag}
              type="submit" 
              className="btn btn-primary"
            >Salvar</button>
          </div>
        </div>
      </form>
    </LayoutLogged>
  );
}
