import React, { useState } from 'react';
import modalManager from '../../services/modalManager';
import MateriaPrimaList from './MateriaPrimaList';
import MateriaPrimaForm from './MateriaPrimaForm';
import MateriaPrimaModel from '../../models/MateriaPrima';

export default function ModalMateriaPrima(props) {

  const [materiaPrima, setMateriaPrima] = useState({ ...MateriaPrimaModel });
  const [reloadMateriasPrimas, setReloadMateriasPrimas] = useState(1);

  function openTab(id) {
    let tabs = ['materiaPrimaFormTab', 'materiasPrimasTab'];
    tabs.map(tab => document.getElementById(tab).style.display = 'none');
    document.getElementById(id).style.display = 'block';
  }

  return (
    <>
      <div className="modal fade" id="modalMateriaPrima" role="dialog" data-backdrop="static">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Matérias Primas</h5>
              <button type="button" className="close" onClick={modalManager.hide}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div id="materiasPrimasTab">
                <MateriaPrimaList
                  onSelect={props.onSelect}
                  onClickCadastrar={e => {
                    setMateriaPrima({...MateriaPrimaModel});
                    openTab('materiaPrimaFormTab');
                  }}
                  onClickEditar={(e, materiaPrima) => {
                    setMateriaPrima({...MateriaPrimaModel, ...materiaPrima });
                    openTab('materiaPrimaFormTab');
                  }}
                  reload={reloadMateriasPrimas}
                />
              </div>
              <div id="materiaPrimaFormTab" style={{ display: 'none' }}>
                <MateriaPrimaForm
                  onSaveSucess={() => {
                    setReloadMateriasPrimas(old => old + 1);
                    openTab('materiasPrimasTab');
                  }} 
                  onCancel={() => {
                    openTab('materiasPrimasTab');
                  }} 
                  materiaPrima={materiaPrima}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
