import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/logo2.png';
import './Footer.css';

export default function Footer(props) {
  return (
    <>
    <footer className="container-fluid d-print-none" id="footer">
      <div className="container">
        <div className="row" id="footer-content">
          <div className="col-12 col-sm-8 col-links">
            <ul className="links">
              <li>
                <a href="mailto:contato@avanceempreendedor.com.br" title="Fale conosco pelo e-mail">
                  <i className="icofont-email"></i>
                  contato@avanceempreendedor.com.br
                </a>
              </li>
              <li>
                <a href="https://www.avanceempreendedor.com.br" target="_blank" title="Ir para a página inicial da Avance">
                  <i className="icofont-web"></i>
                  www.avanceempreendedor.com.br
                </a>
              </li>
              <li>
                <a href="https://api.whatsapp.com/send?phone=5547992047594" target="_blank" title="Fale conosco pelo Whatsapp">
                  <i className="icofont-brand-whatsapp"></i>
                  (47) 99204-7594
                </a>
              </li>
            </ul>
          </div>
          <div className="col-12 col-sm-4 col-logo">
            <Link to="/" title="Ir para a página inicial do sistema">
              <img src={Logo} className="logo" width="130" />
            </Link>
          </div>
        </div>
      </div>
      <div className="faixa"></div>
    </footer>
    <footer className="container-fluid d-none d-print-block" id="footer-print">
      <img src={Logo} className="logo" width="130" />
    </footer>
    </>
  );
}