import Cliente from './Cliente';

const OrdemProducao = {
    id: 0,
    cliente: Cliente,
    dataEntrada: "",
    horaEntrada: "",
    referencia: "",
    quantidade: 0,
    cores: []
}

export default OrdemProducao;