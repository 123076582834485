import React, { useState, useEffect } from 'react';
import URLHelper from '../../helpers/url';
import api from '../../services/api';
import { ResponsiveBar } from '@nivo/bar';

export default function Rentabilidade({ filtros, metadados }) {

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    data: [],
    rentabilidadeTotalDoPeriodo: null
  });
  const [nivoBarConfig, setNivoBarConfig] = useState({
    keys: [],
    groupMode: 'grouped'
  });

  useEffect(() => {
    if (filtros) {
      loadData();
    }
  }, [filtros]);

  async function loadData() {
    let query = URLHelper.createQueryStringFromObject(filtros).toString();
    let resp;
    try {
      setIsLoading(true);
      resp = await api.get(`/relatorios/estamparia/rentabilidade?` + query);
    } catch (error) {
      setIsLoading(false);
      if (error.response.status < 500) {
        alert(error.response.data.description);
      } else {
        alert("Ocorreu um erro no servidor e não foi possível carregar a Rentabilidade. Por favor, tente novamente.");
      }
      return;
    }
    let resultado = [];
    if (filtros.agruparPor == 'mes') {
      let keys = [];
      Object.entries(resp.data.data).map(mes => {
        let item = {};
        item.mes = mes[0].substr(4,2) + '/' + mes[0].substr(0,4);
        (mes[1] || []).map(estamparia => {
          Object.entries(estamparia).map(i => {
            keys.push(i[0]);
            item[i[0]] = i[1];
          });
        });
        resultado.push(item);
      });
      setNivoBarConfig({
        keys: keys.filter((item, i, ar) => ar.indexOf(item) === i),
        groupMode: 'grouped',
        indexBy: filtros.agruparPor,
        margin: { top: 0, right: 130, bottom: 150, left: 60 },
        colors: { scheme: 'paired' },
        tooltip: ({ id, indexValue, value, color }) => {
          return <>
            <div style={{backgroundColor:color, display:'inline-block', height:'15px', marginRight:'10px', width:'20px'}}></div>
            {id} - {indexValue}: R$ {Number(value).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</>
        }
      });
    } else {
      setNivoBarConfig({
        keys: ['rentabilidade'],
        groupMode: 'grouped',
        indexBy: filtros.classificarPor,
        margin: { top: 0, right: 0, bottom: 150, left: 60 },
        colors: '#7C9EB6',
        tooltip: ({ indexValue, value}) => (
          <>{indexValue}: R$ {Number(value).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</>
        )
      });
      resultado = orderByRentabilidadeDesc(resp.data.data);
    }
    resp.data.data = resultado;
    setData(resp.data);
    setIsLoading(false);
  }

  function orderByRentabilidadeDesc(data) {
    return (data || []).sort((a, b) => {
      if (a.rentabilidade > b.rentabilidade) return -1;
      if (a.rentabilidade < b.rentabilidade) return 1;
      return 0;
    })
  }

  return (
    <>
      <h3 className="dashboard-title">Rentabilidade</h3>
      <div className="dashboard-chart-description">
        {metadados.filtrosDescricao}<br />
        Rentabilidade total do período: {Number(data.rentabilidadeTotalDoPeriodo).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
      </div>
      <div style={{ height: '500px' }}>
        {isLoading &&
          <div>Carregando gráfico...</div>
        }
        {!isLoading && data.data.length == 0 &&
          <div>Não há dados para formar o gráfico com os filtros que você usou.</div>
        }
        {!isLoading && data.data.length > 0 &&
          <ResponsiveBar
            data={data.data}
            keys={nivoBarConfig.keys}
            groupMode={nivoBarConfig.groupMode}
            indexBy={nivoBarConfig.indexBy}
            margin={nivoBarConfig.margin}
            padding={0.3}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={nivoBarConfig.colors}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            labelFormat={v => `R$ ${Number(v).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`}
            legends={[
              {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemOpacity: 1
                    }
                  }
                ]
              }
            ]}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: -45,
              legend: '',
              legendPosition: 'middle',
              legendOffset: 32
            }}
            axisLeft={{
              format: value =>
                `R$ ${Number(value).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                })}`,
            }}
            tooltip={nivoBarConfig.tooltip}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor="blacks"
            animate={true}
            motionStiffness={90}
            motionDamping={15}
          />
        }
      </div>
    </>
  );
}