import Cliente from './Cliente';
import MaquinaBase from './Maquina';
import Operador from './Operador';

const OrdemProducao = {
    id: 0,
    cliente: Cliente,
    lacrePiloto: "",
    notaFiscal: "",
    referencia: "",
    entrada: "",
    previsaoEntrega: "",
    entrega: "",
    projecaoProdutividadePorHora: 0,
    quantidadeQuadros: "",
    ofCliente: "",
    marca: "",
    quantidade: 0,
    precoEstampa: "",
    pastas: [],
    quadro: "",
    informacoesProducao: "",
    maquinas: [],
    paradas: [],
    estatisticasParadas: {},
    pecasSegundaQualidade: [],
    status: 'aberta'
}

const Pasta = {
    id: 0,
    corPeca: "",
    tecnica: "",
    cores: [],
    cura: ""
}

const Parada = {
    id: 0,
    tipo: "",
    dataInicio: "",
    horaInicio: "",
    dataFim: "",
    horaFim: "",
    motivo: ""
}

const PecasSegundaQualidade = {
    tamanho: "",
    quantidade: 0
}

const Maquina = {
    ...MaquinaBase,
    quantidadePecas: 0,
    disponibilidades: []
}

const Disponibilidade = {
    id: 0,
    inicio: '',
    fim: '',
    tempo: '',
    eventos: []
}

const Evento = {
    id: 0,
    tipo: '',
    inicio: '',
    fim: '',
    motivo: '',
    tempo: '',
    operador: { ...Operador }
}

export { 
    OrdemProducao, 
    Pasta,
    Parada,
    PecasSegundaQualidade,
    Maquina,
    Disponibilidade,
    Evento,
};