import React from 'react';
import appConfig from '../../AppConfig';
import ClienteList from '../../components/Cliente/ClienteList';

export default function ClienteListPage({ history }) {

  function onClickCadastrar(e) {
    history.push('/clientes/form');
  }

  function onClickEditar(e, cliente) {
    history.push(`${appConfig.baseURL}/clientes/form/${cliente.id}`);
  }

  return (
    <ClienteList
      onClickCadastrar={onClickCadastrar}
      onClickEditar={onClickEditar}
    />
  );
}