import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import numberFormat from '../../helpers/numberFormat';
import ModalMateriaPrima from '../../components/MateriaPrima/ModalMateriaPrima';
import InputTypeFloat from '../../components/Html/InputTypeFloat';
import modalManager from '../../services/modalManager';
import CorModel from '../../models/Cor';
import IngredienteModel from '../../models/Ingrediente';
import RemoveIngredienteDialog from './RemoveIngredienteDialog';
import './CorForm.css';

export default function CorForm(props) {

  const [cor, setCor] = useState({...CorModel});

  useEffect(() => {
    if (props.cor) {
      setCor({...props.cor});
    }
  }, [props.cor]);

  function removeIngredienteDaCor(ingrediente) {
    modalManager.mount(
      'removeIngredienteDialog',
      <RemoveIngredienteDialog
        ingrediente={ingrediente}
        onConfirm={e => {
          let ingredienteIdx = cor.ingredientes.indexOf(ingrediente);
          cor.ingredientes.splice(ingredienteIdx, 1);
          setCor({...cor});
          modalManager.hide();
        }}
        onCancel={e => {
          modalManager.hide();
        }}
      />
    );
    modalManager.show('removeIngredienteDialog');
  }

  function showModalMateriaPrima() {
    modalManager.mount(
      'modalMateriaPrima',
      <ModalMateriaPrima
        onSelect={(e, materiaPrima) => {
          let ingrediente = { ...IngredienteModel };
          ingrediente.materiaPrima = { ...materiaPrima };
          cor.ingredientes = [...cor.ingredientes, ingrediente];
          setCor({ ...cor });
          modalManager.hide();
        }}
      />
    );
    modalManager.show('modalMateriaPrima');
  }

  function getQuantidadeTotalIngredientes() {
    return (cor.ingredientes||[]).reduce((acc, ingrediente) => 
      acc + (isNaN(ingrediente.quantidade) ? 0 : parseFloat(ingrediente.quantidade)), 0
    );
  }

  function getCustoTotalIngredientes() {
    return (cor.ingredientes||[]).reduce((acc, ingrediente) => {
      let custo = ingrediente.quantidade * ingrediente.materiaPrima.custo;
      return acc + (isNaN(custo) ? 0 : parseFloat(custo));
    }, 0);
  }

  function getCustoTotalPorQuilo() {
    let custo = (cor.ingredientes||[]).reduce((acc, ingrediente) => {
      let custo = ingrediente.quantidade * ingrediente.materiaPrima.custo;
      return acc + (isNaN(custo) ? 0 : parseFloat(custo));
    }, 0);
    return custo / getQuantidadeTotalIngredientes();
  }

  function changeQuantidade(value, ingrediente) {
    let qtd = value;
    ingrediente.quantidade = qtd;
    ingrediente.custo = isNaN(qtd) ? 0 : qtd * ingrediente.materiaPrima.custo;
    let custoIngredientes = 0;
    let quantidadeIngredientes = 0;
    (cor.ingredientes||[]).map(ingrediente => {
      custoIngredientes += !isNaN(ingrediente.custo) ? parseFloat(ingrediente.custo) : 0;
      quantidadeIngredientes += !isNaN(ingrediente.quantidade) ? parseFloat(ingrediente.quantidade) : 0;
    });
    cor.custo = custoIngredientes / (quantidadeIngredientes == 0 ? 1 : quantidadeIngredientes);
    setCor({ ...cor });
  }

  async function onSubmit(e) {
    e.preventDefault();
    if ( ! validaForm()) {
      return;
    }
    let resp;
    try {
      resp = await api.post('/cores', cor);
    } catch (error) {
      if (error.response.status < 500) {
        alert(error.response.data.description);
      } else {
        alert("Ocorreu um erro no servidor.");
      }
      return;
    }
    if (props.onSaveSucess) {
      props.onSaveSucess(e, resp);
    }
  }

  function validaForm() {
    if (cor.codigo == "") {
      alert('Preencha o código da cor.');
      return false;
    }
    if (cor.ingredientes.length == 0) {
      alert('Escolha as matérias-primas que compçõe esta cor.');
      return false;
    }
    const pastas = (cor.ingredientes||[]).filter(ingrediente => ingrediente.materiaPrima.tipo == 'pasta');
    if (pastas.length == 0) {
      alert('Deve haver pelo menos uma pasta na lista de matérias-primas.');
      return false;
    }
    return true;
  }

  return (
    <div className="corform-component">
      <h2 className="page-title">Cadastrar Cor:</h2>
      <div>
        <form className="cor-form" onSubmit={onSubmit}>
          <div className="form-group">
            <label>Código da Cor:</label>
            <input
              type="text"
              className="form-control"
              value={cor.codigo}
              onChange={e => {
                cor.codigo = e.target.value;
                setCor({ ...cor });
              }}
              maxLength="50"
            />
          </div>
          <div className="form-group mt-5">
            <button type="button" className="btn btn-secondary" onClick={e => showModalMateriaPrima()}>
              Escolher Matéria Prima
            </button>
          </div>
          <div className="form-group mb-3">

            <table className="table table-hover table-sm">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Cód.</th>
                  <th scope="col">Tipo</th>
                  <th scope="col">Lote</th>
                  <th scope="col">Custo/kg</th>
                  <th scope="col" style={{width:'140px'}}>Quantidade (kg)</th>
                  <th scope="col" style={{width:'140px'}}>Custo</th>
                  <th scope="col">Ação</th>
                </tr>
              </thead>
              <tbody>
                {cor.ingredientes.length == 0
                  ? <tr>
                    <td colSpan="7" style={{padding:'20px', textAlign:'center'}}>
                      Escolha as matérias-primas desta cor clicando no botão acima.
                    </td>
                  </tr>
                  : cor.ingredientes.map((ingrediente, index) => (
                      <tr key={index}>
                        <td>{ingrediente.materiaPrima.codigo}</td>
                        <td>{ingrediente.materiaPrima.tipo}</td>
                        <td>{ingrediente.materiaPrima.lote}</td>
                        <td>R$ {numberFormat(ingrediente.materiaPrima.custo)}</td>
                        <td>
                          <InputTypeFloat
                            style={{width: '120px'}}
                            className="form-control"
                            value={ingrediente.quantidade}
                            fixed={5}
                            onChange={value => {
                              changeQuantidade(value, ingrediente);
                            }}
                            onBlur={e => {
                              if (ingrediente.quantidade <= 0) {
                                alert('A quantidade deve ser maior que 0.');
                              }
                            }}
                          />
                        </td>
                        <td>
                          R$ {numberFormat(ingrediente.quantidade * ingrediente.materiaPrima.custo)}
                        </td>
                        <td>
                          <button type="button" onClick={e => removeIngredienteDaCor(ingrediente)}>
                            <i className="icofont-ui-delete"></i>
                          </button>
                        </td>
                      </tr>
                  ))}
              </tbody>
              {cor.ingredientes.length > 0 &&
                <tfoot>
                  <tr>
                    <td colSpan="4" style={{textAlign:"right"}}>Subtotal:</td>
                    <td>
                      {new Intl.NumberFormat(['pt-BR'], {minimumFractionDigits: 5}).format(
                        getQuantidadeTotalIngredientes())
                      } Kg
                    </td>
                    <td>R$ {numberFormat(getCustoTotalIngredientes())}</td>
                    <td></td>
                  </tr>
                </tfoot>
              }
            </table>
          </div>

          <div className="form-group">
            <div className="total">
              CUSTO TOTAL POR QUILO: <strong>R$ {numberFormat(getCustoTotalPorQuilo())}</strong>
            </div>
          </div>

          <button type="submit" className="btn btn-primary">Salvar</button>
        </form>
      </div>
    </div>
  );
}