import React, { useState } from 'react';
import modalManager from '../../services/modalManager';
import CorModel from '../../models/Cor';
import numberFormat from '../../helpers/numberFormat';
import InputTypeFloat from '../../components/Html/InputTypeFloat';

export default function ModalEditarCorSelecionada(props) {

  const [cor, setCor] = useState({
    ...CorModel, 
    quantidade:0,
    quantidadeRetornada:0,
    ...props.cor
  });

  function confirm(e) {
    if (props.onConfirm) {
      props.onConfirm(e, cor);
    }
  }

  function cancel(e) {
    if (props.onCancel) {
      props.onCancel(e);
    }
  }

  function quantidadeDeCorUtilizada() {
    return parseFloat(cor.quantidade) - parseFloat(cor.quantidadeRetornada);
  }

  function calculaQuantidadeIngrediente(ingrediente) {
    let quantidade = quantidadeDeCorUtilizada() * ingrediente.quantidadePorQuilo;
    return isNaN(quantidade) ? 0 : quantidade;
  }

  function calculaCustoIngrediente(ingrediente) {
    let custo = quantidadeDeCorUtilizada() * ingrediente.quantidadePorQuilo * ingrediente.materiaPrima.custo;
    return isNaN(custo) ? 0 : custo;
  }

  function calculaCustoDaCor() {
    let quantidade = quantidadeDeCorUtilizada();
    let custo = 0;
    (cor.ingredientes||[]).map(ingrediente => {
      custo += quantidade * ingrediente.quantidadePorQuilo * ingrediente.materiaPrima.custo;
    });
    cor.custo = isNaN(custo) ? 0 : custo;
  }

  return (
    <div className="modal fade" id="modalEditarCorSelecionada" role="dialog" data-backdrop="static">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Cor Selecionada</h5>
            <button type="button" className="close" onClick={modalManager.hide}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={confirm}>
              <fieldset>
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label>Código</label>
                    <input
                      type="text"
                      className="form-control"
                      value={cor.codigo}
                      readOnly />
                  </div>
                  <div className="form-group col-md-4">
                    <label>Quantidade em kg</label>
                    <InputTypeFloat
                      className="form-control"
                      value={cor.quantidade}
                      onChange={value => {
                        cor.quantidade = value;
                        setCor({...cor});
                      }}
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label>Quant. Retornada em kg</label>
                    <InputTypeFloat
                      className="form-control"
                      value={cor.quantidadeRetornada}
                      onChange={value => {
                        cor.quantidadeRetornada = value;
                        setCor({...cor});
                      }}
                    />
                  </div>
                </div>
              </fieldset>
              <strong>Matérias Primas:</strong>
              <table className="table table-hover table-sm">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Código</th>
                    <th scope="col">Tipo</th>
                    <th scope="col">Quantidade/kg</th>
                    <th scope="col">Quantidade</th>
                    <th scope="col">Custo/kg</th>
                    <th scope="col">Custo</th>
                  </tr>
                </thead>
                <tbody>
                {(cor.ingredientes||[]).map((ingrediente, index) => (
                  <tr key={index}>
                    <td>{ingrediente.materiaPrima.codigo}</td>
                    <td>{ingrediente.materiaPrima.tipo}</td>
                    <td>{numberFormat(ingrediente.quantidadePorQuilo)} kg</td>
                    <td>{numberFormat(calculaQuantidadeIngrediente(ingrediente))} kg</td>
                    <td>R$ {numberFormat(ingrediente.materiaPrima.custo)}</td>
                    <td>R$ {numberFormat(calculaCustoIngrediente(ingrediente))}</td>
                  </tr>
                ))}
                </tbody>
              </table>
              <div className="btn-toolbar" role="toolbar">
                <div className="btn-group mr-2" role="group">
                  <button type="button" className="btn btn-secondary" onClick={cancel}>
                    Cancelar
                  </button>
                </div>
                <div className="btn-group mr-2" role="group">
                  <button type="submit" className="btn btn-primary">Selecionar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}