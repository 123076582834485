import React, { useState, useEffect, useContext } from 'react';
import useBodyClass from '../../hooks/body-class';
import { Helmet } from "react-helmet";
import config from '../../AppConfig';
import './IndexPage.css';
import { LoggedContext } from '../../contexts/LoggedContext';
import LayoutLogged from '../LayoutLogged';
import api from '../../services/api';
import SearchForm from '../../components/Dashboard/SearchForm';
import TicketMedio from './TicketMedio';
import Faturamento from './Faturamento';
import Producao from './Producao';
import Rentabilidade from './Rentabilidade';
import Produtividade from './Produtividade';
import EficienciaOperacional from './EficienciaOperacional';
import SetupPorQuadro from './SetupPorQuadro';
import $ from 'jquery';
import AProduzir from './AProduzir';

export default function IndexPage() {

  const context = useContext(LoggedContext);
  const [clientes, setClientes] = useState();
  const [maquinas, setMaquinas] = useState();
  const [tabs, setTabs] = useState([]);

  useBodyClass("page-dashboard");

  useEffect(() => {
    loadClientes();
  }, []);

  useEffect(() => {
    ativaUltimaTab();
  }, [tabs]);

  async function loadClientes() {
    let resp;
    try {
      resp = await api.get(`/clientes`);
    } catch (error) {
      if (error.response.status < 500) {
        alert(error.response.data.description);
      } else {
        alert("Ocorreu um erro no servidor e não foi possível carregar os Clientes. Por favor, tente novamente.");
      }
      return;
    }
    setClientes(resp.data.data);
  }

  function addTab(titulo, grafico) {
    setTabs([...tabs, { id: (new Date()).getTime(), titulo, grafico }]);
  }

  function closeTab(e, index) {
    e.preventDefault();
    setTabs(current => current.filter((tab, i) => i !== index));
  }

  function ativaUltimaTab() {
    $('#dashboardTabs li:last-child a').tab('show');
  }

  return (
    <LayoutLogged>

      <Helmet>
        <title>Dashboard {config.pageTitleDefault}</title>
      </Helmet>

      <h2 className="page-title d-print-none">Dashboard</h2>
      <SearchForm
        onApply={(filtros, metadados) => {
          let g;
          if (metadados.relatorio == 'ticketMedio') {
            g = <TicketMedio filtros={filtros} metadados={metadados} />;
          } else if (metadados.relatorio == 'faturamento') {
            g = <Faturamento filtros={filtros} metadados={metadados} />;
          } else if (metadados.relatorio == 'producao') {
            g = <Producao filtros={filtros} metadados={metadados} />;
          } else if (metadados.relatorio == 'aProduzir') {
            g = <AProduzir filtros={filtros} metadados={metadados} />;
          } else if (metadados.relatorio == 'rentabilidade') {
            g = <Rentabilidade filtros={filtros} metadados={metadados} />;
          } else if (metadados.relatorio == 'produtividade') {
            g = <Produtividade filtros={filtros} metadados={metadados} />;
          } else if (metadados.relatorio == 'eficienciaOperacional') {
            g = <EficienciaOperacional filtros={{...filtros}} metadados={{...metadados}} />;
          } else if (metadados.relatorio == 'setupPorQuadro') {
            g = <SetupPorQuadro filtros={{...filtros}} metadados={{...metadados}} />;
          }
          addTab(metadados.relatorioTitulo, g);
        }}
        clientes={clientes}
        maquinas={context.maquinasCache}
      />
      {tabs.length > 0 &&
        <>
          <ul className="nav nav-tabs d-print-none" id="dashboardTabs" role="tablist">
            {(tabs || []).map((tab, index) => (
              <li key={index} className="nav-item">
                <a className={`nav-link`} data-toggle="tab" href={`#tab-` + index} role="tab">
                  {tab.titulo}
                  <button type="button" onClick={e => closeTab(e, index)}>
                    <i className="icofont-close-circled"></i>
                  </button>
                </a>
              </li>
            ))}
          </ul>
          <div className="tab-content pt-4" id="dashboardTabsContent">
            {(tabs || []).map((tab, index) => (
              <div key={tab.id} className={`tab-pane`} id={`tab-` + index} role="tabpanel">
                {tab.grafico}
              </div>
            ))}
          </div>
        </>
      }
    </LayoutLogged>
  );
}