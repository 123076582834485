const Cor = {
    id: 0,
    _corId: 0,
    codigo: "",
    unidadeMedida: "",
    quantidade: 0,
    quantidadeRetornada: 0,
    custoPorQuilo: 0,
    ingredientes: []
}

export default Cor;