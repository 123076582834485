import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import numberFormat from '../../helpers/numberFormat';

export default function MateriaPrimaList(props) {

  const [materiasPrimas, setMateriasPrimas] = useState([]);
  const [loadMsg, setLoadMsg] = useState("Aguarde, carregando...");

  useEffect(() => {
    loadMateriasPrimas();
  }, [props.reload]);

  async function loadMateriasPrimas() {
    let resp;
    try {
      resp = await api.get(`/materias-primas`);
    } catch(error) {
      if (error.response.status < 500) {
        alert(error.response.data.description);
      } else {
        alert("Ocorreu um erro no servidor e não foi possível carregar as matérias-primas.");
      }
      return;
    }
    if (resp.data.length == 0) {
      setLoadMsg('Não há matérias-primas cadastradas ainda.');
    }
    setMateriasPrimas(resp.data);
  }

  return (
    <>
      <button 
        type="button" 
        className="btn btn-secondary mb-2" 
        onClick={props.onClickCadastrar}>
        Cadastrar Nova
      </button>

      <table className="table table-striped table-sm">
        <thead className="thead-dark">
          <tr>
            <th scope="col">Código</th>
            <th scope="col">Custo</th>
            <th scope="col">Tipo</th>
            <th scope="col">Fornecedor</th>
            <th scope="col">Ações</th>
          </tr>
        </thead>
        <tbody>
          {materiasPrimas.map((materiaPrima, index) => (
            <tr key={index}>
              <td>
                <strong>{materiaPrima.codigo}</strong><br />
                <small>{materiaPrima.lote}</small>
              </td>
              <td>R$ {numberFormat(materiaPrima.custo)}</td>
              <td>{materiaPrima.tipo}</td>
              <td>{materiaPrima.fornecedor && materiaPrima.fornecedor.nome}</td>
              <td>
                {props.onSelect && 
                  <button 
                    title="Selecionar"
                    className="mr-1" 
                    onClick={e => props.onSelect(e, materiaPrima)}>
                      <i className="icofont-hand-drag1"></i>
                  </button>
                }
                <button 
                  title="Editar"
                  onClick={e => props.onClickEditar(e, materiaPrima)}>
                    <i className="icofont-ui-edit"></i>
                </button>
              </td>
            </tr>
          ))}
          {materiasPrimas.length == 0 && 
            <tr>
              <td colSpan="5" style={{padding:'20px', textAlign:'center'}}>
                {loadMsg}
              </td>
            </tr>
          }
        </tbody>
      </table>
    </>
  );
}