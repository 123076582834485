import React from 'react';
import { Link } from "react-router-dom";

export default function Menu({ active }) {
  return (
    <ul className="nav nav-pills" role="tablist">
      <li className="nav-item">
        <Link 
          className={`nav-link ${active == 'producao' ? 'active' : ''}`} 
          to={location => `/configuracoes/empresa`}>
          Empresa
        </Link>
      </li>
      <li className="nav-item">
        <Link 
          className={`nav-link ${active == 'maquinas' ? 'active' : ''}`} 
          to={location => `/configuracoes/maquinas`}>
          Máquinas
        </Link>
      </li>
      <li className="nav-item">
        <Link 
          className={`nav-link ${active == 'operadores' ? 'active' : ''}`} 
          to={location => `/configuracoes/operadores`}>
          Operadores
        </Link>
      </li>
    </ul>
  );
}