import React from 'react';
import moment from 'moment';
import numberFormat from '../../helpers/numberFormat';

export default function Apontamentos(props) {

  const ordem = props.ordem;

  function estatisticasDasParadas() {
    let out = '';
    for (let tipo in ordem.estatisticasParadas) {
      out += (tipo === 'parada_programada')
        ? '<strong>PARADAS PROGRAMADAS (' + ordem.estatisticasParadas[tipo]['tempo'] + ' / ' + parseFloat(ordem.estatisticasParadas[tipo]['porcentagem']).toFixed(2) + '%)</strong>: <br />'
        : '<strong>PARADAS NÃO PROGRAMADAS (' + ordem.estatisticasParadas[tipo]['tempo'] + ' / ' + parseFloat(ordem.estatisticasParadas[tipo]['porcentagem']).toFixed(2) + '%)</strong>: <br />';
      for (let motivo in ordem.estatisticasParadas[tipo]['motivos']) {
        out += ordem.estatisticasParadas[tipo]['motivos'][motivo]['legenda'] + ' (';
        out += ordem.estatisticasParadas[tipo]['motivos'][motivo]['tempo'] + ' / ';
        out += parseFloat(ordem.estatisticasParadas[tipo]['motivos'][motivo]['porcentagem']).toFixed(2) + '%';
        out += ') <br />';
      }
    }
    return (
      <div dangerouslySetInnerHTML={{ __html: out }} />
    );
  }

  return (
    <>
      <table className="table table-striped opestampariaapontamentos-table">
        <tbody>
          <tr>
            <th scope="row">Cliente</th>
            <td>{ordem.cliente && ordem.cliente.nome}</td>
          </tr>
          <tr>
            <th scope="row">O. F. Cliente</th>
            <td>{ordem.ofCliente}</td>
          </tr>
          <tr>
            <th scope="row">Marca</th>
            <td>{ordem.marca}</td>
          </tr>
          <tr>
            <th scope="row">Referência</th>
            <td>{ordem.referencia}</td>
          </tr>
          <tr>
            <th scope="row">Entrada</th>
            <td>{ordem.entrada ? moment(ordem.entrada).format('DD/MM/YYYY HH:mm') : ''}</td>
          </tr>
          <tr>
            <th scope="row">Previsão de Entrega</th>
            <td>{ordem.previsaoEntrega ? moment(ordem.previsaoEntrega).format('DD/MM/YYYY HH:mm') : ''}</td>
          </tr>
          <tr>
            <th scope="row">Entrega</th>
            <td>{ordem.entrega ? moment(ordem.entrega).format('DD/MM/YYYY HH:mm') : ''}</td>
          </tr>
          <tr>
            <th scope="row">Lead Time</th>
            <td>{ordem.leadTime && parseFloat(ordem.leadTime).toString().replace(/\./,',') + ' dias'}</td>
          </tr>
          <tr>
            <th scope="row">Preço da Estampa</th>
            <td>R$ {numberFormat(ordem.precoEstampa)}</td>
          </tr>
          <tr>
            <th scope="row">Quantidade de Peças</th>
            <td>{ordem.quantidade}</td>
          </tr>
          <tr>
            <th scope="row">Máquinas</th>
            <td>{(ordem.maquinas||[]).map(maquina => maquina.nome).join(', ')}</td>
          </tr>
          <tr>
            <th scope="row">Quantidade de Quadros</th>
            <td>{ordem.quantidadeQuadros}</td>
          </tr>
          <tr>
            <th scope="row">Quantidade de Cores</th>
            <td>{ordem.quantidadeCores}</td>
          </tr>
          <tr>
            <th scope="row">Cores</th>
            <td>
              {(ordem.pastas||[]).map(pasta => (
                (pasta.cores||[]).map((cor,index) => (
                  <div key={index} className="cor">
                    {cor.codigo} (Lote: {(cor.ingredientes || []).map((ingrediente, index) => {
                      return (index == (cor.ingredientes.length-1)) 
                        ? ingrediente.materiaPrima.lote
                        : ingrediente.materiaPrima.lote + ', ';
                    })})<br />
                    <small>
                      Quantidade: {numberFormat(cor.quantidade)} {cor.unidadeMedida} | 
                      Retornado: {numberFormat(cor.quantidadeRetornada)} {cor.unidadeMedida} | 
                      Custo: {numberFormat(cor.custoTotal)}
                    </small>
                  </div>
                ))
              ))}
            </td>
          </tr>
          <tr>
            <th scope="row">Custo das Cores por peça</th>
            <td>R$ {numberFormat(ordem.custoCores / (ordem.quantidade||1))}</td>
          </tr>
          <tr>
            <th scope="row">Custo total das Cores</th>
            <td>R$ {numberFormat(ordem.custoCores)}</td>
          </tr>
          <tr>
            <th scope="row">Tempo de Setup</th>
            <td>{ordem.tempoSetup} ({numberFormat(ordem.tempoSetupPorcentagem)}% da produção + setup + paradas)</td>
          </tr>
          <tr>
            <th scope="row">Início da Produção</th>
            <td>{ordem.inicioProducao ? moment(ordem.inicioProducao).format('DD/MM/YYYY HH:mm') : ''}</td>
          </tr>
          <tr>
            <th scope="row">Fim da Produção</th>
            <td>{ordem.fimProducao ? moment(ordem.fimProducao).format('DD/MM/YYYY HH:mm') : ''}</td>
          </tr>
          <tr>
            <th scope="row">Tempo de Produção</th>
            <td>{ordem.tempoProducao}</td>
          </tr>
          <tr>
            <th scope="row">Peças de 2ª Qualidade</th>
            <td>
              {ordem.pecasSegundaQualidadeQuantidade} {ordem.pecasSegundaQualidadePorcentagem && '(' + numberFormat(ordem.pecasSegundaQualidadePorcentagem) + '%)'}
            </td>
          </tr>
          <tr>
            <th scope="row">Tempo de Parada</th>
            <td>
              {ordem.tempoParada} {ordem.tempoParadaPorcentagem && '(' + numberFormat(ordem.tempoParadaPorcentagem) + '% da produção + setup + paradas)'}
              <br /><small>{estatisticasDasParadas()}</small>
            </td>
          </tr>
          <tr>
            <th scope="row">Peças por Hora de Trabalho</th>
            <td>{ordem.pecasPorHoraTrabalho}</td>
          </tr>
          <tr>
            <th scope="row">Faturamento por Hora de Trabalho</th>
            <td>R$ {numberFormat(ordem.faturamentoPorHoraTrabalho)}</td>
          </tr>
          <tr>
            <th scope="row">Faturamento Total</th>
            <td>R$ {numberFormat(ordem.faturamento)}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
}