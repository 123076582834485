import React from 'react';
import appConfig from '../../AppConfig';
import FornecedorList from '../../components/Fornecedor/FornecedorList';

export default function ListPage({ history }) {

  function onClickCadastrar(e) {
    history.push('/fornecedores/form');
  }

  function onClickEditar(e, fornecedor) {
    history.push(`${appConfig.baseURL}/fornecedores/form/${fornecedor.id}`);
  }

  return (
    <FornecedorList
      onClickCadastrar={onClickCadastrar}
      onClickEditar={onClickEditar}
    />
  );
}
