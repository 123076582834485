import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from "react-router-dom";
import moment from 'moment';
import { LoggedContext } from '../../contexts/LoggedContext';
import api from '../../services/api';
import LayoutLogged from '../LayoutLogged';

export default function ListPage({ history }) {

  const context = useContext(LoggedContext);
  const [ordens, setOrdens] = useState([]);
  const [loadMsg, setLoadMsg] = useState("Aguarde, carregando...");

  useEffect(() => {
    loadOrdens();
  }, []);

  async function loadOrdens() {
    let resp;
    try {
      resp = await api.get('/op-tinta');
    } catch(error) {
      if (error.response.status < 500) {
        alert(error.response.data.description);
      } else {
        alert("Ocorreu um erro no servidor e não foi possível carregar as ordens de producação da tinta. Por favor, tente novamente.");
      }
      return;
    }
    if (resp.data.length == 0) {
      setLoadMsg("Não há Ordens de Produção cadastradas ainda.");
    }
    setOrdens(resp.data);
  }

  return (
    <LayoutLogged>
      <h2 className="page-title">Ordens de Produção de Tintas</h2>
      <p>
        <button type="button" className="btn btn-primary" 
          onClick={e => history.push('/op-tinta/form')}>
          Cadastrar nova Ordem
        </button>
      </p>
      <div className="table-container-scroll mb-4">
        <table className="table table-striped table-sm">
          <thead className="sticky thead-dark">
            <tr>
              <th scope="col">Ações</th>
              <th scope="col">Id</th>
              <th scope="col">Data Entrada</th>
              <th scope="col">Referência</th>
              <th scope="col">Cliente</th>
            </tr>
          </thead>
          <tbody>
            {ordens.map((ordem, index) => (
              <tr key={index}>
                <td className="sticky">
                  <Link className="btn btn-outline-secondary mr-1" to={location => `${location.pathname}/${ordem.id}/form`}>
                    <i className="icofont-ui-edit"></i>
                  </Link>
                  <Link className="btn btn-outline-secondary" to={location => `${location.pathname}/${ordem.id}/print`}>
                    <i className="icofont-printer"></i>
                  </Link>
                </td>
                <td>{ordem.id}</td>
                <td>{ordem.dataEntrada ? moment(ordem.dataEntrada).format('DD/MM/YYYY') : ''}</td>
                <td>{ordem.referencia}</td>
                <td>{ordem.cliente.nome}</td>
              </tr>
            ))}
            {ordens.length == 0 && 
              <tr>
                <td colSpan="5" style={{padding:'20px', textAlign:'center'}}>
                  {loadMsg}
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    </LayoutLogged>
  );
}
