import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

export default function Menu({ active }) {
  return (
    <ul className="nav nav-pills" role="tablist">
      <li className="nav-item">
        <Link 
          className={`nav-link ${active == 'materia-prima' ? 'active' : ''}`} 
          to={location => `/calculadora/materia-prima`}>
          Matéria-Prima
        </Link>
      </li>
      <li className="nav-item">
        <Link 
          className={`nav-link ${active == 'carrossel' ? 'active' : ''}`} 
          to={location => `/calculadora/carrossel`}>
          Carrossel
        </Link>
      </li>
      <li className="nav-item">
        <Link 
          className={`nav-link ${active == 'manual' ? 'active' : ''}`} 
          to={location => `/calculadora/manual`}>
          Manual
        </Link>
      </li>
    </ul>
  );
}