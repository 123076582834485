import React, { useEffect, useState } from 'react';
import LayoutLogged from '../LayoutLogged';
import api from '../../services/api';
import useBodyClass from '../../hooks/body-class';
import InputTypeFloat from '../../components/Html/InputTypeFloat';
import InputTypeInt from '../../components/Html/InputTypeInt';
import numberFormat from '../../helpers/numberFormat';
import Menu from './Menu';
import './CalculadoraBase.css';

export default function CalculadoraMateriaPrimaPage({ history }) {

  const [tecnicas, setTecnicas] = useState([]);
  const [dadosCalculadora, setDadosCalculadora] = useState({
    tecnicaId: null,
    precoPorQuilo: 0,
    adicional: '',
    precoPorQuiloDoAdicional: 0,
    porcentagemDeParticipacaoDoAdicional: 0,
    areaTotalDaEstampaCm2: 0,
    areaUtilDaEstampaPorcentagem: 0
  });
  const [categoria, setCategoria] = useState();
  const [calculando, setCalculando] = useState(false);
  const [resultado, setResultado] = useState(null);
  const [tecnicasFiltradas, setTecnicasFiltradas] = useState();

  useBodyClass("page-calculadora");

  useEffect(() => {
    loadTecnicas();
  }, []);

  useEffect(() => {
    setResultado(null);
  }, [dadosCalculadora]);

  useEffect(() => {
    filtrarTecnicasPorCategoria();
  }, [categoria]);

  async function loadTecnicas() {
    let resp;
    try {
      resp = await api.get(`/calculadora/tecnicas`);
    } catch (error) {
      if (error.response.status < 500) {
        alert(error.response.data.description);
      } else {
        alert("Ocorreu um erro no servidor e não foi possível fazer o cálculo. Por favor, tente novamente.");
      }
      return;
    }
    setTecnicas(resp.data);
    setCategoria('base');
  }

  async function calcular(e) {
    e.preventDefault();
    setCalculando(true);
    let resp;
    try {
      resp = await api.post('/calculadora/materia-prima', dadosCalculadora);
    } catch (error) {
      if (error.response.status < 500) {
        alert(error.response.data.description);
      } else {
        alert("Ocorreu um erro no servidor.");
      }
      setCalculando(false);
      return;
    }
    setCalculando(false);
    setResultado(resp.data.resultado);
  }

  function filtrarTecnicasPorCategoria() {
    let filtradas = (tecnicas || []).filter(tecnica => tecnica.categoria == categoria);
    if (filtradas.length > 0) {
      setTecnicasFiltradas(filtradas);
      dadosCalculadora.tecnicaId = filtradas[0].id;
      setDadosCalculadora(dadosCalculadora);
    }
  }

  return (
    <LayoutLogged>
      <div className="materia-prima-container">
        <h2 className="page-title">Calculadora</h2>
        <Menu active="materia-prima" />
        <form onSubmit={calcular}>
          <div className="form-row">
            <fieldset className="form-group col-md-3">
              <legend>Técnica:</legend>
              <div className="form-group">
                <label>Categoria da Técnica</label>
                <select className="form-control"
                  value={categoria}
                  onChange={e => {
                    setCategoria(e.target.value);
                  }}>
                  <option value="base">Base</option>
                  <option value="corrosao">Corrosão</option>
                  <option value="diferenciado">Diferenciado</option>
                  <option value="plastisol">Plastisol</option>
                  <option value="silk">Silk</option>
                </select>
              </div>
              <div className="form-group">
                <label>Técnica</label>
                <select className="form-control"
                  value={dadosCalculadora.tecnicaId || ''}
                  onChange={e => {
                    dadosCalculadora.tecnicaId = e.target.value;
                    setDadosCalculadora({ ...dadosCalculadora });
                  }}>
                  {(tecnicasFiltradas || [])
                    .map((tecnica, index) => 
                      <option value={tecnica.id} key={index}>{tecnica.tecnica}</option>
                    )
                  }
                </select>
              </div>
              <div className="form-group">
                <label>Preço por Kg</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">R$</div>
                  </div>
                  <InputTypeFloat
                    className="form-control"
                    value={dadosCalculadora.precoPorQuilo}
                    onChange={value => {
                      dadosCalculadora.precoPorQuilo = value;
                      setDadosCalculadora({ ...dadosCalculadora });
                    }}
                  />
                </div>
              </div>
            </fieldset>
            <fieldset className="form-group col-md-3">
              <legend>Adicional:</legend>
              <div className="form-group">
                <label>Adicional</label>
                <select className="form-control"
                  value={dadosCalculadora.adicional}
                  onChange={e => {
                    dadosCalculadora.adicional = e.target.value;
                    setDadosCalculadora({ ...dadosCalculadora });
                  }}>
                  <option value="">SEM ADICIONAL</option>
                  <option value="pigmento">PIGMENTO</option>
                  <option value="poDeCorrosao">PÓ DE CORROSÃO</option>
                  <option value="fixador">FIXADOR</option>
                  <option value="catalisador">CATALISADOR</option>
                  <option value="diluente">DILUENTE</option>
                  <option value="glitter">GLITTER</option>
                </select>
              </div>
              <div className="form-group">
                <label>Preço por Kg</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">R$</div>
                  </div>
                  <InputTypeFloat
                    className="form-control"
                    value={dadosCalculadora.precoPorQuiloDoAdicional}
                    onChange={value => {
                      dadosCalculadora.precoPorQuiloDoAdicional = value;
                      setDadosCalculadora({ ...dadosCalculadora });
                    }}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Participação</label>
                <div className="input-group">
                  <InputTypeInt
                    className="form-control"
                    value={dadosCalculadora.porcentagemDeParticipacaoDoAdicional}
                    onChange={value => {
                      dadosCalculadora.porcentagemDeParticipacaoDoAdicional = value;
                      setDadosCalculadora({ ...dadosCalculadora });
                    }}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">%</div>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset className="form-group col-md-3">
              <legend>Área:</legend>
              <div className="form-group">
                <label>Área Total da Estampa (cm2)</label>
                <InputTypeInt
                  className="form-control"
                  value={dadosCalculadora.areaTotalDaEstampaCm2}
                  onChange={value => {
                    dadosCalculadora.areaTotalDaEstampaCm2 = value;
                    setDadosCalculadora({ ...dadosCalculadora });
                  }}
                />
              </div>
              <div className="form-group">
                <label>Área Útil Aplicada (cm2)</label>
                <div className="input-group">
                  <InputTypeInt
                    className="form-control"
                    value={dadosCalculadora.areaUtilDaEstampaPorcentagem}
                    onChange={value => {
                      dadosCalculadora.areaUtilDaEstampaPorcentagem = value;
                      setDadosCalculadora({ ...dadosCalculadora });
                    }}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">%</div>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset className="form-group col-md-3 resultado-container">
              <legend>Resultado:</legend>
              <div className="conteudo">
                {calculando 
                  ? <small>Calculando...</small> 
                  : resultado === null 
                    ? <small>Preencha os dados e clique em calcular.</small>
                    : <><small>Custo da matéria-prima:</small>
                      <div className="custo">R$ {numberFormat(resultado)}</div></>
                }
              </div>
              <button type="submit" className="btn btn-primary">Calcular</button>
            </fieldset>
          </div>
        </form>
      </div>
    </LayoutLogged>
  );
}