import React, { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import api from '../../services/api';
import numberFormat from '../../helpers/numberFormat';
import LayoutLogged from '../LayoutLogged';
import useBodyClass from '../../hooks/body-class';
import FiltroDeCor from '../../components/Cor/FiltroDeCor';
import Pagination from '../../components/Pagination/Pagination';

export default function CorList({ history }) {

  let query = new URLSearchParams(useLocation().search);
  const [cores, setCores] = useState({
    data: [],
    current: 1,
    pages: null,
    total: 0
  });
  const [isLoading, setIsLoading] = useState(false);

  useBodyClass("page-cores");

  useEffect(() => {
    loadCores();
  }, [query.toString()]);

  async function loadCores() {
    const url = new URL(window.location.href);
    const page = url.searchParams.get('page') || 1;
    url.searchParams.set('page', page);
    let resp;
    setIsLoading(true);
    try {
      resp = await api.get(`/cores` + url.search);
    } catch(error) {
      setIsLoading(false);
      if (error.response.status < 500) {
        alert(error.response.data.description);
      } else {
        alert("Ocorreu um erro no servidor e não foi possível carregar os cores. Por favor, tente novamente.");
      }
      return;
    }
    setIsLoading(false);
    setCores(resp.data);
  }

  return (
    <LayoutLogged>
      <h2 className="page-title">Cores</h2>
      <p>
        <button type="button" className="btn btn-primary" onClick={e => history.push('/cores/form')}>
          Cadastrar Nova Cor
        </button>
      </p>

      <FiltroDeCor />

      <table className="table table-hover table-sm">
        <thead className="thead-dark">
          <tr>
            <th scope="col" style={{width:"5%"}}>Ações</th>
            <th scope="col">Código</th>
            <th scope="col">Custo por Kg</th>
          </tr>
        </thead>
        {isLoading &&
          <tbody>
            <tr style={{padding:'20px', textAlign:'center'}}>
              <td colSpan="3">Carregando...</td>
            </tr>
          </tbody>
        }
        {!isLoading && cores.data.length == 0 &&
          <tbody>
            <tr style={{padding:'20px', textAlign:'center'}}>
              <td colSpan="3">Não há cores cadastradas ainda.</td>
            </tr>
          </tbody>
        }
        {!isLoading && cores.data.length > 0 &&
          <tbody>
            {(cores.data || []).map((cor, index) => (
              <tr key={index}>
                <td style={{maxWidth: '50px'}}>
                  <Link className="btn btn-outline-secondary mr-1" to={location => `${location.pathname}/form/${cor.id}`}>
                    <i className="icofont-ui-edit"></i>
                  </Link>
                </td>
                <td>{cor.codigo}</td>
                <td>R$ {numberFormat(cor.custoPorQuilo)}</td>
              </tr>
            ))}
          </tbody>
        }
      </table>
      {cores.data.length > 0 && <Pagination totalPages={cores.pages} />}
    </LayoutLogged>
  );
}
