import React, { Fragment } from 'react';

export default function MenuContainer({groups, ...props}) 
{
  return (
    <div className="d-inline-flex mb-3 float-right">
      {(groups||[]).map((group, i) => 
        <div className="d-inline-flex mr-4" key={i}>
          {(group||[]).map((menu, i2) => <Fragment key={i2}>{menu}</Fragment>)}
        </div>
      )}
    </div>
  );
}