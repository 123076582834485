import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import './PrintPage.css';
import LayoutLogged from '../LayoutLogged';
import useBodyClass from '../../hooks/body-class';
import moment from 'moment';
import api from '../../services/api';
import OrdemProducaoModel from '../../models/OPTinta';

export default function PrintPage({ history }) {

  const { ordemId } = useParams();
  const [ordem, setOrdem] = useState({
    ...OrdemProducaoModel
  });

  useBodyClass("page-optintaprint");

  useEffect(() => {
    if (ordemId) {
      loadOrdem();
    }
  }, []);

  async function loadOrdem() {
    const response = await api.get(`/op-tinta/${ordemId}`);
    setOrdem(response.data);
  }

  return (
    <LayoutLogged>
      <h2 className="page-title">Ordem de Produção de Tinta {ordem.id}</h2>

      <table className="mb-5">
        <tbody>
          <tr>
            <td className="label text-right" style={{ width: '120px' }}>Cliente:</td>
            <td className="value">{ordem.cliente && ordem.cliente.nome}</td>
          </tr>
          <tr>
            <td className="label text-right">Refer.:</td>
            <td className="value">{ordem.referencia}</td>
          </tr>
          <tr>
          <td className="label text-right">Data Entrada:</td>
            <td className="value">{ordem.dataEntrada ? moment(ordem.dataEntrada).format('DD/MM/YYYY') : ''} {ordem.horaEntrada ? ordem.horaEntrada.substr(0,5) : ''}</td>
          </tr>
        </tbody>
      </table>

      <div className="tintas-container">
        <div className="caption mb-3">Preparação das Tintas:</div>
        <div className="tintas">
          {(ordem.cores || []).map((cor, index) => (
            <div className="tinta" key={index}>
              <div className="titulo">{cor.codigo}</div>
              {(cor.ingredientes || []).map((ingrediente, index) => (
                <div className="materiaprima" key={index}>
                  <strong>{ingrediente.materiaPrima.codigo}</strong><br/>
                  Lote: {ingrediente.materiaPrima.lote}<br/>
                  Qtde: {new Intl.NumberFormat(['pt-BR'], { 
                      minimumFractionDigits: 5
                  }).format(ingrediente.quantidadePorQuilo * cor.quantidade)} {cor.unidadeMedida}
                </div>
              ))}
              <div className="footer">
                {cor.quantidade} {cor.unidadeMedida}
              </div>
            </div>
          ))}
        </div>
      </div>
    </LayoutLogged>
  );
}