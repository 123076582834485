import React, { useState } from 'react';

export default function MenuRadio({values, checked, onChange, ...props}) 
{
  const [_values, _setValues] = useState([...values]);
  const [_checked, _setChecked] = useState();

  if (_checked === undefined) {
    _setChecked(checked || "");
  }

  const uid = (new Date()).getTime();

  return (
    <div className="btn-group btn-group-toggle me-2">
      {(_values||[]).map((value, i) =>       
        <div className="form-check form-check-inline" key={i}>
          <input
            type="radio"
            checked={_checked === value.id ? true : false}
            value={value.id}
            onChange={e => {
              _setChecked(value.id);
              if (onChange) {
                onChange(value.id);
              }
            }}
            autoComplete="off"
            className="form-check-input"
            id={`dashboardMenuRadio_${i + '_' + value.id + '_' + uid}`}
          />
          <label className="form-check-label" htmlFor={`dashboardMenuRadio_${i + '_' + value.id + '_' + uid}`}>
            {value.label}
          </label>
        </div>
      )}
    </div>
  );
}