import React, { useState, useEffect, useMemo } from 'react';
import api from '../../services/api';
import { ResponsiveBar } from '@nivo/bar';
import MenuDropdown from '../../components/Html/MenuDropdown';
import MenuRadio from '../../components/Dashboard/MenuRadio';
import MenuContainer from '../../components/Dashboard/MenuContainer';
import MenuGroupMode from '../../components/Dashboard/MenuGroupMode';
import DashboardHelper from './DashboardHelper';

export default function AProduzir({ filtros, metadados }) {

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const [clientes, setClientes] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [menuKey, setMenuKey] = useState('cliente');
  const [menuIndice, setMenuIndice] = useState('quantidade');
  const [chartConfig, setChartConfig] = useState({
    keys: [],
    groupMode: 'grouped',
    colors: ['#ed1c24', '#f26522', '#f7941d', '#cdc42a', '#8dc63f', '#39b54a', '#00a651', '#00a99d', '#00aeef', '#0072bc', '#0054a6', '#2e3192', '#662d91', '#92278f', '#ec008c', '#ed145b'],
    margin: { top: 50, right: 160, bottom: 150, left: 60 }
  });

  useEffect(() => {
    if (filtros) {
      submit();
    }
  }, [filtros]);

  const dadosComputados = useMemo(() => {
    if (!data || !clientes || !marcas) {
      return null;
    }
    return computaDados(data.data);
  }, [data, clientes, marcas, menuKey, menuIndice]);

  async function submit() {
    let data;
    try {
      setIsLoading(true);
      data = await loadData();
    } catch (error) {
      setIsLoading(false);
      if (error.response.status < 500) {
        alert(error.response.data.description);
      } else {
        alert("Ocorreu um erro no servidor e não foi possível carregar o gráfico. Por favor, tente novamente.");
      }
      return;
    }
    setData(data.data);
    setIsLoading(false);
    let indices = extraiIndices(data.data.data);
    setClientes(indices.clientes);
    setMarcas(indices.marcas);
  }

  async function loadData() {
    const query = new URLSearchParams('');
    query.append('analisarPor', filtros.analisarPor);
    query.append('periodoPor', filtros.periodoPor);
    query.append('periodoInicial', filtros.periodoInicial);
    query.append('periodoFinal', filtros.periodoFinal);
    return await api.get(`/relatorios/estamparia/a-produzir?` + query);
  }

  function extraiIndices(data) {
    let clientes = [];
    let marcas = [];
    for (const row of data) {
      if (clientes.findIndex(idx => idx.id === row.clienteId) < 0) {
        clientes.push({
          id: row.clienteId,
          label: row.cliente,
          checked: true
        });
      }
      if (marcas.findIndex(idx => idx.id === row.marca) < 0) {
        marcas.push({
          id: row.marca,
          label: row.marca,
          checked: true
        });
      }
    }
    return {
      clientes: DashboardHelper.orderByNome(clientes),
      marcas: DashboardHelper.orderByNome(marcas)
    }
  }

  function computaDados(dados) {
    let dadosFormatados = [];
    let clientesEscolhidos = clientes.filter(v => v.checked === true);
    let marcasEscolhidas = marcas.filter(v => v.checked === true);
    let keys = [];
    
    if (menuKey === 'cliente') {
      keys = clientesEscolhidos.map(m => m.label)
    } else if (menuKey === 'marca') {
      keys = marcasEscolhidas.map(p => p.label)
    }
    
    let dadosFiltrados = dados.filter(d => clientesEscolhidos.find(v => v.id === d.clienteId));
    dadosFiltrados = dadosFiltrados.filter(d => marcasEscolhidas.find(v => v.id === d.marca));
    let meses = DashboardHelper.periodoDeMeses(data.filtrosAplicados.periodoInicial, data.filtrosAplicados.periodoFinal);

    for (const mes of meses) {
      const mesFormatado = mes.slice(-2)+'/'+mes.slice(0,4);
      const dadosDoMes = dadosFiltrados.filter(dado => dado.anoMes === mes);
      let dadoFormatado = {
        id: mesFormatado
      };
      let totalQuantidadePecas = 0;
      let totalFaturamento = 0;
      if (menuKey === 'cliente') {
        clientesEscolhidos.forEach(cliente => {
          let dadosDoClienteNoMes = dadosDoMes.filter(dado => dado.clienteId === cliente.id);
          let quantidade = dadosDoClienteNoMes.reduce((acc, cur) => acc + cur.quantidade, 0);
          let faturamento = dadosDoClienteNoMes.reduce((acc, cur) => acc + cur.faturamento, 0)
          let ticketMedio = (quantidade > 0) ? (faturamento / quantidade) : 0;
          dadoFormatado[cliente.label] = menuIndice === 'quantidade' ? quantidade : (menuIndice === 'faturamento' ? faturamento : ticketMedio);
          dadoFormatado[cliente.label + '_quantidade'] = quantidade;
          dadoFormatado[cliente.label + '_faturamento'] = faturamento;
          dadoFormatado[cliente.label + '_ticketMedio'] = ticketMedio;
          totalQuantidadePecas += quantidade;
          totalFaturamento += faturamento;
        });
      } else if (menuKey === 'marca') {
        marcasEscolhidas.forEach(marca => {
          let dadosDaMarcaNoMes = dadosDoMes.filter(dado => dado.marca === marca.id);
          let quantidade = dadosDaMarcaNoMes.reduce((acc, cur) => acc + cur.quantidade, 0);
          let faturamento = dadosDaMarcaNoMes.reduce((acc, cur) => acc + cur.faturamento, 0)
          let ticketMedio = (quantidade > 0) ? (faturamento / quantidade) : 0;
          dadoFormatado[marca.label] = menuIndice === 'quantidade' ? quantidade : (menuIndice === 'faturamento' ? faturamento : ticketMedio);
          dadoFormatado[marca.label + '_quantidade'] = quantidade;
          dadoFormatado[marca.label + '_faturamento'] = faturamento;
          dadoFormatado[marca.label + '_ticketMedio'] = ticketMedio;
          totalQuantidadePecas += quantidade;
          totalFaturamento += faturamento;
        });
      }
      dadoFormatado["totalQuantidadePecas"] = totalQuantidadePecas;
      dadoFormatado["totalFaturamento"] = totalFaturamento;
      dadoFormatado["totalTicketMedio"] = totalFaturamento / totalQuantidadePecas;
      dadosFormatados.push(dadoFormatado);
    }
    setChartConfig({...chartConfig, keys: keys});
    return dadosFormatados;
  }

  return (
    <>
      {!isLoading &&
        <>
          <MenuContainer 
            groups={[
              [
                <MenuRadio 
                  values={[{
                      id: 'quantidade',
                      label: 'Quantidade Peças'
                    }, {
                      id: 'faturamento',
                      label: 'Faturamento'
                    }, {
                      id: 'ticketMedio',
                      label: 'Ticket Médio'
                    }
                  ]}
                  checked={menuIndice}
                  onChange={novoIndex => {
                    setMenuIndice(novoIndex)
                  }}
                />
              ],
              [
                <MenuRadio 
                  values={[{
                      id: 'cliente',
                      label: 'Cliente'
                    }, {
                      id: 'marca',
                      label: 'Marca'
                    }
                  ]}
                  checked={menuKey}
                  onChange={novoIndex => {
                    setMenuKey(novoIndex)
                  }}
                />
              ],
              [
                <MenuDropdown
                  title="Clientes"
                  staticValues={clientes}
                  onChange={clientes => {
                    setClientes(clientes);
                  }}
                  itemClass="text-nowrap"
                />,
                <MenuDropdown
                  title="Marcas"
                  staticValues={marcas}
                  onChange={marcas => {
                    setMarcas(marcas);
                  }}
                  itemClass="text-nowrap"
                />
              ],
              [
                <MenuGroupMode
                  checked={chartConfig.groupMode}
                  onChange={mode => {
                    chartConfig.groupMode = mode;
                    setChartConfig({...chartConfig})
                  }}
                />
              ]
            ]}
          />
        </>
      }
      <h3 className="dashboard-title">À Produzir</h3>
      {dadosComputados && dadosComputados.length > 0 &&
        <div className="dashboard-chart-description">
          <table className="m-auto fw-normal">
            <tbody>
              <tr>
                <td className="text-right pr-2">Período de Entrada:</td>
                <td className="text-left">{new Date(data.filtrosAplicados.periodoInicial).toLocaleDateString()} a {new Date(data.filtrosAplicados.periodoFinal).toLocaleDateString()}</td>
              </tr>
              {(() => {
                let totalQuantidadePecas = dadosComputados.reduce((acc, curr) => acc + curr.totalQuantidadePecas, 0);
                let totalFaturamento = dadosComputados.reduce((acc, curr) => acc + curr.totalFaturamento, 0);
                let totalTicketMedio = (totalQuantidadePecas > 0) ? (totalFaturamento / totalQuantidadePecas) : 0;
                return <>
                  <tr>
                    <td className="text-right pr-2">Peças:</td>
                    <td className="text-left">{`${Number(totalQuantidadePecas).toLocaleString('pt-BR')}`}</td>
                  </tr>
                  <tr>
                    <td className="text-right pr-2">Faturamento:</td>
                    <td className="text-left">{`${Number(totalFaturamento).toLocaleString('pt-BR', {style: "currency", currency: "BRL"})}`}</td>
                  </tr>
                  <tr>
                    <td className="text-right pr-2">Ticket Médio:</td>
                    <td className="text-left">{`${Number(totalTicketMedio).toLocaleString('pt-BR', {style: "currency", currency: "BRL"})}`}</td>
                  </tr>
                </>
              })()}
            </tbody>
          </table>
        </div>
      }
      <div style={{ height: '550px' }}>
        {isLoading &&
          <div>Carregando gráfico...</div>
        }
        {!isLoading && data?.data.length == 0 &&
          <div>Não há dados para formar o gráfico com os filtros que você usou.</div>
        }
        {!isLoading && data?.data.length > 0 &&
          <ResponsiveBar
            data={dadosComputados}
            keys={chartConfig.keys}
            groupMode={chartConfig.groupMode}
            indexBy="id"
            margin={chartConfig.margin}
            padding={0.3}
            valueFormat={value =>
              `${Number(value).toLocaleString('pt-BR')}`
            }
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={chartConfig.colors}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            legends={[
              {
                dataFrom: 'keys',
                toggleSerie: true,
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 20,
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemOpacity: 1
                    }
                  }
                ]
              }
            ]}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: -45,
              legend: '',
              legendPosition: 'middle',
              legendOffset: 32
            }}
            axisLeft={{
              format: value =>
                `${Number(value).toLocaleString('pt-BR')}`,
            }}
            axisTop={{
              tickSize: 5,
              tickPadding: 5,
              legend: '',
              legendPosition: 'middle',
              legendOffset: 32,
              format: function(value) {
                let colunaDeDados = dadosComputados.filter(d => d.id === value)[0];
                if ( ! colunaDeDados) return 0;
                if (menuIndice === 'quantidade') {
                  return `${Number(colunaDeDados.totalQuantidadePecas || 0).toLocaleString('pt-BR')}`;
                } else if (menuIndice === 'faturamento') {
                  return `${Number(colunaDeDados.totalFaturamento || 0).toLocaleString('pt-BR', {style: "currency", currency: "BRL"})}`;
                } else if (menuIndice === 'ticketMedio') {
                  return `${Number(
                    colunaDeDados.totalQuantidadePecas > 0
                      ? (colunaDeDados.totalFaturamento) / (colunaDeDados.totalQuantidadePecas)
                      : 0
                  ).toLocaleString('pt-BR', {style: "currency", currency: "BRL"})}`;
                }
              },
            }}
            tooltip={function(config) {
              return <div style={{
                backgroundColor: config.color,
                boxShadow: '0px 0px 25px 5px rgba(0,0,0,0.46)',
                color: '#fff',
                padding: '10px 18px',
                fontWeight: 'bold'
              }}>
                {config.id}<br />
                {config.indexValue}<br />
                Qtd. Peças: {Number(config.data[config.id + '_quantidade']).toLocaleString('pt-BR')}<br />
                Faturamento: {Number(config.data[config.id + '_faturamento']).toLocaleString('pt-BR', {style: "currency", currency: "BRL"})}<br />
                Ticket Médio: {Number(config.data[config.id + '_ticketMedio']).toLocaleString('pt-BR', {style: "currency", currency: "BRL"})}
              </div>
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor="rgb(255,255,255)"
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            enableGridX={true}
            theme={{
              "grid": {
                "line": {
                    "stroke": "#a8a8a8",
                    "strokeWidth": 1
                },
              },
              "axis": {
                "ticks": {
                  "text": {
                    "fontSize": 12,
                    "fill": "#000"
                  }
                }
              }
            }}
          />
        }
      </div>
    </>
  );
}