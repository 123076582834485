import parseJwt from './jwt';
import EstampariaModel from '../models/Estamparia';
import api from './api';

const TOKEN_KEY = "estamparia-token";

const auth = {
    isAuthenticated: () => {
        return localStorage.getItem(TOKEN_KEY) !== null;
    },
    getToken: () => {
        return localStorage.getItem(TOKEN_KEY);
    },
    saveToken: token => {
        localStorage.setItem(TOKEN_KEY, token);
        document.cookie = "authorization = Bearer+" + token + ';domain=' + process.env.REACT_APP_DOMAIN;
    },
    login: async (dados, tipoUsuario) => {
        let url = tipoUsuario == 'gestor' ? '/gestor/login' : '/estamparia/login';
        let response = await api.post(url, dados);
        if (response.data.status == 'ok') {
            auth.saveToken(response.data.token);
        }
        return response;
    },
    logout: () => {
        localStorage.removeItem(TOKEN_KEY);
        document.cookie = "authorization= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
    },
    refreshToken: async () => {
        let usuario = auth.getUsuario();
        if (usuario.role == 'estamparia') {
            let resp = await api.post('/estamparia/refresh-token');
            auth.saveToken(resp.data);
        }
    },
    getUsuario: () => {
        let tokenDecoded = parseJwt(auth.getToken());
        let usuario = {...EstampariaModel};
        usuario.id = tokenDecoded.id;
        usuario.role = tokenDecoded.role;
        usuario.nome = tokenDecoded.nome;
        usuario.modulo = tokenDecoded.modulo;
        usuario.configuracoes = tokenDecoded.configuracoes;
        return usuario;
    }
}

export default auth;