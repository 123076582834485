import MateriaPrima from './MateriaPrima';

const Ingrediente = {
    id: 0,
    corId: 0,
    materiaPrimaId: 0,
    materiaPrima: {...MateriaPrima},
    quantidade: 0
}

export default Ingrediente;