import React from 'react';
import './HomePage.css';
import { Link } from 'react-router-dom';
import LayoutLogged from '../LayoutLogged';

export default function Home({ history }) { 
  return (
    <LayoutLogged>
      <div id="homeMenu">
        <ul className="iconlist">		
          <li>
            <Link className="icon-holder" to="/op-estamparia">
              <div className="icon"> 
                <i className="icofont-attachment"></i>
              </div> 
              <span>O.P. Estamparia</span>
            </Link>
          </li>
          <li>
            <Link className="icon-holder" to="/op-tinta">
              <div className="icon"> 
                <i className="icofont-attachment"></i>
              </div> 
              <span>O.P. Tintas</span>
            </Link>
          </li>
          <li>
            <Link className="icon-holder" to="/cores">
              <div className="icon"> 
                <i className="icofont-color-bucket"></i>
              </div> 
              <span>Cores</span>
            </Link>
          </li>
        </ul>
      </div>
    </LayoutLogged>
  );
}