import React, { useEffect } from 'react';

import appConfig from '../../AppConfig';
import auth from "../../services/auth";

export default function LogoutPage({ history }) {

  useEffect(() => {
    auth.logout();
    history.push(`${appConfig.baseURL}/login`);
  }, []);

  return (
    <></>
  );

}
