import React, { useState } from 'react';
import modalManager from '../../services/modalManager';
import OperadorModel from '../../models/Operador';
import './ModalEventoForm.css';

export default function ModalEventoForm(
  { onConfirm, onCancel, operadores, ...props }
) {

  const [evento, setEvento] = useState({ ...props.evento });

  let titulo = '';

  if (evento.tipo == 'setup') {
    titulo += 'Setup';
  } else if (evento.tipo == 'producao') {
    titulo += 'Produção';
  } else if (evento.tipo == 'parada_programada') {
    titulo += 'Parada Programada';
  } else if (evento.tipo == 'parada_nao_programada') {
    titulo += 'Parada Não Programada';
  }

  function setOperador(operadorId) {
    const operadorSelecionado = operadores.find(funcionario =>
      funcionario.id == parseInt(operadorId)
    );
    evento.operador = operadorSelecionado ? operadorSelecionado : { ...OperadorModel };
    setEvento({ ...evento });
  }

  function confirm(e) {
    if (!validaForm()) {
      e.preventDefault();
      return;
    }
    if (onConfirm) {
      onConfirm(e, evento);
    }
  }

  function cancel(e) {
    if (onCancel) {
      onCancel(e);
    }
  }

  function validaForm() {
    if ( ! /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}(:[0-9]{2})?/.exec(evento.inicio)) {
      alert('A data e hora de início precisam estar preenchidas corretamente.');
      return false;
    }
    if ( ! /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}(:[0-9]{2})?/.exec(evento.fim)) {
      alert('A data e hora do fim precisam estar preenchidas corretamente.');
      return false;
    }
    if ((new Date(evento.inicio)).getTime() >= (new Date(evento.fim)).getTime()) {
      alert('A data do início do Evento deve ser menor que a do fim');
      return false;
    }
    if (evento.tipo == 'parada_programada' || evento.tipo == 'parada_nao_programada') {
      if (evento.motivo == '') {
        alert('O motivo da parada não pode estar em branco.');
        return false;
      }
    }
    if (evento.operador.id < 1) {
      alert('Você precisa selecionar um Operador para o Evento.');
      return false;
    }
    return true;
  }

  return (
    <div className="modal fade" id="modalEventoForm" role="dialog" data-backdrop="static">
      <div className="modal-dialog modal-md" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{titulo}</h5>
            <button type="button" className="close" onClick={modalManager.hide}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={confirm}>
              {(() => {
                if (evento.tipo === 'parada_programada') {
                  return (
                    <div className="form-row">
                      <div className="form-group col-12">
                        <label>Motivo</label>
                        <select
                          value={evento.motivo}
                          className="form-control"
                          onChange={e => {
                            evento.motivo = e.target.value;
                            setEvento({ ...evento });
                          }}>
                          <option value=""></option>
                          <option value="limpeza_de_maquina">LIMPEZA DE MÁQUINAS</option>
                          <option value="manutencao_preventiva">MANUTENÇÃO PREVENTIVA</option>
                          <option value="raspar_chapa">RASPAR CHAPAS</option>
                          <option value="refeicao">REFEIÇÕES, ALMOÇO/JANTAR</option>
                          <option value="reuniao_treinamento">REUNIÕES, TREINAMENTOS</option>
                          <option value="troca_de_cor">TROCA DE CORES</option>
                          <option value="intervalos_de_operacao">INTERVALOS DE OPERAÇÃO</option>
                        </select>
                      </div>
                    </div>
                  );
                } else if (evento.tipo === 'parada_nao_programada') {
                  return (
                    <div className="form-row">
                      <div className="form-group col-12">
                        <label>Motivo</label>
                        <select
                          value={evento.motivo}
                          className="form-control"
                          onChange={e => {
                            evento.motivo = e.target.value;
                            setEvento({ ...evento });
                          }}>
                          <option value=""></option>
                          <option value="ajuste_de_cor">AJUSTES DE CORES</option>
                          <option value="troca_de_quadro">TROCA DE QUADROS</option>
                          <option value="conserto_de_quadro">CONSERTO DE QUADROS</option>
                          <option value="falta_de_pessoal">FALTA DE PESSOAL</option>
                          <option value="falta_de_ferramenta">FALTA DE FERRAMENTAS</option>
                          <option value="falta_de_materia_prima">FALTA DE MATÉRIA PRIMA</option>
                          <option value="manutencao_corretiva">MANUTENÇÃO CORRETIVA</option>
                          <option value="quebra_de_linha">QUEBRA DE LINHA</option>
                          <option value="limpeza_de_cisco">LIMPEZA DE CISCO</option>
                        </select>
                      </div>
                    </div>
                  );
                }
              })()}
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label>Data Início</label>
                  <input
                    type="date"
                    className="form-control"
                    value={evento.inicio.split(/[T ]/)[0] || ""}
                    onChange={e => {
                      let data = e.target.value;
                      let hora = evento.inicio.split(/[T ]/)[1] || "";
                      if (data !== '') {
                        let [ano, mes, dia] = data.split("-");
                        data = ano.substr(0,4) + '-' + mes + '-' + dia;
                      }
                      evento.inicio = data + 'T' + hora;
                      setEvento({ ...evento });
                    }}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label>Hora Início</label>
                  <input
                    type="time"
                    className="form-control"
                    value={evento.inicio.split(/[T ]/)[1] || ""}
                    onChange={e => {
                      let data = evento.inicio.split(/[T ]/)[0] || "";
                      let hora = e.target.value;
                      evento.inicio = data + 'T' + hora;
                      setEvento({ ...evento });
                    }}
                    step="1"
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label>Data Fim</label>
                  <input
                    type="date"
                    className="form-control"
                    value={evento.fim.split(/[T ]/)[0] || ""}
                    onChange={e => {
                      let data = e.target.value;
                      let hora = evento.fim.split(/[T ]/)[1] || "";
                      if (data !== '') {
                        let [ano, mes, dia] = data.split("-");
                        data = ano.substr(0,4) + '-' + mes + '-' + dia;
                      }
                      evento.fim = data + 'T' + hora;
                      setEvento({ ...evento });
                    }}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label>Hora Fim</label>
                  <input
                    type="time"
                    className="form-control"
                    value={evento.fim.split(/[T ]/)[1] || ""}
                    onChange={e => {
                      let data = evento.fim.split(/[T ]/)[0] || "";
                      let hora = e.target.value;
                      evento.fim = data + 'T' + hora;
                      setEvento({ ...evento });
                    }}
                    step="1"
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-12">
                  <label>Operador</label>
                  <select
                    value={evento.operador.id}
                    className="form-control"
                    onChange={e => setOperador(e.target.value)}
                  >
                    {evento.operador.id == 0 &&
                      <option value="0">Selecione um Operador</option>
                    }
                    {(operadores || []).map((operador, index) => (
                      <option value={operador.id} key={index}>
                        {operador.nome}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="btn-group mr-2" role="group">
                <button type="button" className="btn btn-secondary" onClick={cancel}>Cancelar</button>
              </div>
              <div className="btn-group" role="group">
                <button type="submit" className="btn btn-primary">Ok</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}