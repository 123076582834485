import React, { useEffect, useState } from 'react';
import modalManager from '../../services/modalManager';
import { Maquina as MaquinaModel } from '../../models/OPEstamparia';

export default function ModalMaquinaForm(props) {

  const [maquina, _setMaquina] = useState({ ...MaquinaModel });

  useEffect(() => {
    setMaquina(props.maquinas[0].id);
  }, []);

  function setMaquina(maquinaId) {
    const maquinaFromCache = (props.maquinas || []).find(maquina => maquina.id == parseInt(maquinaId));
    _setMaquina({ ...MaquinaModel, ...maquinaFromCache });
  }

  function confirm(e) {
    if (props.onConfirm) {
      props.onConfirm(e, maquina);
    }
  }

  function cancel(e) {
    if (props.onCancel) {
      props.onCancel(e);
    }
  }

  return (
    <div className="modal fade" id="modalMaquinaForm" role="dialog" data-backdrop="static">
      <div className="modal-dialog modal-sm" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Adicionar Máquina</h5>
            <button type="button" className="close" onClick={modalManager.hide}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={confirm}>
              <div className="form-row">
                <div className="form-group col-md-12">
                  <label>Máquina</label>
                  <select
                    value={maquina.id}
                    className="form-control"
                    onChange={e => setMaquina(e.target.value)}
                  >
                    {(props.maquinas || [])
                      .filter(maquina => maquina.status == 'ativo')
                      .map((maquina, index) => (
                        <option value={maquina.id} key={index}>{maquina.nome}</option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="btn-group mr-2" role="group">
                <button type="button" className="btn btn-secondary" onClick={cancel}>Cancelar</button>
              </div>
              <div className="btn-group" role="group">
                <button type="submit" className="btn btn-primary">Ok</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}