import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import auth from "./services/auth";
import LoggedContext from './contexts/LoggedContext';
import LoginPage from './pages/Login/LoginPage';
import TrocarEstampariaPage from './pages/TrocarEstamparia/TrocarEstampariaPage';
import HomePage from './pages/Home/HomePage';
import OPEstampariaListPage from './pages/OPEstamparia/ListPage';
import OPEstampariaPrintPage from './pages/OPEstamparia/PrintPage';
import OPEstampariaFormPage from './pages/OPEstamparia/FormPage';
import OPTintaListPage from './pages/OPTinta/ListPage';
import OPTintaFormPage from './pages/OPTinta/FormPage';
import OPTintaPrintPage from './pages/OPTinta/PrintPage';
import FornecedorListPage from './pages/Fornecedor/ListPage';
import FornecedorFormPage from './pages/Fornecedor/FormPage';
import ClienteListPage from './pages/Cliente/ClienteListPage';
import ClienteFormPage from './pages/Cliente/ClienteFormPage';
import CorListPage from './pages/Cor/CorListPage';
import CorFormPage from './pages/Cor/CorFormPage';
import CalculadoraMateriaPrimaPage from './pages/Calculadora/CalculadoraMateriaPrimaPage';
import CalculadoraCarrosselPage from './pages/Calculadora/CalculadoraCarrosselPage';
import CalculadoraManualPage from './pages/Calculadora/CalculadoraManualPage';
import DashboardIndexPage from './pages/Dashboard/IndexPage';
import ConfiguracaoEmpresaPage from './pages/Configuracao/EmpresaPage';
import MaquinasPage from './pages/Configuracao/MaquinasPage';
import OperadoresPage from './pages/Configuracao/OperadoresPage';
import LogoutPage from './pages/Logout/LogoutPage';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      auth.isAuthenticated() ? (
        <LoggedContext>
          <Component {...props} />
        </LoggedContext>
      ) : (
        <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
      )
    }
  />
);

export default function Routes() {
  return (
    <Switch>
      <Route path="/login" component={LoginPage} />
      <Route path="/login-gestor" component={LoginPage} />
      <Route path="/logout" component={LogoutPage} />
      <PrivateRoute path="/gestor/trocar-estamparia" component={TrocarEstampariaPage} />
      <PrivateRoute path="/op-estamparia/:ordemId/print" component={OPEstampariaPrintPage} />
      <PrivateRoute path="/op-estamparia/form/:ordemId" component={OPEstampariaFormPage} />
      <PrivateRoute path="/op-estamparia/form" component={OPEstampariaFormPage} />
      <PrivateRoute path="/op-estamparia" component={OPEstampariaListPage} />
      <PrivateRoute path="/op-tinta/:ordemId/form" component={OPTintaFormPage} />
      <PrivateRoute path="/op-tinta/:ordemId/print" component={OPTintaPrintPage} />
      <PrivateRoute path="/op-tinta/form" component={OPTintaFormPage} />
      <PrivateRoute path="/op-tinta" component={OPTintaListPage} />
      <PrivateRoute path="/fornecedores/form/:fornecedorId" component={FornecedorFormPage} />
      <PrivateRoute path="/fornecedores/form" component={FornecedorFormPage} />
      <PrivateRoute path="/fornecedores" exact={true} component={FornecedorListPage} />
      <PrivateRoute path="/clientes/form/:clienteId" component={ClienteFormPage} />
      <PrivateRoute path="/clientes/form" component={ClienteFormPage} />
      <PrivateRoute path="/clientes" component={ClienteListPage} />
      <PrivateRoute path="/cores/form/:corId" component={CorFormPage} />
      <PrivateRoute path="/cores/form" component={CorFormPage} />
      <PrivateRoute path="/cores" exact={true} component={CorListPage} />
      <PrivateRoute path="/calculadora/materia-prima" exact={true} component={CalculadoraMateriaPrimaPage} />
      <PrivateRoute path="/calculadora/carrossel" exact={true} component={CalculadoraCarrosselPage} />
      <PrivateRoute path="/calculadora/manual" exact={true} component={CalculadoraManualPage} />
      <PrivateRoute path="/dashboard" component={DashboardIndexPage} />
      <PrivateRoute path="/configuracoes/empresa" component={ConfiguracaoEmpresaPage} />
      <PrivateRoute path="/configuracoes/maquinas" component={MaquinasPage} />
      <PrivateRoute path="/configuracoes/operadores" component={OperadoresPage} />
      <PrivateRoute path="/" exact={true} component={HomePage} />
    </Switch>
  );
}