import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import appConfig from '../../AppConfig';
import api from '../../services/api';
import FornecedorForm from '../../components/Fornecedor/FornecedorForm';

export default function FormPage({ history }) {

  const { fornecedorId } = useParams();
  const [fornecedor, setFornecedor] = useState();

  useEffect(() => {
    loadFornecedor();
  }, []);

  function loadFornecedor() {
    api.get(`/fornecedores/${fornecedorId}`)
      .then((response) => {
        setFornecedor(response.data);
      })
      .catch((error) => {
        if (error.response.status < 500) {
          alert(error.response.data.description);
        } else {
          alert("Ocorreu um erro no servidor e não foi possível carregar o Fornecedor.");
        }
      });
  }

  function onCancel() {
    history.push(`${appConfig.baseURL}/fornecedores`);
  }

  function onSaveSucess() {
    history.push(`${appConfig.baseURL}/fornecedores`);
  }

  return (
    <FornecedorForm 
      fornecedor={fornecedor}
      onCancel={onCancel}
      onSaveSucess={onSaveSucess}
    />
  );
}