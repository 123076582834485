import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import './FiltroDeCor.css';

export default function FiltroDeCor({
  onSubmit = (filtros) => { },
  onClear = () => { },
  workWithLocation = true
}) {

  let query = new URLSearchParams(useLocation().search);
  const history = useHistory();
  const filtroModel = {
    codigo: "",
  };
  const [filtros, setFiltros] = useState({ ...filtroModel });

  useEffect(() => {
    if (workWithLocation) {
      preencheOsFiltrosComOsParametrosDaUrl();
    }
  }, [query.toString()]);

  function preencheOsFiltrosComOsParametrosDaUrl() {
    const params = new URL(window.location.href).searchParams;
    for (let filtro in filtroModel) {
      if (params.has(filtro)) {
        filtros[filtro] = params.get(filtro);
      }
    }
    setFiltros({...filtros});
  }

  function onSubmitFiltro(event) {
    event.preventDefault();
    onSubmit(filtros);
    if (workWithLocation) {
      const url = new URL(window.location.href);
      url.searchParams.delete('page');
      for (let filtro in filtros) {
        url.searchParams.set(filtro, filtros[filtro]);
      }
      history.push(url.search);
    }
  }

  function onClickLimparFiltro(event) {
    event.preventDefault();
    onClear();
    if (workWithLocation) {
      const url = new URL(window.location.href);
      history.push(url.pathname);
      setFiltros({ ...filtroModel });
    }
  }

  return (
    <div className="filtrodecor-container">
      <form onSubmit={onSubmitFiltro}>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            value={filtros.codigo}
            onChange={e => {
              filtros.codigo = e.target.value;
              setFiltros({ ...filtros });
            }}
            placeholder="Código"
            title="Código"
          />
        </div>
        <div className="form-group form-actions">
          <button type="submit" className="btn btn-primary">Filtrar</button>
          <button type="button" className="btn btn-secondary" onClick={onClickLimparFiltro}>Limpar</button>
        </div>
      </form>
    </div>
  );
}
