import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import LayoutLogged from '../LayoutLogged';

export default function TrocarEstampariaPage() {

  const history = useHistory();

  useEffect(()=>{
    const url = new URL(window.location.href);
    history.push(url.searchParams.get('redirect'));
  });

  return (
    <LayoutLogged></LayoutLogged>
  );
}