import React, { useEffect, useState, useContext } from 'react';
import LayoutLogged from '../LayoutLogged';
import { LoggedContext } from '../../contexts/LoggedContext';
import api from '../../services/api';
import useBodyClass from '../../hooks/body-class';
import InputTypeFloat from '../../components/Html/InputTypeFloat';
import InputTypeInt from '../../components/Html/InputTypeInt';
import numberFormat from '../../helpers/numberFormat';
import Menu from './Menu';
import './CalculadoraBase.css';

export default function CalculadoraCarrosselPage({ history }) {

  const context = useContext(LoggedContext);
  const [dadosCalculadora, setDadosCalculadora] = useState({
    custosFixosDaEmpresa: 0,
    quantidadeDeDiasUteis: 0,
    horasDiariasDeTrabalho: "",
    pecasPorHora: 0,
    custoMateriaPrima: 0,
    quantidadeDeMaquinas: 0,
    aliquotaImposto: 0,
    lucroDesejado: 0
  });
  const [calculando, setCalculando] = useState(false);
  const [resultado, setResultado] = useState(null);

  useBodyClass("page-calculadora");

  useEffect(() => {
    if (context.estamparia && context.estamparia.id > 0) {
      let configs = context.estamparia.configuracoes;
      dadosCalculadora.custosFixosDaEmpresa = configs.custosFixosDaEmpresa;
      dadosCalculadora.quantidadeDeDiasUteis = configs.diasDeTrabalhoPorMes;
      dadosCalculadora.horasDiariasDeTrabalho = configs.horasDiariasDeTrabalho;
      dadosCalculadora.quantidadeDeMaquinas = configs.quantidadeDeMaquinas;
      dadosCalculadora.lucroDesejado = configs.lucroDesejado;
      dadosCalculadora.aliquotaImposto = configs.aliquotaImposto;
      setDadosCalculadora({ ...dadosCalculadora });
    }
  }, [context.estamparia]);

  useEffect(() => {
    setResultado(null);
  }, [dadosCalculadora]);

  async function calcular(e) {
    e.preventDefault();
    setCalculando(true);
    let resp;
    try {
      resp = await api.post('/calculadora/carrossel', dadosCalculadora);
    } catch (error) {
      if (error.response.status < 500) {
        alert(error.response.data.description);
      } else {
        alert("Ocorreu um erro no servidor.");
      }
      setCalculando(false);
      return;
    }
    setCalculando(false);
    setResultado(resp.data.resultado);
  }

  return (
    <LayoutLogged>
      <div className="materia-prima-container">
        <h2 className="page-title">Calculadora</h2>
        <Menu active="carrossel" />
        <form onSubmit={calcular}>
          <div className="form-row">
            <fieldset className="form-group col-md-3">
              <legend>Produção:</legend>
              <div className="form-group">
                <label>Dias de Trabalho por Mês</label>
                <InputTypeFloat
                  className="form-control"
                  value={dadosCalculadora.quantidadeDeDiasUteis}
                  onChange={value => {
                    dadosCalculadora.quantidadeDeDiasUteis = value;
                    setDadosCalculadora({ ...dadosCalculadora });
                  }}
                />
              </div>
              <div className="form-group">
                <label>Horas Diárias</label>
                <input 
                  className="form-control"
                  value={dadosCalculadora.horasDiariasDeTrabalho}
                  onChange={e => {
                    dadosCalculadora.horasDiariasDeTrabalho = e.target.value;
                    setDadosCalculadora({ ...dadosCalculadora });
                  }}
                  type="time"
                  style={{textAlign:'right'}}
                />
              </div>
              <div className="form-group">
                <label>Quantidade de Máquinas</label>
                <InputTypeInt
                  className="form-control"
                  value={dadosCalculadora.quantidadeDeMaquinas}
                  onChange={value => {
                    dadosCalculadora.quantidadeDeMaquinas = value;
                    setDadosCalculadora({ ...dadosCalculadora });
                  }}
                />
              </div>
            </fieldset>
            <fieldset className="form-group col-md-3">
              <legend>Projeção:</legend>
              <div className="form-group">
                <label>Peças por Hora</label>
                <InputTypeInt 
                  className="form-control"
                  value={dadosCalculadora.pecasPorHora}
                  onChange={value => {
                    dadosCalculadora.pecasPorHora = value;
                    setDadosCalculadora({ ...dadosCalculadora });
                  }}
                />
              </div>
              <div className="form-group">
                <label>Custo Matéria-Prima</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">R$</div>
                  </div>
                  <InputTypeFloat
                    className="form-control"
                    value={dadosCalculadora.custoMateriaPrima}
                    onChange={value => {
                      dadosCalculadora.custoMateriaPrima = value;
                      setDadosCalculadora({ ...dadosCalculadora });
                    }}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Lucro Desejado</label>
                <div className="input-group">
                  <InputTypeFloat
                    className="form-control"
                    value={dadosCalculadora.lucroDesejado}
                    onChange={value => {
                      dadosCalculadora.lucroDesejado = value;
                      setDadosCalculadora({ ...dadosCalculadora });
                    }}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">%</div>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset className="form-group col-md-3">
              <legend>Empresa:</legend>
              <div className="form-group">
                <label>Custos Fixos</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">R$</div>
                  </div>
                  <InputTypeFloat
                    className="form-control"
                    value={dadosCalculadora.custosFixosDaEmpresa}
                    onChange={value => {
                      dadosCalculadora.custosFixosDaEmpresa = value;
                      setDadosCalculadora({ ...dadosCalculadora });
                    }}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Alíquota Imposto</label>
                <div className="input-group">
                  <InputTypeFloat
                    className="form-control"
                    value={dadosCalculadora.aliquotaImposto}
                    onChange={value => {
                      dadosCalculadora.aliquotaImposto = value;
                      setDadosCalculadora({ ...dadosCalculadora });
                    }}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">%</div>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset className="form-group col-md-3 resultado-container">
              <legend>Resultado:</legend>
              <div className="conteudo">
                {calculando 
                  ? <small>Calculando...</small> 
                  : resultado === null 
                    ? <small>Preencha os dados e clique em calcular.</small>
                    : <><small>Preço da Estampa:</small>
                      <div className="custo">R$ {numberFormat(resultado)}</div></>
                }
              </div>
              <button type="submit" className="btn btn-primary">Calcular</button>
            </fieldset>
          </div>
        </form>
      </div>
    </LayoutLogged>
  );
}