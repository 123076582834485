import $ from 'jquery';
import ReactDOM from 'react-dom';

const openedModals = [];

/**
 * O Bootstrap não foi feito para trabalhar com modal sobreposto.
 * Esta função foi feita para dar esta possibilidade.
 */
const modalManager = {
  mount: function(modalId, modalComponent) {
    if (document.getElementById(modalId)) {
      return;
    }
    let container = document.getElementById("modalContainer");
    let div = document.createElement('div');
    container.appendChild(div);
    ReactDOM.render(modalComponent, div);
    $(`#${modalId}`).on('hide.bs.modal', function (e) {
      openedModals.pop();
    });
    $(`#${modalId}`).on('hidden.bs.modal', function (e) {
      $(`#${modalId}`).parent().remove();
      if (openedModals.length > 0) {
        // Quando um modal sobreposto é fechado, força o bootstrap a pensar que o modal está aberto ainda.
        document.getElementsByTagName('body')[0].classList.add('modal-open');
        document.getElementsByTagName('body')[0].style.paddingRight = '17px';
        document.getElementById(`${openedModals[openedModals.length - 1]}`).style.opacity = '1';
      }
    });
  },
  show: function(modalId) {
    if (openedModals.length > 0) {
      document.getElementById(`${openedModals[openedModals.length - 1]}`).style.opacity = '0.1';
    }
    $(`#${modalId}`).modal('show');
    openedModals.push(modalId);
  },
  hide: function(){
    $(`#${openedModals[openedModals.length - 1]}`).modal('hide');
  }
}

export default modalManager;