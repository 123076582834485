import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import ClienteModel from '../../models/Cliente';

export default function ClienteForm(props) {

  const [cliente, setCliente] = useState({...ClienteModel});

  useEffect(() => {
    if (props.cliente) {
      setCliente({...props.cliente});
    }
  }, [props.cliente]);

  async function saveCliente(e) {
    e.preventDefault();
    let response;
    try {
      response = await api.post('/clientes', cliente);
    } catch (error) {
      if (error.response.status < 500) {
        alert(error.response.data.description);
      } else {
        alert("Ocorreu um erro no servidor ao salvar o Cliente.");
      }
      return;
    }

    if (props.onSaveSucess) {
      props.onSaveSucess(response);
    }
  }

  function cancel() {
    if (props.onCancel) props.onCancel();
  }

  return (
    <>
      {(props.showPageTitle || props.showPageTitle == undefined) &&
        <h2 className="page-title">
          {cliente ? 'Editar' : 'Cadastrar'} Cliente:
        </h2>
      }
      <div>
        <form className="cor-form" onSubmit={saveCliente}>
          <div className="form-group">
            <label>Nome:</label>
            <input
              type="text"
              className="form-control"
              value={cliente.nome}
              onChange={e => {
                cliente.nome = e.target.value;
                setCliente({ ...cliente });
              }}
            />
          </div>
          <div className="btn-group mr-2" role="group">
            <button type="button" className="btn btn-secondary" onClick={cancel}>Cancelar</button>
          </div>
          <div className="btn-group" role="group">
            <button type="submit" className="btn btn-primary">Salvar</button>
          </div>
        </form>
      </div>
    </>
  );
}