import React, { useState, useEffect } from 'react';
import api from '../../services/api';

export default function FornecedorList(props) {

  const [fornecedores, setFornecedores] = useState([]);
  const [loadMsg, setLoadMsg] = useState("Aguarde, carregando...");

  useEffect(() => {
    loadFornecedores();
  }, [props.reload]);

  async function loadFornecedores() {
    let resp;
    try {
      resp = await api.get('/fornecedores');
    } catch (error) {
      if (error.response.status < 500) {
        alert(error.response.data.description);
      } else {
        alert("Ocorreu um erro no servidor e não foi possível carregar os fornecedores. Por favor, tente novamente.");
      }
      return;
    }
    if (resp.data.length == 0) {
      setLoadMsg("Não há fornecedores cadastrados ainda.");
    }
    setFornecedores(resp.data);
  }

  function onClickCadastrar(e) {
    if (props.onClickCadastrar) props.onClickCadastrar(e);
  }

  function onClickEditar(e, fornecedor) {
    if (props.onClickEditar) props.onClickEditar(e, fornecedor);
  }

  function getPageTitle() {
    if (props.showPageTitle == false) {
      return;
    }
    return <h2 className="page-title">Fornecedores</h2>;
  }

  return (
    <>
      {getPageTitle()}
      <p>
        <button type="button" className="btn btn-primary" onClick={e => onClickCadastrar(e)}>
          Cadastrar Fornecedor
        </button>
      </p>
      <table className="table table-hover table-sm">
        <thead className="thead-dark">
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Nome</th>
            <th scope="col">Ações</th>
          </tr>
        </thead>
        <tbody>
          {fornecedores.map((fornecedor, index) => (
            <tr key={index}>
              <td>{fornecedor.id}</td>
              <td>{fornecedor.nome}</td>
              <td>
                {props.onSelect &&
                  <button className="mr-1" onClick={e => props.onSelect(e, fornecedor)}>
                    Selecionar
                  </button>
                }
                <button onClick={e => onClickEditar(e, fornecedor)}>
                  <i className="icofont-ui-edit"></i>
                </button>
              </td>
            </tr>
          ))}
          {fornecedores.length == 0 && 
            <tr>
              <td colSpan="3" style={{padding:'20px', textAlign:'center'}}>
                {loadMsg}
              </td>
            </tr>
          }
        </tbody>
      </table>
    </>
  );
}
