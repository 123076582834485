import React, { useState, useEffect, useMemo } from 'react';
import URLHelper from '../../helpers/url';
import api from '../../services/api';
import { ResponsiveBar } from '@nivo/bar';

export default function Produtividade({ filtros, metadados }) {

  const [isLoading, setIsLoading] = useState(false);
  const [apontamentosSelecionados, setApontamentosSelecionados] = useState();
  const [data, setData] = useState();
  const [nivoBarConfig, setNivoBarConfig] = useState(() => {
    let config = {
      keys: ["Produtividade"],
      groupMode: 'grouped',
      margin: { top: 0, right: 130, bottom: 150, left: 60 },
      indexBy: "index",
      colors: ['#ed1c24', '#f26522', '#f7941d', '#cdc42a', '#8dc63f', '#39b54a', '#00a651', '#00a99d', '#00aeef', '#0072bc', '#0054a6', '#2e3192', '#662d91', '#92278f', '#ec008c', '#ed145b']
    };
    if (filtros.agruparPor === 'mes') {
      
    } else {
      config.colors = '#7C9EB6';
      config.margin = { ...config.margin, right: 0 };
    }
    return config;
  });
  const formattedData = useMemo(() => {
    if (data) return format(data.data);
    return null;
  }, [data, apontamentosSelecionados]);

  const total = useMemo(() => {
    if (!data) return null;
    return {
      quantidade: data.data.reduce((acc, cur) => acc + cur.quantidade, 0),
      horasSetup: (data.data.reduce((acc, cur) => acc + cur.tempoSetup, 0) / 3600).toFixed(1),
      horasProducao: (data.data.reduce((acc, cur) => acc + cur.tempoProducao, 0) / 3600).toFixed(1),
      horasParadaProgramada: (data.data.reduce((acc, cur) => acc + cur.tempoParadaProgramada, 0) / 3600).toFixed(1),
      horasParadaNaoProgramada: (data.data.reduce((acc, cur) => acc + cur.tempoParadaNaoProgramada, 0) / 3600).toFixed(1),
      horasDisponibilidadeDeMaquina: (data.data.reduce((acc, cur) => acc + cur.tempoDisponibilidadeDeMaquina, 0) / 3600).toFixed(1),
    }
  }, [data]);

  useEffect(() => {
    if (filtros) {
      loadData();
    }
  }, [filtros]);

  async function loadData() {
    let query = URLHelper.createQueryStringFromObject(filtros).toString();
    let resp;
    try {
      setIsLoading(true);
      resp = await api.get(`/relatorios/estamparia/produtividade?` + query);
    } catch (error) {
      setIsLoading(false);
      if (error.response.status < 500) {
        alert(error.response.data.description);
      } else {
        alert("Ocorreu um erro no servidor e não foi possível carregar a Produtividade. Por favor, tente novamente.");
      }
      return;
    }
    setData(resp.data);
    setIsLoading(false);
  }

  function format(dados) {
    let dadosFormatados = [];
    if (filtros.agruparPor === 'mes') {
      let meses = rangeDeMeses(filtros.entradaInicial, filtros.entradaFinal, filtros.entregaInicial, filtros.entregaFinal);
      let keys = [];
      dados.forEach(d => {
        if (!keys.includes(d[filtros.classificarPor])) keys.push(d[filtros.classificarPor]);
      });
      for (const mes of meses) {
        const mesFormatado = mes.slice(-2)+'/'+mes.slice(0,4);
        const formatado = {
          "index": mesFormatado
        }
        const dadosDoMes = dados.filter(dado => dado.mes === mes);
        for (const dado of dadosDoMes) {
          let tempoEmSegundos = 0;
          for (const apontamento of apontamentosSelecionados) {
            tempoEmSegundos += dado[apontamento];
          }
          let tempoEmHoras = tempoEmSegundos / 3600;
          let produtividade = dado.quantidade / tempoEmHoras;
          formatado[dado[filtros.classificarPor]] = produtividade;
        }
        dadosFormatados.push(formatado);
      }
      setNivoBarConfig({...nivoBarConfig, keys: keys})
    } else {
      for (const dado of dados) {
        let quantidade = dado.quantidade;
        let somaTempoApontamentos = 0;
        for (const apontamento of apontamentosSelecionados) {
          somaTempoApontamentos = somaTempoApontamentos + dado[apontamento];
        }
        let tempoEmHoras = somaTempoApontamentos / 3600;
        dadosFormatados.push({
          "index": dado[filtros.classificarPor],
          "Produtividade": quantidade / tempoEmHoras,
          "quantidade": quantidade,
          "tempoEmHoras": tempoEmHoras
        });
      }
      dadosFormatados = orderByProdutividadeDesc(dadosFormatados);
    }
    return dadosFormatados;
  }

  function rangeDeMeses(entradaInicial, entradaFinal, entregaInicial, entregaFinal) {
    if (entradaInicial) {
      entradaInicial = new Date(entradaInicial);
      entradaInicial = entradaInicial.getUTCFullYear() + ('0'+ (entradaInicial.getUTCMonth()+1)).slice(-2);
      entradaInicial = parseInt(entradaInicial);
    }
    if (entradaFinal) {
      entradaFinal = new Date(entradaFinal);
      entradaFinal = entradaFinal.getUTCFullYear() + ('0'+ (entradaFinal.getUTCMonth()+1)).slice(-2);
      entradaFinal = parseInt(entradaFinal);
    }
    if (entregaInicial) {
      entregaInicial = new Date(entregaInicial);
      entregaInicial = entregaInicial.getUTCFullYear() + ('0'+ (entregaInicial.getUTCMonth()+1)).slice(-2);
      entregaInicial = parseInt(entregaInicial);
    }
    if (entregaFinal) {
      entregaFinal = new Date(entregaFinal);
      entregaFinal = entregaFinal.getUTCFullYear() + ('0'+ (entregaFinal.getUTCMonth()+1)).slice(-2);
      entregaFinal = parseInt(entregaFinal);
    }
    let dataInicial = (entradaInicial && entradaInicial < entregaInicial) ? entradaInicial : entregaInicial;
    let dataFinal = (entradaFinal && entradaFinal > entregaFinal) ? entradaFinal : entregaFinal;
    let range = [];
    let i = dataInicial;
    while (i <= dataFinal) {
      range.push(i+'');
      i++;
      let anoCorrente = (i+'').slice(0,4);
      if (i > parseInt(anoCorrente+'12')) {
				let proximoAno = parseInt(anoCorrente) + 1;
				i = parseInt(proximoAno+'01');
			}
    }
    return range;
  }

  function orderByProdutividadeDesc(data) {
    return (data || []).sort((a, b) => {
      if (a["Produtividade"] > b["Produtividade"]) return -1;
      if (a["Produtividade"] < b["Produtividade"]) return 1;
      return 0;
    })
  }

  return (
    <>
      <OpcoesDoGrafico
        onChangeApontamentos={apontamentos => {
          setApontamentosSelecionados([...apontamentos]);
        }}
      />
      <h3 className="dashboard-title">Produtividade <span style={{fontSize:'12px'}}>(Quantidade de peças por hora)</span></h3>
      <div className="dashboard-chart-description">
        {total && 
          <table className="m-auto fw-normal">
            <tbody>
              {filtros.entradaInicial && filtros.entradaInicial && 
                <tr>
                  <td className="text-right pr-2">Período de Entrada:</td>
                  <td className="text-left">
                    {(() => {
                      return filtros.entradaInicial.split("-").reverse().join("/") + ' a ' + filtros.entradaInicial.split("-").reverse().join("/");
                    })()}
                  </td>
                </tr>
              }
              {filtros.entregaInicial && filtros.entregaFinal && 
                <tr>
                  <td className="text-right pr-2">Período de Entrega:</td>
                  <td className="text-left">
                    {(() => {
                      return filtros.entregaInicial.split("-").reverse().join("/") + ' a ' + filtros.entregaFinal.split("-").reverse().join("/");
                    })()}
                  </td>
                </tr>
              }
              <tr>
                <td className="text-right pr-2">Total de peças:</td>
                <td className="text-left">{Number(total.quantidade).toLocaleString('pt-BR')}</td>
              </tr>
              <tr>
                <td className="text-right pr-2">Horas de Disp. de Máquina:</td>
                <td className="text-left">
                  {Number(total.horasDisponibilidadeDeMaquina).toLocaleString('pt-BR')} 
                  {` (Média: ${Number(total.quantidade / total.horasDisponibilidadeDeMaquina).toLocaleString('pt-BR', { minimumFractionDigits: 1, maximumFractionDigits: 1 })} pçs/h)`}
                </td>
              </tr>
              <tr>
                <td className="text-right pr-2">Horas de Produção:</td>
                <td className="text-left">
                  {Number(total.horasProducao).toLocaleString('pt-BR')}
                  {` (Média: ${Number(total.quantidade / total.horasProducao).toLocaleString('pt-BR', { minimumFractionDigits: 1, maximumFractionDigits: 1 })} pçs/h)`}
                </td>
              </tr>
              <tr>
                <td className="text-right pr-2">Horas de Setup:</td>
                <td className="text-left">{Number(total.horasSetup).toLocaleString('pt-BR')}</td>
              </tr>
              <tr>
                <td className="text-right pr-2">Horas de Parada Prog.:</td>
                <td className="text-left">{Number(total.horasParadaProgramada).toLocaleString('pt-BR')}</td>
              </tr>
              <tr>
                <td className="text-right pr-2">Horas de Parada Não Prog.:</td>
                <td className="text-left">{Number(total.horasParadaNaoProgramada).toLocaleString('pt-BR')}</td>
              </tr>
            </tbody>
          </table>
        }
      </div>
      <div style={{ height: '500px' }}>
        {isLoading &&
          <div>Carregando gráfico...</div>
        }
        {!isLoading && data && data.data.length == 0 &&
          <div>Não há dados para formar o gráfico com os filtros que você usou.</div>
        }
        {!isLoading && formattedData &&
          <ResponsiveBar
            data={formattedData}
            keys={nivoBarConfig.keys}
            groupMode={nivoBarConfig.groupMode}
            indexBy={nivoBarConfig.indexBy}
            margin={nivoBarConfig.margin}
            padding={0.3}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={nivoBarConfig.colors}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            valueFormat={v => `${parseInt(v)}`}
            legends={[
              {
                dataFrom: 'keys',
                toggleSerie: true,
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemOpacity: 1
                    }
                  }
                ]
              }
            ]}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: -45,
              legend: '',
              legendPosition: 'middle',
              legendOffset: 32
            }}
            axisLeft={{
              format: value => `${parseInt(value)} pçs`,
            }}
            tooltip={function(config) {
              return <div style={{
                backgroundColor: config.color,
                boxShadow: '0px 0px 25px 5px rgba(0,0,0,0.46)',
                color: '#fff',
                padding: '10px 18px',
                fontWeight: 'bold'
              }}>
                {config.indexValue}<br />
                {filtros.agruparPor === 'mes' && <>{config.id}<br /></>}
                Peças p/ Hora: {Number(config.value.toFixed(1)).toLocaleString('pt-BR')}
              </div>
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor="blacks"
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            theme={{
              "grid": {
                "line": {
                    "stroke": "#a8a8a8",
                    "strokeWidth": 1
                },
              },
              "axis": {
                "ticks": {
                  "text": {
                    "fontSize": 12,
                    "fill": "#000"
                  }
                }
              }
            }}
          />
        }
      </div>
    </>
  );
}


function OpcoesDoGrafico({
  config,
  onChangeApontamentos,
  ...props
}) {

  const [apontamentosSelecionados, setApontamentosSelecionados] = useState([
    "tempoDisponibilidadeDeMaquina"
  ]);

  useEffect(() => {
    onChangeApontamentos(apontamentosSelecionados);
  }, [apontamentosSelecionados]);

  function checkApontamento(e) {
    let apontamentos = apontamentosSelecionados.filter(a => a !== e.target.name);
    if (e.target.checked === false && e.target.name === "tempoDisponibilidadeDeMaquina") {
      apontamentos = ["tempoProducao", "tempoSetup", "tempoParadaProgramada", "tempoParadaNaoProgramada"];
    } else if (e.target.checked === true && e.target.name === "tempoDisponibilidadeDeMaquina") {
      apontamentos = [e.target.name];
    } else if (e.target.checked === true) {
      apontamentos = apontamentosSelecionados.filter(a => a !== "tempoDisponibilidadeDeMaquina");
      apontamentos.push(e.target.name);
    } else if (apontamentos.length === 0) {
      apontamentos = ["tempoDisponibilidadeDeMaquina"];
    }
    setApontamentosSelecionados([...apontamentos]);
  }

  return (
    <div className="position-relative">
      <div className="position-absolute" style={{ right: 0 }}>

        <div className="dropdown d-inline-flex">
          <button
            className="btn btn-primary dropdown-toggle"
            type="button"
            data-toggle="dropdown"
          >
            Apontamentos
          </button>
          <div className="dropdown-menu dropdown-menu-right">
            <form className="px-3 py-2">
              <div className="form-group mb-0">
                <div className="form-check">
                  <label className="form-check-label text-nowrap">
                    <input
                      name="tempoDisponibilidadeDeMaquina"
                      className="form-check-input"
                      type="checkbox"
                      checked={apontamentosSelecionados.includes("tempoDisponibilidadeDeMaquina")}
                      onChange={checkApontamento}
                    />
                    Disponibilidade de Máquina
                  </label>
                </div>
                <div className="form-check">
                  <label className="form-check-label text-nowrap">
                    <input
                      name="tempoProducao"
                      className="form-check-input"
                      type="checkbox"
                      checked={apontamentosSelecionados.includes("tempoProducao")}
                      onChange={checkApontamento}
                    />
                    Tempo de Produção
                  </label>
                </div>
                <div className="form-check">
                  <label className="form-check-label text-nowrap">
                    <input
                      name="tempoSetup"
                      className="form-check-input"
                      type="checkbox"
                      checked={apontamentosSelecionados.includes("tempoSetup")}
                      onChange={checkApontamento}
                    />
                    Tempo de Setup
                  </label>
                </div>
                <div className="form-check">
                  <label className="form-check-label text-nowrap">
                    <input
                      name="tempoParadaProgramada"
                      className="form-check-input"
                      type="checkbox"
                      checked={apontamentosSelecionados.includes("tempoParadaProgramada")}
                      onChange={checkApontamento}
                    />
                    Tempo de Parada Programada
                  </label>
                </div>
                <div className="form-check">
                  <label className="form-check-label text-nowrap">
                    <input
                      name="tempoParadaNaoProgramada"
                      className="form-check-input"
                      type="checkbox"
                      checked={apontamentosSelecionados.includes("tempoParadaNaoProgramada")}
                      value="disponibilidade_maquina"
                      onChange={checkApontamento}
                    />
                    Tempo de Parada Não Programada
                  </label>
                </div>
              </div>
            </form>
          </div>

        </div>
      </div>
    </div>
  );
}