import React from 'react';

export default function InputTypeFloat(
    { onChange, value, locale, className, style, fixed, decPoint, thousandsSeparation, ...rest }
) {

    locale = 'pt-BR';
    style = { textAlign: 'right', ...style }
    fixed = fixed ? parseInt(fixed) : 2;
    decPoint = decPoint ? decPoint : ',';
    thousandsSeparation = thousandsSeparation ? thousandsSeparation : '.';

    function numberFormat(number) {
        if ( !number || isNaN(number)) {
            number = 0;
        }
        return new Intl.NumberFormat([locale], { 
            minimumFractionDigits: fixed
        }).format(number);
    };

    return (
        <input
            type="text"
            className={className}
            style={style}
            value={value === null ? "" : numberFormat(value)}
            onKeyPress={e => {
                if (/[0-9,\.]/.test(e.key) == false) {
                    e.preventDefault();
                }
            }}
            onChange={e => {
                let v = e.target.value
                    .replace(new RegExp("\\"+thousandsSeparation,"g"), '')
                    .replace(new RegExp("\\"+decPoint,"g"), '');
                v = parseInt(v).toString().padStart(fixed + 1,"0");
                v = v.substr(0, v.length - fixed) + '.' + v.substr(-fixed);
                v = parseFloat(v);
                v = isNaN(v) ? 0.00 : v;
                if (onChange) {
                    onChange(v, e);
                }
            }}
            {...rest}
        />
    );
}