import React, { useState } from 'react';
import './LoginPage.css';
import appConfig from '../../AppConfig';
import auth from "../../services/auth";
import LayoutUnlogged from '../LayoutUnlogged';

export default function LoginPage({ history, match }) {

  const [usuario, setUsuario] = useState();
  const [email, setEmail] = useState();
  const [senha, setSenha] = useState();

  function tipoDeLogin() {
    if (match.path === '/login-gestor') {
      return 'gestor';
    }
    return 'estamparia';
  }

  async function handleSubmit(e) {
    e.preventDefault();
    let response;
    let data = tipoDeLogin() === 'gestor' ? {
      email: email,
      senha: senha
    } : {
      usuario: usuario,
      senha: senha
    };
    try {
      response = await auth.login(data, tipoDeLogin());
    } catch (error) {
      if (error.response.status < 500) {
        alert(error.response.data.description);
      } else {
        alert("Ocorreu um erro no servidor.");
      }
      return;
    }

    if (response.data.status === 'ok') {
      history.push(`${appConfig.baseURL}`);
    } else {
      alert('Usuário e senha não confere.');
    }
  }

  return (
    <LayoutUnlogged>
      <div className="login-container">
        <h2 className="page-title">Login</h2>
        <form className="login-form" onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group col-md-12">
              {tipoDeLogin() === 'estamparia' ? 
                <input
                  type="text"
                  className="form-control"
                  onChange={e => {
                    setUsuario(e.target.value);
                  }}
                  placeholder="Usuário"
                /> :
                <input
                  type="text"
                  className="form-control"
                  onChange={e => {
                    setEmail(e.target.value);
                  }}
                  placeholder="E-mail"
                />
              }
            </div>
            <div className="form-group col-8">
              <input
                type="password"
                className="form-control"
                onChange={e => {
                  setSenha(e.target.value);
                }}
                placeholder="Senha"
              />
            </div>
            <div className="form-group col-4">
              <button type="submit" className="btn btn-primary">Enviar</button>
            </div>
          </div>
        </form>
      </div>
    </LayoutUnlogged>
  );
}
