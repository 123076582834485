import React, { useState, useRef } from 'react';
import modalManager from '../../services/modalManager';
import FornecedorList from './FornecedorList';
import FornecedorForm from './FornecedorForm';
import FornecedorModel from '../../models/Fornecedor';

export default function ModalFornecedor(props) {

  const [fornecedorSelecionado, setFornecedorSelecionado] = useState();
  const [reloadFornecedores, setReloadFornecedores] = useState(1);
  const fornecedoresTab = useRef();
  const fornecedorFormTab = useRef();

  function openTabEditarFornecedor() {
    fornecedoresTab.current.style.display = 'none';
    fornecedorFormTab.current.style.display = 'block';
  }

  function closeFormTab() {
    fornecedoresTab.current.style.display = 'block';
    fornecedorFormTab.current.style.display = 'none';
  }

  return (
    <div className="modal fade" id="modalFornecedor" role="dialog" data-backdrop="static">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Fornecedores</h5>
            <button type="button" className="close" onClick={modalManager.hide}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div ref={fornecedoresTab}>
              <FornecedorList 
                showPageTitle={false}
                onClickCadastrar={e => {
                  setFornecedorSelecionado({...FornecedorModel});
                  openTabEditarFornecedor();
                }}
                onClickEditar={(e, fornecedor) => {
                  setFornecedorSelecionado({...fornecedor});
                  openTabEditarFornecedor();
                }} 
                onSelect={(e, fornecedor) => {
                  if (props.onSelect) {
                    props.onSelect(e, fornecedor);
                  }
                }}
                reload={reloadFornecedores}
              />
            </div>
            <div ref={fornecedorFormTab} style={{ display: 'none' }}>
              <FornecedorForm
                showPageTitle={false}
                fornecedor={fornecedorSelecionado}
                onCancel={e => {
                  closeFormTab();
                }}
                onSaveSucess={response => {
                  setReloadFornecedores(reloadFornecedores + 1);
                  closeFormTab();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
