import React, { useState, useEffect, useMemo } from 'react';
import api from '../../services/api';
import { ResponsiveBar } from '@nivo/bar';
import MenuDropdown from '../../components/Html/MenuDropdown';
import MenuRadio from '../../components/Dashboard/MenuRadio';
import MenuContainer from '../../components/Dashboard/MenuContainer';
import MenuGroupMode from '../../components/Dashboard/MenuGroupMode';

export default function SetupPorQuadro({ filtros, metadados }) {

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const [clientes, setClientes] = useState([]);
  const [maquinas, setMaquinas] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [menuIndice, setMenuIndice] = useState(filtros.analisarPor === 'maquina' ? 'maquina' : 'cliente');
  const [chartConfig, setChartConfig] = useState({
    keys: [],
    groupMode: 'grouped',
    colors: ['#ed1c24', '#f26522', '#f7941d', '#cdc42a', '#8dc63f', '#39b54a', '#00a651', '#00a99d', '#00aeef', '#0072bc', '#0054a6', '#2e3192', '#662d91', '#92278f', '#ec008c', '#ed145b'],
    margin: { top: 50, right: 160, bottom: 150, left: 60 }
  });

  useEffect(() => {
    if (filtros) {
      submit();
    }
  }, [filtros]);

  const dadosComputados = useMemo(() => {
    if (!data || !clientes || !maquinas || !marcas) {
      return null;
    }
    return computaDados(data.data);
  }, [data, clientes, maquinas, marcas, menuIndice]);

  async function submit() {
    let data;
    try {
      setIsLoading(true);
      data = await loadData();
    } catch (error) {
      setIsLoading(false);
      if (error.response.status < 500) {
        alert(error.response.data.description);
      } else {
        alert("Ocorreu um erro no servidor e não foi possível carregar o gráfico. Por favor, tente novamente.");
      }
      return;
    }
    setData(data.data);
    setIsLoading(false);
    let indices = extraiIndices(data.data.data);
    setClientes(indices.clientes);
    setMaquinas(indices.maquinas);
    setMarcas(indices.marcas);
  }

  async function loadData() {
    const query = new URLSearchParams('');
    query.append('periodoInicial', filtros.periodoInicial);
    query.append('periodoFinal', filtros.periodoFinal);
    return await api.get(`/relatorios/estamparia/setupporquadro?` + query);
  }

  function extraiIndices(data) {
    let clientes = [];
    let maquinas = [];
    let marcas = [];
    for (const row of data) {
      if (clientes.findIndex(idx => idx.id === row.clienteId) < 0) {
        clientes.push({
          id: row.clienteId,
          label: row.cliente,
          checked: true
        });
      }
      if (maquinas.findIndex(idx => idx.id === row.maquinaId) < 0) {
        maquinas.push({
          id: row.maquinaId,
          label: row.maquina,
          checked: true
        });
      }
      if (marcas.findIndex(idx => idx.id === row.marca) < 0) {
        marcas.push({
          id: row.marca,
          label: row.marca,
          checked: true
        });
      }
    }
    return {
      clientes: orderByNome(clientes),
      maquinas: orderByNome(maquinas),
      marcas: orderByNome(marcas)
    }
  }

  function computaDados(dados) {
    let maquinasEscolhidas = maquinas.filter(v => v.checked === true);
    let clientesEscolhidos = clientes.filter(v => v.checked === true);
    let marcasEscolhidas = marcas.filter(v => v.checked === true);
    let dadosFiltrados = dados.filter(d => 
      maquinasEscolhidas.find(v => v.id === d.maquinaId)
      && clientesEscolhidos.filter(v => v.id === d.clienteId)
      && marcasEscolhidas.filter(v => v => v.id === d.marca)
    );

    let dadosDoIndiceEscolhido = [];
    if (menuIndice === 'maquina') {
      dadosDoIndiceEscolhido = maquinasEscolhidas;
    } else if (menuIndice === 'cliente') {
      dadosDoIndiceEscolhido = clientesEscolhidos;
    } else if (menuIndice === 'marca') {
      dadosDoIndiceEscolhido = marcasEscolhidas;
    }

    let quantidadeQuadros = faixaDeQuantidadeDeQuadros(dadosFiltrados);
    let dadosFormatados = [];
    for (const quantidade of quantidadeQuadros) {
      let dadosPorQuadro = dadosFiltrados.filter(dado => dado.quantidadeQuadros === quantidade);
      let dadoFormatado = {
        id: quantidade + ((quantidade === 1) ? ' Quadro' : ' Quadros')
      };
      let totalOps = 0;
      let totalTempo = 0;
      dadosDoIndiceEscolhido.forEach(dadoDoIndiceEscolhido => {
        let dadosPorIndice = dadosPorQuadro.filter(dado => dado[menuIndice + 'Id'] === dadoDoIndiceEscolhido.id);
        let quantidadeOp = dadosPorIndice.reduce((acc, cur) => acc + cur.quantidadeOp, 0);
        let tempoSetup = dadosPorIndice.reduce((acc, cur) => acc + cur.tempoTotalSetupMinutos, 0);
        let mediaDeTempo = quantidadeOp > 0 ? (tempoSetup / quantidadeOp) : 0;
        dadoFormatado[dadoDoIndiceEscolhido.label] = mediaDeTempo;
        dadoFormatado[dadoDoIndiceEscolhido.label + 'QtdOps'] = quantidadeOp;
        totalOps += quantidadeOp;
        totalTempo += tempoSetup;
      });
      dadoFormatado["mediaTotal"] = totalOps > 0 ? (totalTempo / totalOps) : 0;
      dadosFormatados.push(dadoFormatado);
    }
    setChartConfig({
      ...chartConfig, 
      keys: dadosDoIndiceEscolhido.map(v => v.label)
    });
    return dadosFormatados;
  }

  function orderByNome(nomes) {
    return (nomes || []).sort((a, b) => {
      if (a.label > b.label) return 1;
      if (a.label < b.label) return -1;
      return 0;
    })
  }

  function faixaDeQuantidadeDeQuadros(relatorio) {
    let faixa = [];
    for (const linha of relatorio) {
      if (faixa.indexOf(linha.quantidadeQuadros) === -1) {
        faixa.push(linha.quantidadeQuadros);
      }
    }
    return (faixa || []).sort((a, b) => {
      if (a > b) return 1;
      if (a < b) return -1;
      return 0;
    })
  }

  return (
    <>
      {!isLoading &&
        <>
          <MenuContainer 
            groups={[
              [
                <MenuRadio 
                  values={[{
                      id: 'maquina',
                      label: 'Máquina',
                    }, {
                      id: 'cliente',
                      label: 'Cliente'
                    }, {
                      id: 'marca',
                      label: 'Marca'
                    }
                  ]}
                  checked={menuIndice}
                  onChange={novoIndex => {
                    setMenuIndice(novoIndex)
                  }}
                />
              ],
              [
                <MenuDropdown
                  title="Máquinas"
                  staticValues={maquinas}
                  onChange={maquinas => {
                    setMaquinas(maquinas);
                  }}
                  itemClass="text-nowrap"
                />,
                <MenuDropdown
                  title="Clientes"
                  staticValues={clientes}
                  onChange={clientes => {
                    setClientes(clientes);
                  }}
                  itemClass="text-nowrap"
                />,
                <MenuDropdown
                  title="Marcas"
                  staticValues={marcas}
                  onChange={marcas => {
                    setMarcas(marcas);
                  }}
                  itemClass="text-nowrap"
                />
              ],
              [
                <MenuGroupMode
                  checked={chartConfig.groupMode}
                  onChange={mode => {
                    chartConfig.groupMode = mode;
                    setChartConfig({...chartConfig})
                  }}
                />
              ]
            ]}
          />
        </>
      }
      <h3 className="dashboard-title">Produção</h3>
      {dadosComputados && dadosComputados.length > 0 &&
        <div className="dashboard-chart-description">
          Período: {new Date(data.filtrosAplicados.periodoInicial).toLocaleDateString()} a {new Date(data.filtrosAplicados.periodoFinal).toLocaleDateString()}<br />
        </div>
      }
      <div style={{ height: '550px' }}>
        {isLoading &&
          <div>Carregando gráfico...</div>
        }
        {!isLoading && data?.data.length == 0 &&
          <div>Não há dados para formar o gráfico com os filtros que você usou.</div>
        }
        {!isLoading && data?.data.length > 0 &&
          <ResponsiveBar
            data={dadosComputados}
            keys={chartConfig.keys}
            groupMode={chartConfig.groupMode}
            menuIndice="id"
            margin={chartConfig.margin}
            padding={0.3}
            valueFormat={value =>
              `${Number(value).toLocaleString('pt-BR')}`
            }
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={chartConfig.colors}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            legends={[
              {
                dataFrom: 'keys',
                toggleSerie: true,
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 20,
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemOpacity: 1
                    }
                  }
                ]
              }
            ]}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: -45,
              legend: '',
              legendPosition: 'middle',
              legendOffset: 32
            }}
            axisLeft={{
              format: value =>
                `${Number(value).toLocaleString('pt-BR')}`,
            }}
            axisTop={{
              tickSize: 5,
              tickPadding: 5,
              legend: '',
              legendPosition: 'middle',
              legendOffset: 32,
              format: function(value) {
                let colunaDeDados = dadosComputados.filter(d => d.id === value)[0];
                let mediaTotal = colunaDeDados?.mediaTotal || 0;
                return `${Number(mediaTotal).toLocaleString('pt-BR')} min.`;
              },
            }}
            tooltip={function(config) {
              return <div style={{
                backgroundColor: config.color,
                boxShadow: '0px 0px 25px 5px rgba(0,0,0,0.46)',
                color: '#fff',
                padding: '10px 18px',
                fontWeight: 'bold'
              }}>
                {config.id}<br />
                {config.indexValue}<br />
                Média de Setup: {Number(config.value).toLocaleString('pt-BR') + ' min.'}<br />
                Quantidade OPs: {Number(config.data[config.id + 'QtdOps']).toLocaleString('pt-BR')}
              </div>
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor="rgb(255,255,255)"
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            enableGridX={true}
            theme={{
              "grid": {
                "line": {
                    "stroke": "#a8a8a8",
                    "strokeWidth": 1
                },
              },
              "axis": {
                "ticks": {
                  "text": {
                    "fontSize": 12,
                    "fill": "#000"
                  }
                }
              }
            }}
          />
        }
      </div>
    </>
  );
}