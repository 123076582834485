const Estamparia = {
    id: 0,
    nome: "",
    modulo: "",
    hasModulo: function(modulo) {
        let modulos = this.modulo.split(',');
        return modulos.indexOf(modulo) >= 0 ? true : false;
    }
}

export default Estamparia;