import React from 'react';
import modalManager from '../../services/modalManager';
import ModalCorList from '../Cor/ModalCorList';
import ModalEditarCorSelecionada from './ModalEditarCorSelecionada';
import RemovePastaDialog from './RemovePastaDialog';
import RemoveCorDialog from './RemoveCorDialog';
import OPCorModel from '../../models/OPCor';

export default function PastaForm(props) {
  const ordem = props.ordem;
  const setOrdem = props.setOrdem;
  const permiteEditar = props.permiteEditar;

  function adicionaCor(e, pasta) {
    e.preventDefault();
    showModalSelecionarCor(pasta);
  }

  function showModalSelecionarCor(pasta) {
    modalManager.mount(
      'modalCorList',
      <ModalCorList
        onSelect={(e, cor) => {
          modalManager.mount(
            'modalEditarCorSelecionada',
            <ModalEditarCorSelecionada
              cor={cor}
              onConfirm={(e, corSelecionada) => {
                e.preventDefault();
                let cor = {...OPCorModel};
                cor._corId = corSelecionada.id;
                cor.codigo = corSelecionada.codigo;
                cor.unidadeMedida = corSelecionada.unidadeMedida;
                cor.custoPorQuilo = corSelecionada.custoPorQuilo;
                cor.quantidade = corSelecionada.quantidade;
                cor.quantidadeRetornada = corSelecionada.quantidadeRetornada;
                cor.ingredientes = [...corSelecionada.ingredientes];
                pasta.cores = [...pasta.cores, {...cor}];
                setOrdem({...ordem});
                modalManager.hide();
                modalManager.hide();
              }}
              onCancel={(e) => {
                modalManager.hide();
              }}
            />
          );
          modalManager.show('modalEditarCorSelecionada');
        }}
      />
    );
    modalManager.show('modalCorList');
  }

  function onClickEditarCor(e, cor) {
    showModalEditarCor(cor);
  }

  function showModalEditarCor(cor) {
    modalManager.mount(
      'modalEditarCorSelecionada',
      <ModalEditarCorSelecionada
        cor={{...cor}}
        onCancel={e => {
          modalManager.hide();
        }} 
        onConfirm={(e, corModificada) => {
          e.preventDefault();
          ordem.pastas.map(pasta => {
            let index = pasta.cores.indexOf(cor);
            if (index > -1) pasta.cores[index] = {...corModificada};
          });
          setOrdem({...ordem});
          modalManager.hide();
        }}
      />
    );
    modalManager.show('modalEditarCorSelecionada');
  }

  function removePasta(pasta) {
    modalManager.mount(
      'removePastaDialog',
      <RemovePastaDialog
        onConfirm={e => {
          let pastaIdx = ordem.pastas.indexOf(pasta);
          ordem.pastas.splice(pastaIdx, 1);
          setOrdem({...ordem});
          modalManager.hide();
        }}
        onCancel={e => {
          modalManager.hide();
        }}
      />
    );
    modalManager.show('removePastaDialog');
  }

  function removeCor(pasta, cor) {
    modalManager.mount(
      'removeCorDialog',
      <RemoveCorDialog
        cor={cor}
        onConfirm={e => {
          let corIdx = pasta.cores.findIndex(elm => elm.id == cor.id);
          pasta.cores.splice(corIdx, 1);
          setOrdem({...ordem});
          modalManager.hide();
        }}
        onCancel={e => {
          modalManager.hide();
        }}
      />
    );
    modalManager.show('removeCorDialog');
  }

  return (
    (ordem.pastas || []).map((pasta, index) => (
      <div key={index} className={`form-group -box ${permiteEditar() ? '-box--withfooter' : ''} pasta`}>
        <div className="form-row">
          <div className="form-group col-12">
            <label>Cor Peça</label>
            <input
              className="form-control"
              value={pasta.corPeca || ""}
              onChange={e => {
                pasta.corPeca = e.target.value;
                setOrdem({ ...ordem });
              }}
              disabled={!permiteEditar()}
            />
          </div>
          <div className="form-group col-12">
            <label>Técnica</label>
            <input
              className="form-control"
              value={pasta.tecnica || ""}
              onChange={e => {
                pasta.tecnica = e.target.value;
                setOrdem({ ...ordem });
              }}
              disabled={!permiteEditar()}
            />
          </div>
          <div className="form-group col-12">
            <label>Cura</label>
            <select
              className="form-control"
              value={pasta.cura || ""}
              onChange={e => {
                pasta.cura = e.target.value;
                setOrdem({ ...ordem });
              }}
              disabled={!permiteEditar()}>
              <option value=""></option>
              <option value="cura_ar">Cura Ar</option>
              <option value="cura_estufa">Cura Estufa</option>
            </select>
          </div>

          {pasta.cores.length > 0 && <div className="cores-title">Cores:</div>}

          {(pasta.cores || []).map((cor, corIndex) => (
            <div 
              key={corIndex} 
              className="form-group col-12 cores-container">
                <div className="codigo">
                  {cor.codigo} <small>({(cor.ingredientes || []).map((ingrediente, index) => {
                    return (index == (cor.ingredientes.length-1)) 
                      ? ingrediente.materiaPrima.lote
                      : ingrediente.materiaPrima.lote + ', ';
                  })})</small>
                </div>
                <div>Quantidade: {cor.quantidade} kg</div>
                <div>Qtd. Retornada: {cor.quantidadeRetornada} kg</div>
                {
                  permiteEditar() ?
                    <div className="menu">
                      <button type="button" onClick={e => onClickEditarCor(e, cor)}>
                        <i className="icofont-ui-edit"></i> Modificar
                      </button>
                      <button type="button" onClick={e => removeCor(pasta, cor)}>
                        <i className="icofont-ui-delete"></i> Remover
                      </button>
                    </div> 
                    : ''
                }
            </div>
          ))}
          {
            permiteEditar() ?
              <div className="menu-container">
                <button type="button" onClick={e => adicionaCor(e, pasta)}>Adicionar Cor</button>
              </div>
              : ''
          }
        </div>
        {
          permiteEditar() ?
            <div className="footer menu-container">
              <button type="button" onClick={e => removePasta(pasta)}>
                <i className="icofont-ui-delete"></i> Remover Pasta
              </button>
            </div>
            : ''
        }
      </div>
    ))
  );
}