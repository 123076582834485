import React, { useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import appConfig from '../../AppConfig';
import api from '../../services/api';
import numberFormat from '../../helpers/numberFormat';
import LayoutLogged from '../LayoutLogged';
import modalManager from '../../services/modalManager';
import OPCorModel from '../../models/OPCor';
import OPTinta from '../../models/OPTinta';
import InputTypeFloat from '../../components/Html/InputTypeFloat';
import ModalCliente from '../../components/Cliente/ModalCliente';
import ModalCorList from '../../components/Cor/ModalCorList';
import RemoveCorDialog from '../../components/OPTinta/RemoveCorDialog';

export default function FormPage({ history }) {

  const { ordemId } = useParams();
  const [ordem, _setOrdem] = useState({ ...OPTinta });

  function setOrdem(ordem) {
    ordem._dirty = true;
    _setOrdem(ordem);
  }

  useEffect(() => {
    if (ordemId) {
      loadOrdem();
    }
  }, []);

  async function loadOrdem() {
    let response;
    try {
      response = await api.get(`/op-tinta/${ordemId}`);
    } catch(error) {
      if (error.response.status < 500) {
        alert(error.response.data.description);
      } else {
        alert("Ocorreu um erro no servidor e não foi possível carregar a ordem de producação da tinta. Por favor, tente novamente.");
      }
      return;
    }
    _setOrdem(response.data);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if ( ! validaForm()) {
      return false;
    }
    try {
      await api.post('/op-tinta', ordem);
    } catch (error) {
      if (error.response.status < 500) {
        alert(error.response.data.description);
      } else {
        alert("Ocorreu um erro no servidor.");
      }
      return;
    }
    history.push(`${appConfig.baseURL}/op-tinta`);
  }

  function validaForm() {
    if (ordem.cliente.id < 1) {
      alert('É necessário selecionar um cliente.');
      return false;
    }
    if (ordem.dataEntrada == "") {
      alert('Preencha uma data de entrada para a Ordem.');
      return false;
    }
    if (ordem.horaEntrada == "") {
      alert('Preencha uma hora de entrada para a Ordem.');
      return false;
    }
    if (ordem.cores.length == 0) {
      alert('Escolha as cores para compor a Ordem de Produção.');
      return false;
    }
    return true;
  }

  function showModalSelecionarCliente() {
    modalManager.mount(
      'modalCliente',
      <ModalCliente
        onSelect={(e, cliente) => {
          e.preventDefault();
          ordem.cliente = {...cliente};
          setOrdem({...ordem});
          modalManager.hide();
        }}
      />
    );
    modalManager.show('modalCliente');
  }

  function showModalSelecionarCor() {
    modalManager.mount(
      'modalCorList',
      <ModalCorList
        onSelect={(e, corSelecionada) => {
          e.preventDefault();
          let cor = {...OPCorModel};
          cor._corId = corSelecionada.id;
          cor.codigo = corSelecionada.codigo;
          cor.unidadeMedida = corSelecionada.unidadeMedida;
          cor.custoPorQuilo = corSelecionada.custoPorQuilo;
          cor.quantidade = 0;
          cor.ingredientes = [...corSelecionada.ingredientes];
          ordem.cores = [...ordem.cores, cor];
          setOrdem({...ordem});
          modalManager.hide();
        }}
      />
    );
    modalManager.show('modalCorList');
  }

  function removeCor(e, cor) {
    modalManager.mount(
      'removeCorDialog',
      <RemoveCorDialog
        cor={cor}
        onConfirm={e => {
          e.preventDefault();
          ordem.cores.splice(ordem.cores.indexOf(cor), 1);
          setOrdem({...ordem});
          modalManager.hide();
        }}
        onCancel={e => {
          modalManager.hide();
        }}
      />
    );
    modalManager.show('removeCorDialog');
  }

  function calculaCustoDaCor(cor) {
    let quantidade = isNaN(cor.quantidade) ? 0 : cor.quantidade;
    let quantidadeRetornada = isNaN(cor.quantidadeRetornada) ? 0 : cor.quantidadeRetornada;
    let quantidadeUsada = quantidade - quantidadeRetornada;
    return cor.custoPorQuilo * quantidadeUsada;
  }

  function calculaCustoTotal() {
    let total = 0;
    ordem.cores.map(cor => total += calculaCustoDaCor(cor));
    return total;
  }

  return (
    <LayoutLogged>
      <h2 className="page-title">Ordem de Produção de Tinta</h2>
      <form className="ordemproducao-form" onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="form-group col-md-3">
            <label>Cliente</label>
            <div>
              {ordem.cliente.id == 0 &&
                <button type="button" onClick={e => showModalSelecionarCliente()}>
                  Selecionar Cliente
                </button>}
              {ordem.cliente.id > 0 && 
                <a href="#" 
                  onClick={e => {
                    e.preventDefault();
                    showModalSelecionarCliente();
                  }}>{ordem.cliente.nome}</a>
              }
            </div>
          </div>
          <div className="form-group col-md-2">
            <label>Data Entrada</label>
            <input
              type="date"
              className="form-control"
              value={ordem.dataEntrada}
              onChange={e => {
                ordem.dataEntrada = e.target.value;
                setOrdem({ ...ordem });
              }}
            />
          </div>
          <div className="form-group col-md-2">
            <label>Hora Entrada</label>
            <input
              type="time"
              className="form-control"
              value={ordem.horaEntrada}
              onChange={e => {
                ordem.horaEntrada = e.target.value;
                setOrdem({ ...ordem });
              }}
            />
          </div>
          <div className="form-group col-md-2">
            <label>Referência</label>
            <input
              className="form-control"
              value={ordem.referencia || ""}
              onChange={e => {
                ordem.referencia = e.target.value;
                setOrdem({ ...ordem });
              }}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <button 
              type="button" 
              className="btn btn-secondary" 
              onClick={e => { showModalSelecionarCor(); }}>
              Escolher Cor
            </button>
          </div>
          <table className="table table-hover table-sm">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Código</th>
                <th scope="col">Custo/Kg</th>
                <th scope="col" style={{width:'160px'}}>Quantidade (Kg)</th>
                <th scope="col" style={{width:'220px'}}>Quant. Retornada (Kg)</th>
                <th scope="col" style={{width:'160px'}}>Total</th>
                <th scope="col">Ações</th>
              </tr>
            </thead>
            <tbody>
              {(ordem.cores || []).map((cor, index) => (
                <tr key={index}>
                  <td>
                    <strong>{cor.codigo}</strong><br />
                    <small>Lote: {(cor.ingredientes || []).map((ingrediente, index) => {
                      return ingrediente.materiaPrima.lote + (cor.ingredientes.length != (index + 1) ? ', ' : '');
                    })}</small>
                  </td>
                  <td>R$ {numberFormat(cor.custoPorQuilo)}</td>
                  <td>
                    <InputTypeFloat
                      fixed={3}
                      style={{width: '110px'}}
                      className="form-control"
                      value={cor.quantidade}
                      onChange={value => {
                        cor.quantidade = value;
                        setOrdem({...ordem});
                      }}
                    />
                  </td>
                  <td>
                    <InputTypeFloat
                      fixed={3}
                      style={{width: '110px'}}
                      className="form-control"
                      value={cor.quantidadeRetornada}
                      onChange={value => {
                        cor.quantidadeRetornada = value;
                        setOrdem({...ordem});
                      }}
                    />
                  </td>
                  <td>
                    R$ {new Intl.NumberFormat(['pt-BR'], { minimumFractionDigits: 2}).format(
                      calculaCustoDaCor(cor)
                    )}
                  </td>
                  <td>
                    <button type="button" onClick={e => removeCor(e, cor) }>
                      <i className="icofont-ui-delete"></i>
                    </button>
                  </td>
                </tr>
              ))}
              {ordem.cores.length == 0 && 
                <tr>
                  <td colSpan="5" style={{padding:'20px', textAlign:'center'}}>
                    Escolha as cores que fazem parte desta Ordem.
                  </td>
                </tr>
              }
            </tbody>
            {ordem.cores.length > 0 &&
              <tfoot>
                <tr>
                  <td colSpan="4" style={{textAlign:"right"}}>Total:</td>
                  <td>R$ {new Intl.NumberFormat(['pt-BR'], { minimumFractionDigits: 2}).format(
                    calculaCustoTotal()
                  )}</td>
                </tr>
              </tfoot>
            }
          </table>
        </div>
        <button type="submit" className="btn btn-primary mr-2">Salvar</button>
        <Link 
          className={`btn btn-outline-secondary ${!ordem.id ? 'disabled':''}`} 
          to={`/op-tinta/${ordem.id}/print`}>
            <i className="icofont-printer"></i> Imprimir
        </Link>
      </form>
    </LayoutLogged>
  );
}