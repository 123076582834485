import axios from 'axios';
import auth from "./auth";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: { 'content-type': 'application/x-www-form-urlencoded' }
});

api.interceptors.request.use(async config => {
  const token = auth.getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  if (auth.isAuthenticated()) {
    const usuario = auth.getUsuario();
    if (usuario.role == 'gestor') {
      const url = new URL(config.baseURL + config.url);
      const selectEstamparias = document.getElementById('selectEstamparias');
      if (selectEstamparias) {
        url.searchParams.set('estampariaId', selectEstamparias.value);
        config.url = url.href;
      }
    }
  }

  return config;
});

api.interceptors.response.use(function(response) {
    return response;
  }, function(error) {
    if (error.response.status == 401) {
      auth.logout();
      window.location = '/login';
    }
    return Promise.reject(error);
  }
);

export default api;