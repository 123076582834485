import React, { useState, useRef } from 'react';
import { BrowserRouter } from 'react-router-dom';
import ClienteList from './ClienteList';
import ClienteForm from './ClienteForm';
import ClienteModel from '../../models/Cliente';
import FiltroDeCliente from './FiltroDeCliente';

export default function ModalCliente({ onSelect }) {

  const [filtros, setFiltros] = useState();
  const [clienteSelecionado, setClienteSelecionado] = useState();
  const [reloadClientes, setReloadClientes] = useState(1);
  const clientesTab = useRef();
  const clienteFormTab = useRef();

  function openTabEditarCliente() {
    clientesTab.current.style.display = 'none';
    clienteFormTab.current.style.display = 'block';
  }

  function closeFormTab() {
    clientesTab.current.style.display = 'block';
    clienteFormTab.current.style.display = 'none';
  }

  return (
    <BrowserRouter>
      <div className="modal fade" id="modalCliente" role="dialog" data-backdrop="static">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Clientes</h5>
              <button type="button" className="close" data-dismiss="modal">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div ref={clientesTab}>
                <FiltroDeCliente
                  workWithLocation={false}
                  onSubmit={(filtros) => {
                    setFiltros({...filtros});
                  }}
                  onClear={() => {
                    setFiltros({});
                  }}
                />
                <ClienteList
                  filtros={filtros}
                  showPageTitle={false}
                  onClickCadastrar={e => {
                    setClienteSelecionado({ ...ClienteModel });
                    openTabEditarCliente();
                  }}
                  onClickEditar={(e, cliente) => {
                    setClienteSelecionado({ ...cliente });
                    openTabEditarCliente();
                  }}
                  onSelect={(e, cliente) => {
                    if (onSelect) {
                      onSelect(e, cliente);
                    }
                  }}
                  reload={reloadClientes}
                />
              </div>
              <div ref={clienteFormTab} style={{ display: 'none' }}>
                <ClienteForm
                  showPageTitle={false}
                  cliente={clienteSelecionado}
                  onCancel={e => {
                    closeFormTab();
                  }}
                  onSaveSucess={response => {
                    setReloadClientes(old => old + 1);
                    closeFormTab();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
}
