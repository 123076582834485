import React, { useState } from 'react';
import modalManager from '../../services/modalManager';

export default function ModalDisponibilidadeForm(
  { onConfirm, onCancel, ...props }
) {

  const [disponibilidade, setDisponibilidade] = useState({ ...props.disponibilidade });

  function confirm(e) {
    if (!validaForm()) {
      e.preventDefault();
      return;
    }
    if (onConfirm) {
      onConfirm(e, disponibilidade);
    }
  }

  function cancel(e) {
    if (onCancel) {
      onCancel(e);
    }
  }

  function validaForm() {
    if ( ! /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}(:[0-9]{2})?/.exec(disponibilidade.inicio)) {
      alert('A data e hora de início precisam estar preenchidas corretamente.');
      return false;
    }
    if ( ! /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}(:[0-9]{2})?/.exec(disponibilidade.fim)) {
      alert('A data e hora do fim precisam estar preenchidas corretamente.');
      return false;
    }
    if ((new Date(disponibilidade.inicio)).getTime() >= (new Date(disponibilidade.fim)).getTime()) {
      alert('A data do início da Disponibilidade deve ser menor que a do fim');
      return false;
    }
    return true;
  }

  return (
    <div className="modal fade" id="modalDisponibilidadeForm" role="dialog" data-backdrop="static">
      <div className="modal-dialog modal-sm" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Modificar Disponibilidade</h5>
            <button type="button" className="close" onClick={modalManager.hide}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={confirm}>
              <div className="form-row">
                <div className="form-group col-md-12">
                  <label>Início</label>
                  <input
                    type="datetime-local"
                    className="form-control"
                    value={disponibilidade.inicio || ""}
                    onChange={e => {
                      disponibilidade.inicio = e.target.value;
                      setDisponibilidade({ ...disponibilidade });
                    }}
                    step="1"
                  />
                </div>
                <div className="form-group col-md-12">
                  <label>Fim</label>
                  <input
                    type="datetime-local"
                    className="form-control"
                    value={disponibilidade.fim || ""}
                    onChange={e => {
                      disponibilidade.fim = e.target.value;
                      setDisponibilidade({ ...disponibilidade });
                    }}
                    step="1"
                  />
                </div>
              </div>
              <div className="btn-group mr-2" role="group">
                <button type="button" className="btn btn-secondary" onClick={cancel}>Cancelar</button>
              </div>
              <div className="btn-group" role="group">
                <button type="submit" className="btn btn-primary">Ok</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}