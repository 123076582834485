import React, { useState } from 'react';
import { Link, useLocation, useHistory } from "react-router-dom";

export default function Pagination({
  totalPages, workWithLocation = true, onNav = (page) => { } 
}) {

  let history = useHistory();
  let query = new URLSearchParams(useLocation().search);
  const [currentPage, setCurrentPage] = useState(parseInt(query.get('page')) || 1);

  function getPrevPage() {
    if (currentPage <= 1) return 1;
    return currentPage - 1;
  }

  function getNextPage() {
    if (currentPage >= totalPages) return totalPages;
    return currentPage + 1;
  }

  function buildLinkPage(page) {
    const url = new URL(window.location.href);
    url.searchParams.set('page', page);
    return url.search;
  }

  function onChangeSelectPage(event) {
    navTo(event.target.value);
  }

  function navTo(page) {
    setCurrentPage(parseInt(page));
    onNav(parseInt(page));
    if (workWithLocation) {
      let link = buildLinkPage(page)
      history.push(link);
    }
  }

  return (
    <nav className="d-print-none">
      <ul className="pagination -with-dropdown justify-content-center">
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <Link
            className="page-link"
            to={buildLinkPage(getPrevPage())}
            onClick={e => {
              e.preventDefault();
              navTo(getPrevPage());
            }}>&laquo;</Link>
        </li>
        <li className="page-item -item-dropdown">
          <select
            className="form-control"
            disabled={totalPages == 1 ? true : false}
            value={currentPage}
            onChange={onChangeSelectPage}>
            {(() => {
              let options = [];
              for (let i = 1; i <= totalPages; i++) {
                options.push(<option key={i}>{i}</option>);
              }
              return options;
            })()}
          </select>
        </li>
        <li className={`page-item ${(currentPage >= totalPages || totalPages == 1) ? 'disabled' : ''}`}>
          <Link
            className="page-link"
            to={buildLinkPage(getNextPage())}
            onClick={e => {
              e.preventDefault();
              navTo(getNextPage());
            }}>&raquo;</Link>
        </li>
      </ul>
    </nav>
  );
}