import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import appConfig from '../../AppConfig';
import api from '../../services/api';
import CorForm from '../../components/Cor/CorForm';
import LayoutLogged from '../LayoutLogged';
import CorModel from '../../models/Cor';

export default function CorFormPage({ history }) {

  const { corId } = useParams();
  const [cor, setCor] = useState({...CorModel});

  useEffect(() => {
    if (corId) {
      loadCor(corId);
    }
  }, []);

  async function loadCor(corId) {
    let resp;
    try {
      resp = await api.get(`/cores/${corId}`);
    } catch (error) {
      if (error.response.status < 500) {
        alert(error.response.data.description);
      } else {
        alert("Ocorreu um erro no servidor e não foi possível carregar a cor. Por favor, tente novamente.");
      }
      return;      
    }
    setCor(resp.data);
  }

  function onCancel(e) {
    history.push(`${appConfig.baseURL}/cores`);
  }

  function onSaveSucess(e, resp) {
    history.push(`${appConfig.baseURL}/cores`);
  }

  return (
    <LayoutLogged>
      <CorForm 
        cor={cor} 
        onCancel={onCancel} 
        onSaveSucess={onSaveSucess} 
      />
    </LayoutLogged>
  );
}