import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import Pagination from '../../components/Pagination/Pagination';
import { BrowserRouter } from 'react-router-dom';

export default function CorList({ onSelect, filtros }) {

  const [pagina, setPagina] = useState(1);
  const [cores, setCores] = useState({
    data: [],
    current: 0,
    pages: 0,
    total: 0
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadCores();
  }, [pagina, filtros]);

  async function loadCores() {
    let resp;
    setIsLoading(true);
    try {
      resp = await api.get(`/cores?${buildSearchParams()}`);
    } catch (error) {
      setIsLoading(false);
      if (error.response.status < 500) {
        alert(error.response.data.description);
      } else {
        alert("Ocorreu um erro no servidor e não foi possível carregar os cores. Por favor, tente novamente.");
      }
      return;
    }
    setIsLoading(false);
    setCores(resp.data);
  }

  function buildSearchParams() {
    const searchParams = new URLSearchParams();
    for (let filtro in filtros) {
      searchParams.set(filtro, filtros[filtro]);
    }
    searchParams.set('page', pagina);
    return searchParams.toString();
  }

  return (
    <>
      <table className="table table-hover table-sm">
        <thead className="thead-dark">
          <tr>
            <th scope="col">Código</th>
            <th scope="col">Ações</th>
          </tr>
        </thead>
        <tbody>
          {isLoading &&
            <tr style={{padding:'20px', textAlign:'center'}}>
              <td colSpan="2">Carregando...</td>
            </tr>
          }
          {!isLoading && cores.data.length == 0 &&
            <tr style={{padding:'20px', textAlign:'center'}}>
              <td colSpan="2">Não há cores cadastradas ainda.</td>
            </tr>
          }
          {!isLoading && cores.data.length > 0 && (cores.data || []).map((cor, index) => (
            <tr key={index}>
              <td>{cor.codigo}</td>
              <td>
                <button className="mr-1"
                  onClick={e => {
                    if (onSelect) {
                      onSelect(e, cor);
                    }
                  }}>
                  Selecionar
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {cores.data.length > 0 &&
        <BrowserRouter>
          <Pagination
            workWithLocation={false}
            totalPages={cores.pages}
            onNav={pg => {
              setPagina(pg);
            }}
          />
        </BrowserRouter>
      }
    </>
  );
}