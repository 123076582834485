const DashboardHelper = {
  periodoDeMeses: (dataInicial, dataFinal) => {
    dataInicial = new Date(dataInicial.slice(0,7)+'-01T00:00:00+0000');
    dataFinal = new Date(dataFinal.slice(0,7)+'-01T00:00:00+0000');
    if (isNaN(dataInicial.getTime()) || isNaN(dataFinal.getTime())) {
      return;
    }
    let range = [];
    while (dataInicial <= dataFinal) {
      range.push(dataInicial.getUTCFullYear() + ('0'+ (dataInicial.getUTCMonth()+1)).slice(-2));
      dataInicial.setUTCMonth(dataInicial.getUTCMonth()+1);
    }
    return range;
  },

  orderByNome: (nomes) => {
    return (nomes || []).sort((a, b) => {
      if (a.label > b.label) return 1;
      if (a.label < b.label) return -1;
      return 0;
    })
  }
}

export default DashboardHelper;