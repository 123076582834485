import React from 'react';
import modalManager from '../../services/modalManager';
import MaquinaForm from './MaquinaForm';

export default function ModalMaquina(props) {

  function cancel(e) {
    if (props.onCancel) {
      props.onCancel(e);
    }
  }

  return (
    <div className="modal fade" id="modalMaquina" role="dialog" data-backdrop="static">
      <div className="modal-dialog modal-sm" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {props.maquina ? 'Editar' : 'Cadastrar'} Máquina
            </h5>
            <button type="button" className="close" onClick={modalManager.hide}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <MaquinaForm
              maquina={props.maquina ? props.maquina : null}
              onCancel={cancel}
              onSave={maquina => {
                if (props.onSave) props.onSave(maquina);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
