import React, { useState, useEffect, useContext } from 'react';
import useBodyClass from '../../hooks/body-class';
import LayoutLogged from '../LayoutLogged';
import { LoggedContext } from '../../contexts/LoggedContext';
import api from '../../services/api';
import modalManager from '../../services/modalManager';
import Menu from './Menu';
import ModalFuncionario from '../../components/Funcionario/ModalFuncionario';

export default function OperadoresPage() {

  const context = useContext(LoggedContext);
  const [isLoading, setIsLoading] = useState(false);
  const [funcionarios, setFuncionarios] = useState([]);

  useBodyClass("page-configuracao");

  useEffect(() => {
    loadFuncionarios();
  }, []);

  async function loadFuncionarios() {
    let resp;
    try {
      setIsLoading(true);
      resp = await api.get(`/funcionarios`);
    } catch (error) {
      setIsLoading(false);
      if (error.response.status < 500) {
        alert(error.response.data.description);
      } else {
        alert("Ocorreu um erro no servidor e não foi possível carregar os operadores. Por favor, tente novamente.");
      }
      return;
    }
    setFuncionarios(resp.data);
    setIsLoading(false);
  }

  function showModalCadastrarFuncionario(e) {
    showModalEditarFuncionario(e);
  }

  function showModalEditarFuncionario(e, funcionario) {
    modalManager.mount(
      'modalFuncionario',
      <ModalFuncionario
        funcionario={funcionario ? { ...funcionario } : null}
        onCancel={e => {
          e.preventDefault();
          modalManager.hide();
        }}
        onSave={funcionarioSalvo => {
          let index = funcionarios.findIndex(
            funcionario => funcionario.id === funcionarioSalvo.id
          );
          if (index > -1) {
            funcionarios[index] = funcionarioSalvo;
            setFuncionarios([...funcionarios]);
          } else {
            setFuncionarios([...funcionarios, funcionarioSalvo]);
          }
          context.reloadFuncionariosCache();
          modalManager.hide();
        }}
      />
    );
    modalManager.show('modalFuncionario');
  }

  return (
    <LayoutLogged>
      <h2 className="page-title">Configurações</h2>
      <Menu active="operadores" />
      <nav className="navbar navbar-expand navbar-light bg-light mb-3">
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item active">
              <button
                type="button"
                className="btn btn-primary"
                onClick={showModalCadastrarFuncionario}
              >Cadastrar Operador</button>
            </li>
          </ul>
        </div>
      </nav>
      <table className="table table-striped table-sm">
        <thead className="sticky thead-dark">
          <tr>
            <th style={{width:'80px'}} scope="col">Ações</th>
            <th style={{width:'80px'}} scope="col">ID</th>
            <th scope="col">Nome</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {isLoading &&
            <tr>
              <td colSpan="6" style={{ padding: '20px', textAlign: 'center' }}>
                Carregando...
              </td>
            </tr>
          }
          {!isLoading && funcionarios.length < 1 &&
            <tr>
              <td colSpan="6" style={{ padding: '20px', textAlign: 'center' }}>
                Não há Operadores cadastrados ainda.
              </td>
            </tr>
          }
          {!isLoading &&
            funcionarios.sort(function (a, b) {
              if (a.nome < b.nome) { return -1; }
              if (a.nome > b.nome) { return 1; }
              return 0;
            }).map((funcionario, index) => (
              <tr key={index}>
                <td className="sticky">
                  <button
                    type="button"
                    className="btn btn-outline-secondary mr-1"
                    onClick={e => showModalEditarFuncionario(e, funcionario)}
                  >
                    <i className="icofont-ui-edit"></i>
                  </button>
                </td>
                <td>{funcionario.id}</td>
                <td>{funcionario.nome}</td>
                <td>{funcionario.ativo ? 'Ativo' : 'Inativo'}</td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </LayoutLogged>
  );
}
