import React, { useState, useEffect, useMemo } from 'react';
import URLHelper from '../../helpers/url';
import api from '../../services/api';
import { ResponsiveBar } from '@nivo/bar';
import NivoBarConfigComponent from './NivoBarConfigComponent';

export default function EficienciaOperacional({ filtros, metadados }) {

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const [indexes1, setIndexes1] = useState([]);
  const [totalizar, setTotalizar] = useState(false);
  const [nivoBarConfig, setNivoBarConfig] = useState({
    keys: [
      "Produção",
      "Setup", 
      "Parada Programada", 
      "Parada Não Programada",
      "Sem Apontamentos"
    ],
    groupMode: 'stacked',
    colors: ['#1F9315', '#FFCC01', '#F47560', '#E25C3B', '#D6D6D6']
  });

  const dataFiltered = useMemo(() => {
    let filtrados = {};
    Object.entries(data).map(wrapper => {
      filtrados[wrapper[0]] = [];
      for (const dados of wrapper[1]) {
        if (indexes1.find(idx => idx.id === dados[filtros.classificarPor + 'Id']).checked) {
          filtrados[wrapper[0]].push(dados);
        }
      }
    });
    return format(filtrados);
  }, [data, indexes1, totalizar]);

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    let query = URLHelper.createQueryStringFromObject(filtros).toString();
    let apiResponse;
    try {
      setIsLoading(true);
      apiResponse = await api.get(`/relatorios/estamparia/eficiencia-operacional?` + query);
    } catch (error) {
      setIsLoading(false);
      if (error.response.status < 500) {
        alert(error.response.data.description);
      } else {
        alert("Ocorreu um erro no servidor e não foi possível carregar o gráfico. Por favor, tente novamente.");
      }
      return;
    }
    let data;
    if (filtros.agruparPor === '') {
      data = { "wrapper": apiResponse.data.data };
    } else {
      data = apiResponse.data.data;
    }
    extractIndexes(data);
    setData(data);
    setIsLoading(false);
  }

  function format(data) {
    const mapping = (metricas) => {
      let ret = {
        "index": metricas.index,
        "Produção": metricas.producaoPorcentagem,
        "Setup": metricas.setupPorcentagem,
        "Parada Programada": metricas.paradaProgramadaPorcentagem,
        "Parada Não Programada": metricas.paradaNaoProgramadaPorcentagem
      };
      if (filtros.classificarPor !== "operador") {
        ret["Sem Apontamentos"] = metricas.semApontamentosPorcentagem;
      }
      return ret;
    }

    if (totalizar === true) {
      let dataWithoutWrapper = [];
      Object.entries(data).map(wrapper => {
        dataWithoutWrapper = [...dataWithoutWrapper, ...wrapper[1]];
      });
      let total = calculate(dataWithoutWrapper);
      total.index = "Total";
      return [mapping(total)];
    }

    let ret = [];
    if (filtros.agruparPor === '') {
      Object.entries(data).map(wrapper => {
        for (const metricas of wrapper[1]) {
          ret.push(mapping({...metricas, index: metricas[filtros.classificarPor]}));
        }
      });
    } else {
      Object.entries(data).map(mes => {
        let total = calculate(mes[1]);
        total.index = mes[0];
        ret.push(mapping(total));
      });
    }
    return ret;
  }

  function extractIndexes(data) {
    let indexes = [];
    Object.entries(data).map(wrapper => {
      for (const conjuntoDeDados of wrapper[1]) {
        let id = conjuntoDeDados[filtros.classificarPor + 'Id'];
        if (indexes.findIndex(idx => idx.id === id) < 0) {
          indexes.push({
            id: id,
            label: conjuntoDeDados[filtros.classificarPor],
            checked: true
          });
        }
      }
    });
    setIndexes1(indexes);
  }

  function calculate(data) {
    let divisor;
    let total = {
      setupSegundos: 0,
      paradaProgramadaSegundos: 0,
      paradaNaoProgramadaSegundos: 0,
      producaoSegundos: 0,
      semApontamentosSegundos: 0,
    }
    if (filtros.classificarPor === 'operador') {
      total.totalEventosSegundos = 0;
    } else {
      total.disponibilidadeMaquinaSegundos = 0;
    }
    data.map(dado => {
      if (filtros.classificarPor === 'operador') {
        total.totalEventosSegundos += dado.totalEventosSegundos;
      } else {
        total.disponibilidadeMaquinaSegundos += dado.disponibilidadeMaquinaSegundos;
      }
      total.setupSegundos += dado.setupSegundos;
      total.paradaProgramadaSegundos += dado.paradaProgramadaSegundos;
      total.paradaNaoProgramadaSegundos += dado.paradaNaoProgramadaSegundos;
      total.producaoSegundos += dado.producaoSegundos;
      total.semApontamentosSegundos += dado.semApontamentosSegundos;
    });
    if (filtros.classificarPor === 'operador') {
      divisor = total.totalEventosSegundos;
    } else {
      divisor = total.disponibilidadeMaquinaSegundos;
    }
    if (divisor === 0) {
      divisor = 1;
    }
    total.setupPorcentagem = parseFloat(((total.setupSegundos * 100) / divisor).toFixed(2));
    total.paradaProgramadaPorcentagem = parseFloat(((total.paradaProgramadaSegundos * 100) / divisor).toFixed(2));
    total.paradaNaoProgramadaPorcentagem = parseFloat(((total.paradaNaoProgramadaSegundos * 100) / divisor).toFixed(2));
    total.producaoPorcentagem = parseFloat(((total.producaoSegundos * 100) / divisor).toFixed(2));
    total.semApontamentosPorcentagem = parseFloat(((total.semApontamentosSegundos * 100) / divisor).toFixed(2));
    return total;
  }

  return (
    <>
      {!isLoading &&
        <NivoBarConfigComponent
          config={{
            groupMode: nivoBarConfig.groupMode,
            totalizar: totalizar,
            indexesLabel: filtros.classificarPor
          }}
          indexes1={indexes1}
          onChangeIndexes1={indexes => {
            setIndexes1(indexes);
          }}
          onChangeGroupMode={groupMode => {
            nivoBarConfig.groupMode = groupMode;
            setNivoBarConfig({ ...nivoBarConfig });
          }}
          onChangeTotalizar={totalizar => setTotalizar(totalizar)}
        />
      }
      <h3 className="dashboard-title">Eficiência Operacional</h3>
      <div className="dashboard-chart-description">
        {metadados.filtrosDescricao}
      </div>
      {isLoading &&
        <div>Carregando gráfico...</div>
      }
      {!isLoading &&
        <>
          <div style={{ height: '500px' }}>
            <ResponsiveBar
              data={dataFiltered}
              keys={nivoBarConfig.keys}
              groupMode={nivoBarConfig.groupMode}
              indexBy="index"
              margin={ {top: 10, right: 160, bottom: 150, left: 40} }
              padding={0.3}
              valueScale={{ type: 'linear' }}
              indexScale={{ type: 'band', round: true }}
              colors={nivoBarConfig.colors}
              borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              labelFormat={v => `${Number(v).toLocaleString('pt-BR')}%`}
              valueFormat={v => `${Math.round(v)}%`}
              legends={[
                {
                  dataFrom: 'keys',
                  toggleSerie: true,
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: 120,
                  translateY: 0,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: 'left-to-right',
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemOpacity: 1
                      }
                    }
                  ]
                }
              ]}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -45,
                format: (value) => {
                  if (filtros.agruparPor === 'mes' && totalizar === false) {
                    return value.slice(4,6) + "/" +value.slice(0,4);
                  }
                },
                legend: filtros.filtrosToString,
                legendPosition: 'middle',
                legendOffset: 370
              }}
              axisLeft={{
                format: value => `${Number(value).toLocaleString('pt-BR')}%`,
              }}
              tooltip={({ id, indexValue, value, color }) => {
                return <>
                  <div style={{ padding: 5, background: '#fff' }}>
                    <div style={{backgroundColor:color, display:'inline-block', height:'15px', marginRight:'10px', width:'20px'}}></div>
                    {id}: {Number(value).toLocaleString('pt-BR')}%
                  </div>
                </>
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor="blacks"
              motionStiffness={90}
              motionDamping={15}
            />
          </div>
        </>
      }
    </>
  );
}