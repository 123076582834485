import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import appConfig from '../../AppConfig';
import api from '../../services/api';
import ClienteForm from '../../components/Cliente/ClienteForm';
import LayoutLogged from '../LayoutLogged';

export default function ClienteFormPage({ history }) {

  const { clienteId } = useParams();
  const [cliente, setCliente] = useState();

  useEffect(() => {
    loadCliente();
  }, []);

  function loadCliente() {
    api.get(`/clientes/${clienteId}`)
      .then((response) => {
        setCliente(response.data);
      })
      .catch((error) => {
        if (error.response.status < 500) {
          alert(error.response.data.description);
        } else {
          alert("Ocorreu um erro no servidor e não foi possível carregar o Cliente.");
        }
      });
  }

  function onCancel(e) {
    history.push(`${appConfig.baseURL}/clientes`);
  }

  function onSaveSucess() {
    history.push(`${appConfig.baseURL}/clientes`);
  }

  return (
    <LayoutLogged>
      <ClienteForm 
        cliente={cliente}
        onCancel={onCancel}
        onSaveSucess={onSaveSucess}
      />
    </LayoutLogged>
  );
}