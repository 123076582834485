import React, { useState } from 'react';

export default function MenuGroupMode({checked, onChange, ...props}) 
{
  const [_checked, _setChecked] = useState();

  if (_checked === undefined) {
    _setChecked(checked || "stacked");
  }

  const uid = (new Date()).getTime();

  function setGroupMode(e) {
    _setChecked(e.target.value);
    if (onChange) {
      onChange(e.target.value);
    }
  }

  return (
    <div className="btn-group btn-group-toggle mr-3" role="group">
      <label className={`btn btn-outline-primary btn-check ${_checked === "grouped" ? 'active':''}`} title="Barras lado a lado">
        <input 
          type="radio" 
          checked={_checked === "grouped" ? true : false}
          value="grouped"
          onChange={setGroupMode}
          autoComplete="off" 
          id={`dashboardGroupGrouped${uid}`}
        />
        <i className="icofont-bars"></i>
      </label>
      <label className={`btn btn-outline-primary btn-check ${_checked === "stacked" ? 'active':''}`} title="Barra única">
        <input 
          type="radio" 
          checked={_checked === "stacked" ? true : false}
          value="stacked"
          onChange={setGroupMode}
          autoComplete="off" 
          id={`dashboardGroupStacked${uid}`} 
        />
        <i className="icofont-database"></i>
      </label>
    </div>
  );
}