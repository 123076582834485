import React from 'react';

export default function InputTypeInt(
    { onChange, value, className, style, ...rest }
) {

    style = { textAlign: 'right', ...style }

    function numberFormat(number) {
        if ( !number || isNaN(number)) {
            return 0;
        }
        return parseInt(number);
    };

    return (
        <input
            type="text"
            className={className}
            style={style}
            value={value === null ? "" : numberFormat(value)}
            onKeyPress={e => {
                if (/[0-9]/.test(e.key) == false) {
                    e.preventDefault();
                }
            }}
            onChange={e => {
                let v = parseInt(e.target.value);
                v = isNaN(v) ? 0 : v;
                if (onChange) {
                    onChange(v, e);
                }
            }}
            {...rest}
        />
    );
}