import React, { useState, useEffect, useContext } from 'react';
import modalManager from '../../services/modalManager';
import useBodyClass from '../../hooks/body-class';
import { LoggedContext } from '../../contexts/LoggedContext';
import LayoutLogged from '../LayoutLogged';
import api from '../../services/api';
import Menu from './Menu';
import ModalMaquina from '../../components/Maquina/ModalMaquina';

export default function MaquinasPage() {

  const context = useContext(LoggedContext);
  const [isLoading, setIsLoading] = useState(false);
  const [maquinas, setMaquinas] = useState([]);

  useBodyClass("page-configuracao");

  useEffect(() => {
    loadMaquinas();
  }, []);

  async function loadMaquinas() {
    let resp;
    try {
      setIsLoading(true);
      resp = await api.get(`/maquinas`);
    } catch (error) {
      setIsLoading(false);
      if (error.response.status < 500) {
        alert(error.response.data.description);
      } else {
        alert("Ocorreu um erro no servidor e não foi possível carregar as máquinas. Por favor, tente novamente.");
      }
      return;
    }
    setMaquinas(resp.data.data);
    setIsLoading(false);
  }

  function showModalCadastrarMaquina(e) {
    showModalEditarMaquina(e);
  }

  function showModalEditarMaquina(e, maquina) {
    modalManager.mount(
      'modalMaquina',
      <ModalMaquina
        maquina={maquina ? { ...maquina } : null}
        onCancel={e => {
          e.preventDefault();
          modalManager.hide();
        }}
        onSave={maquinaSalva => {
          let index = maquinas.findIndex(
            maquina => maquina.id === maquinaSalva.id
          );
          if (index > -1) {
            maquinas[index] = maquinaSalva;
            setMaquinas([...maquinas]);
          } else {
            setMaquinas([...maquinas, maquinaSalva]);
          }
          context.reloadMaquinasCache();
          modalManager.hide();
        }}
      />
    );
    modalManager.show('modalMaquina');
  }

  return (
    <LayoutLogged>
      <h2 className="page-title">Configurações</h2>
      <Menu active="maquinas" />
      <nav className="navbar navbar-expand navbar-light bg-light mb-3">
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item active">
              <button
                type="button"
                className="btn btn-primary"
                onClick={showModalCadastrarMaquina}
              >Cadastrar Máquina</button>
            </li>
          </ul>
        </div>
      </nav>
      <table className="table table-striped table-sm">
        <thead className="sticky thead-dark">
          <tr>
            <th scope="col">Ações</th>
            <th scope="col">ID</th>
            <th scope="col">Nome</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {isLoading &&
            <tr>
              <td colSpan="4" style={{ padding: '20px', textAlign: 'center' }}>
                Carregando...
              </td>
            </tr>
          }
          {!isLoading && maquinas.length < 1 &&
            <tr>
              <td colSpan="4" style={{ padding: '20px', textAlign: 'center' }}>
                Não há Operadores cadastrados ainda.
              </td>
            </tr>
          }
          {!isLoading &&
            maquinas.sort(function (a, b) {
              if (a.nome < b.nome) { return -1; }
              if (a.nome > b.nome) { return 1; }
              return 0;
            }).map((maquina, index) => (
              <tr key={index}>
                <td style={{width:'80px'}} className="sticky">
                  <button
                    type="button"
                    className="btn btn-outline-secondary mr-1"
                    onClick={e => showModalEditarMaquina(e, maquina)}
                  >
                    <i className="icofont-ui-edit"></i>
                  </button>
                </td>
                <td style={{width:'80px'}}>{maquina.id}</td>
                <td>{maquina.nome}</td>
                <td>{maquina.status}</td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </LayoutLogged>
  );
}
