import React from 'react';

export default function NivoBarConfigComponent({
  config, 
  indexes1,
  onChangeIndexes1,
  onChangeGroupMode, 
  onChangeTotalizar, 
  ...props
}) {

  const uid = (new Date()).getTime();
  
  function setGroupMode(opt) {
    if (onChangeGroupMode) {
      onChangeGroupMode(opt);
    }
  }

  function setTotalizar(opt) {
    if (onChangeTotalizar) {
      onChangeTotalizar(opt);
    }
  }

  function changeIndex(e) {
    if (onChangeIndexes1) {
      indexes1[indexes1.findIndex(elm => elm.id === parseInt(e.target.value))].checked = e.target.checked;
      onChangeIndexes1([...indexes1]);
    }
  }

  return (
    <div className="position-relative">
      <div className="position-absolute" style={{right: 0}}>

        <div className="custom-control custom-switch d-inline-flex mr-3">
          <input 
            type="checkbox" 
            className="custom-control-input" 
            id={`filtroTotalizar-${uid}`}
            onChange={e => setTotalizar(e.target.checked)}
            checked={config.totalizar}
          />
          <label className="custom-control-label" htmlFor={`filtroTotalizar-${uid}`}>
            Totalizar
          </label>
        </div>

        <div className="btn-group btn-group-toggle mr-3" data-toggle="buttons">
          <label title="Empilhado" className={`btn btn-outline-primary ${config.groupMode === "stacked" ? 'active':''}`}>
            <input
              type="radio"
              checked={config.groupMode === "stacked"}
              value="stacked"
              onChange={e => setGroupMode(e.target.value)}
            />
            <i className="icofont-database"></i>
          </label>

          <label title="Barras" className={`btn btn-outline-primary ${config.groupMode === "grouped" ? 'active':''}`}>
            <input
              type="radio"
              checked={config.groupMode === "grouped"}
              value="grouped"
              onChange={e => setGroupMode(e.target.value)}
            />
            <i className="icofont-bars"></i>
          </label>
        </div>

        <div className="dropdown d-inline-flex text-right">
          <button 
            className="btn btn-primary dropdown-toggle" 
            type="button" 
            data-toggle="dropdown" 
            aria-expanded="false"
            style={{textTransform: "capitalize"}}
          >
            {config.indexesLabel + 's'}
          </button>
          <div className="dropdown-menu dropdown-menu-right">
            <form className="px-3 py-2">
              <div className="form-group">
                {(indexes1 || []).map((index, i) => 
                  <div className="form-check" key={i}>
                    <label className="form-check-label">
                      <input 
                        className="form-check-input" 
                        type="checkbox" 
                        checked={index.checked}
                        value={index.id} 
                        onChange={changeIndex}
                      />
                      {index.label}
                    </label>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>

      </div>
    </div>
  );
}