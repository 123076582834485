import React, { useEffect, useState, createContext } from 'react';
import auth from "../services/auth";
import api from '../services/api';
import EstampariaModel from '../models/Estamparia';

export const LoggedContext = createContext();

export default function LoggedProvider(props) {

  const [usuario, setUsuario] = useState(auth.getUsuario());
  const [estamparias, setEstamparias] = useState();
  const [estamparia, setEstamparia] = useState({ ...EstampariaModel });
  const [maquinasCache, setMaquinasCache] = useState();
  const [funcionariosCache, setFuncionariosCache] = useState([]);
  const [areaDeTransferencia, setAreaDeTransferencia] = useState(); // Area de transferencia para transitar dados teporários entre páginas

  useEffect(() => {
    if (usuario.role === 'estamparia') {
      setEstamparia(auth.getUsuario());
    }
    if (usuario.role === 'gestor') {
      loadEstamparias();
    }
  }, []);

  useEffect(() => {
    if (estamparia.id > 0) {
      loadFuncionariosCache();
      loadMaquinasCache();
    }
  }, [estamparia]);

  async function refreshEstamparia(estampariaModificada) {
    if (usuario.role === 'estamparia') {
      await auth.refreshToken();
      setEstamparia(auth.getUsuario());
    } else if (usuario.role === 'gestor') {
      let index = estamparias.indexOf(estamparia);
      estamparias[index] = estampariaModificada;
      setEstamparia(estampariaModificada);
    }
  }

  async function loadEstamparias() {
    let resp;
    try {
      resp = await api.get('/estamparias');
    } catch (error) {
      if (error.response.status < 500) {
        alert(error.response.data.description);
      } else {
        alert("Ocorreu um erro no servidor e não foi possível carregar as estamparias. Por favor, tente novamente.");
      }
      return;
    }
    setEstamparias([{ ...EstampariaModel }, ...resp.data]);
  }

  async function loadMaquinasCache() {
    let resp;
    try {
      resp = await api.get('/maquinas');
    } catch (error) {
      if (error.response.status < 500) {
        alert(error.response.data.description);
      } else {
        alert("Ocorreu um erro no servidor e não foi possível carregar as Máquinas. Por favor, tente novamente.");
      }
      return;
    }
    setMaquinasCache([...resp.data.data]);
  }

  async function reloadMaquinasCache() {
    await loadMaquinasCache();
  }

  async function loadFuncionariosCache() {
    let resp;
    try {
      resp = await api.get('/funcionarios');
    } catch (error) {
      if (error.response.status < 500) {
        alert(error.response.data.description);
      } else {
        alert("Ocorreu um erro no servidor e não foi possível carregar os Funcionários. Por favor, tente novamente.");
      }
      return;
    }
    setFuncionariosCache([...resp.data]);
  }

  async function reloadFuncionariosCache() {
    await loadFuncionariosCache();
  }

  return (
    <LoggedContext.Provider value={{ 
      usuario, 
      estamparias, 
      estamparia, 
      setEstamparia, 
      refreshEstamparia,
      funcionariosCache,
      maquinasCache,
      reloadMaquinasCache,
      reloadFuncionariosCache,
      areaDeTransferencia,
      setAreaDeTransferencia
    }}>
      {props.children}
    </LoggedContext.Provider>
  );
}