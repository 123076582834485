import React from 'react';
import modalManager from '../../services/modalManager';
import FuncionarioForm from './FuncionarioForm';

export default function ModalFuncionario(props) {

  function cancel(e) {
    if (props.onCancel) {
      props.onCancel(e);
    }
  }

  return (
    <div className="modal fade" id="modalFuncionario" role="dialog" data-backdrop="static">
      <div className="modal-dialog modal-sm" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {props.funcionario ? 'Editar' : 'Cadastrar'} Funcionário
            </h5>
            <button type="button" className="close" onClick={modalManager.hide}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <FuncionarioForm
              funcionario={props.funcionario ? props.funcionario : null}
              onCancel={cancel}
              onSave={funcionario => {
                if (props.onSave) props.onSave(funcionario);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
