import React from 'react';
import modalManager from '../../services/modalManager';

export default function RemoveDisponibilidadeDialog({onConfirm, onCancel}) {
  return (
    <div className="modal fade" id="removeMaquinaDialog" role="dialog" data-backdrop="static">
      <div className="modal-dialog modal-small" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Remover Disponibilidade da Máquina</h5>
            <button type="button" className="close" onClick={modalManager.hide}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            Deseja remover esta Disponibilidade da Máquina? Você ainda precisará salvar o formulário para concretizar a operação.
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={onCancel}>Cancelar</button>
            <button type="button" className="btn btn-danger" onClick={onConfirm}>Remover</button>
          </div>
        </div>
      </div>
    </div>
  );
}