const MateriaPrima = {
    id: 0,
    estampariaId: 0,
    tipo: 'pigmento',
    codigo: '',
    unidadeMedida: '',
    custo: 0.00,
    fornecedorId: 0,
    lote: '',
    fornecedor: null
}

export default MateriaPrima;