import React, { useState } from 'react';
import CorList from './CorList';
import { BrowserRouter } from 'react-router-dom';
import FiltroDeCor from './FiltroDeCor';

export default function ModalSelecaoDeCor({ onSelect }) {

  const [filtros, setFiltros] = useState();

  return (
    <BrowserRouter>
      <div className="modal fade" id="modalCorList" role="dialog" data-backdrop="static">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Seleção de Cor</h5>
              <button type="button" className="close" data-dismiss="modal">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <FiltroDeCor
                workWithLocation={false}
                onSubmit={(filtros) => {
                  setFiltros({...filtros});
                }}
                onClear={() => {
                  setFiltros({});
                }}
              />
              <CorList
                filtros={filtros}
                onSelect={(e, cor) => {
                  if (onSelect) {
                    onSelect(e, cor);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
}