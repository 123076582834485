import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import FornecedorModel from '../../models/Fornecedor';

export default function FornecedorForm(props) {

  const [fornecedor, setFornecedor] = useState({...FornecedorModel});

  useEffect(() => {
    if (props.fornecedor) {
      setFornecedor({...props.fornecedor});
    }
  }, [props.fornecedor]);

  async function saveFornecedor(e) {
    e.preventDefault();
    api.post('/fornecedores', fornecedor)
      .then((response) => {
        if (props.onSaveSucess) props.onSaveSucess(response);
      })
      .catch((error) => {
        if (error.response.status < 500) {
          alert(error.response.data.description);
        } else {
          alert("Ocorreu um erro no servidor.");
        }
      });
  }

  function cancel() {
    if (props.onCancel) props.onCancel();
  }

  return (
    <>
      {(props.showPageTitle || props.showPageTitle == undefined) &&
        <h2 className="page-title">
          {fornecedor ? 'Editar' : 'Cadastrar'} Fornecedor:
        </h2>
      }
      <div>
        <form className="cor-form" onSubmit={saveFornecedor}>
          <div className="form-group">
            <label>Nome:</label>
            <input
              type="text"
              className="form-control"
              value={fornecedor.nome}
              onChange={e => {
                fornecedor.nome = e.target.value;
                setFornecedor({ ...fornecedor });
              }}
            />
          </div>
          <div className="btn-group mr-2" role="group">
            <button type="button" className="btn btn-secondary" onClick={cancel}>Cancelar</button>
          </div>
          <div className="btn-group" role="group">
            <button type="submit" className="btn btn-primary">Salvar</button>
          </div>
        </form>
      </div>
    </>
  );
}