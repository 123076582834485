import React, { useState } from 'react';

export default function MenuDropdown({
  title, 
  staticValues,
  values, 
  setValues, 
  onChange, 
  ...props
}) {
  const [_staticValues, _setStaticValues] = useState(staticValues ? [...staticValues] : null);

  function _setValues(newValues) {
    if (staticValues) {
      _setStaticValues([...newValues]);
    } else {
      setValues([...newValues]);
    }
    if (onChange) {
      onChange([...newValues]);
    }
  }

  return (
    <>
      <div className="dropdown d-inline-flex text-right mr-1">
        <button 
          className={`btn ${props.btnClass ?? 'btn-primary'} dropdown-toggle`} 
          type="button" 
          data-toggle="dropdown" 
          aria-expanded="false"
          style={{textTransform: "capitalize"}}
        >
          {title}
        </button>
        <div className="dropdown-menu dropdown-menu-right">
          <div className="px-3 py-2" onClick={e => e.stopPropagation()}>
            <div style={{ whiteSpace: 'nowrap' }}>
              <button
                type="button"
                className="btn btn-link btn-sm"
                onClick={(event) => {
                  (_staticValues || values || []).map(v => v.checked = true);
                  _setValues(_staticValues ? [..._staticValues] : [...values]);
                }}>Todos</button>|
              <button
                type="button"
                className="btn btn-link btn-sm"
                onClick={(event) => {
                  (_staticValues || values || []).map(v => v.checked = false);
                  _setValues(_staticValues ? [..._staticValues] : [...values]);
                }}>Nenhum</button>
            </div>
            <div className="form-group">
              {(_staticValues || values || []).map((value, i) => 
                <div className={`form-check ${props.itemClass ?? ""}`} key={i}>
                  <label className="form-check-label">
                    <input 
                      className="form-check-input" 
                      type="checkbox" 
                      checked={value.checked}
                      value={value.id} 
                      onChange={e => {
                        value.checked = e.target.checked;
                        _setValues(_staticValues ? [..._staticValues] : [...values]);
                      }}
                    />
                    {value.label}
                  </label>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}