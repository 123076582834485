import React from 'react';
import useBodyClass from '../hooks/body-class';
import Logo from '../assets/images/logo2.png';
import Footer from '../components/Footer/Footer';
import './LayoutUnlogged.css';

export default function LayoutLogout(props) {

  useBodyClass("layout-unlogged");

  return (
    <>
      <header className="container" id="header">
        <div className="row">
          <div className="col">
            <img src={Logo} className="logo" />
          </div>
        </div>
      </header>
      <main className="container" id="main">
        <div className="row">
          <div className="col" id="routeContent">
            {props.children}
          </div>
        </div>
      </main>
      <Footer></Footer>
    </>
  );
}