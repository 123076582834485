import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import FuncionarioModel from '../../models/Funcionario';

export default function FuncionarioForm(props) {

  const [funcionario, setFuncionario] = useState({ ...FuncionarioModel });
  const [savingFlag, setSavingFlag] = useState(false);

  useEffect(() => {
    if (props.funcionario) {
      setFuncionario({ ...props.funcionario });
    }
  }, [props.funcionario]);

  async function saveFuncionario(e) {
    e.preventDefault();
    setSavingFlag(true);
    let resp;
    try {
      resp = await api.post('/funcionarios', funcionario);
    } catch (error) {
      if (error.response.status < 500) {
        alert(error.response.data.description);
      } else {
        alert("Ocorreu um erro no servidor. Tente novamente ou entre em contato com o suporte.");
      }
      setSavingFlag(false);
      return;
    }
    setSavingFlag(false);
    if (props.onSave) {
      props.onSave(resp.data);
    }
  }

  function cancel(e) {
    if (props.onCancel) props.onCancel(e);
  }

  return (
    <>
      <div>
        <form className="cor-form" onSubmit={saveFuncionario}>
          <div className="form-group">
            <label>Nome:</label>
            <input
              type="text"
              className="form-control"
              value={funcionario.nome}
              onChange={e => {
                funcionario.nome = e.target.value;
                setFuncionario({ ...funcionario });
              }}
            />
          </div>
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                checked={funcionario.ativo}
                onChange={e => {
                  funcionario.ativo = e.target.checked;
                  setFuncionario({ ...funcionario });
                }}
              /> Ativo
            </label>
          </div>
          <div className="btn-group mr-2" role="group">
            <button type="button" className="btn btn-secondary" onClick={cancel}>Cancelar</button>
          </div>
          <div className="btn-group" role="group">
            <button type="submit" className={`btn btn-primary ${savingFlag ? 'disabled':''}`}>
              {savingFlag ? 'Salvando' : 'Salvar'}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}