import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import './PrintPage.css';
import LayoutLogged from '../LayoutLogged';
import moment from 'moment';
import api from '../../services/api';
import {
  OrdemProducao as OrdemProducaoModel,
  Pasta as PastaModel,
} from '../../models/OPEstamparia';

export default function PrintPage() {

  const { ordemId } = useParams();
  const [ordem, setOrdem] = useState({
    ...OrdemProducaoModel,
    pastas: [{ ...PastaModel }]
  });

  useEffect(() => {
    if (ordemId) {
      loadOrdem();
    }
  }, []);

  async function loadOrdem() {
    const response = await api.get(`/op-estamparia/${ordemId}`);
    setOrdem(response.data);
  }

  return (
    <LayoutLogged>
      <div className="print">

        <h2 className="page-title">Ordem de Produção de Estamparia {ordem.id}</h2>

        <table className="mb-2">
          <tbody>
            <tr>
              <td className="label text-right" style={{ width: '120px' }}>Cliente:</td>
              <td className="value">{ordem.cliente && ordem.cliente.nome}</td>
              <td className="label text-right" style={{ width: '120px' }}>Lacre Piloto:</td>
              <td className="value">{ordem.lacrePiloto}</td>
              <td className="label text-right" style={{ width: '130px' }}>Nota Fiscal:</td>
              <td className="value">{ordem.notaFiscal}</td>
            </tr>
            <tr>
              <td className="label text-right">Refer.:</td>
              <td className="value">{ordem.referencia}</td>
              <td className="label text-right">Data Entrada:</td>
              <td className="value">{ordem.entrada ? moment(ordem.entrada).format('DD/MM/YYYY HH:mm') : ''}</td>
              <td className="label text-right">O.F. do Cliente:</td>
              <td className="value">{ordem.ofCliente}</td>
            </tr>
            <tr>
              <td className="label text-right">Marca:</td>
              <td className="value">{ordem.marca}</td>
              <td className="label text-right">Data Entrega:</td>
              <td className="value">{ordem.entrega ? moment(ordem.entrega).format('DD/MM/YYYY HH:mm') : ''}</td>
              <td className="label text-right">Quantidade:</td>
              <td className="value">{ordem.quantidade}</td>
            </tr>
          </tbody>
        </table>

        <table className="mb-4">
          <caption>PREPARAÇÃO DAS PASTAS:</caption>
          <thead>
            <tr>
              <th>Cor Peça:</th>
              <th>Técnica:</th>
              <th>Cura:</th>
              <th>Cores:</th>
            </tr>
          </thead>
          <tbody>
            {(ordem.pastas || []).map((pasta, index) => (
              <tr key={index}>
                <td>{pasta.corPeca}</td>
                <td>{pasta.tecnica}</td>
                <td>{pasta.cura}</td>
                <td>
                  {(pasta.cores || []).map((cor, corIndex) => {
                    return (index == (pasta.cores.length - 1))
                      ? cor.codigo
                      : cor.codigo + ', ';
                  })}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <table className="mb-2">
          <tbody>
            <tr>
              <td className="label text-center" colSpan="2">Informações para produção:</td>
            </tr>
            <tr>
              <td className="label text-right" style={{ width: "130px" }}>Quadro:</td>
              <td className="value">{ordem.quadro}</td>
            </tr>
            <tr>
              <td colSpan="2">{ordem.informacoesProducao ? ordem.informacoesProducao : <><br /><br /><br /><br /></>}</td>
            </tr>
          </tbody>
        </table>

        <table className="maquina mb-2">
          <caption>DADOS DE PRODUÇÃO:</caption>
          {
          // <tbody>
          //   <tr>
          //     <td className="label text-right" style={{ width: "110px" }}>Máquina:</td>
          //     <td className="value">{ordem.maquina}</td>
          //   </tr>
          //   <tr>
          //     <td className="label text-right" style={{ width: "110px" }}>Operador:</td>
          //     <td className="value">{ordem.operador}</td>
          //   </tr>
          // </tbody>
          }
        </table>

        <div className="eventos-container mb-2">

          <table className="eventos">
            <thead>
              <tr>
                <th style={{width:'1cm'}}>Máq.</th>
                <th>Operador</th>
                <th style={{width:'2cm'}}>Motivo</th>
                <th style={{width:'2cm'}}>Data</th>
                <th style={{width:'2cm'}}>Hora Início</th>
                <th style={{width:'2cm'}}>Hora Fim</th>
              </tr>
            </thead>
            <tbody>
              {
                (() => {
                    let rows = [];
                    for(let i=0; i < 12; i++) {
                      rows.push(
                        <tr key={i}>
                          <td>&nbsp;</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      );
                    }
                    return rows;
                  }
                )()
              }
            </tbody>
          </table>

          <table className="eventos">
            <thead>
              <tr>
                <th style={{width:'1cm'}}>Máq.</th>
                <th>Operador</th>
                <th style={{width:'2cm'}}>Motivo</th>
                <th style={{width:'2cm'}}>Data</th>
                <th style={{width:'2cm'}}>Hora Início</th>
                <th style={{width:'2cm'}}>Hora Fim</th>
              </tr>
            </thead>
            <tbody>
              {
                (() => {
                    let rows = [];
                    for(let i=0; i < 12; i++) {
                      rows.push(
                        <tr key={i}>
                          <td>&nbsp;</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      );
                    }
                    return rows;
                  }
                )()
              }
            </tbody>
          </table>
        
        </div>

        <div className="tipologia-container mb-2">
          <div className="grupo programadas">
            <div className="titulo">Tipologia Paradas Programadas</div>
            <ul>
              <li>
                <span className="sigla">INT</span>
                <span>INTERVALOS DE OPERAÇÃO</span>
              </li>
              <li>
                <span className="sigla">LPZ</span>
                <span>LIMPEZA DE MÁQUINAS</span>
              </li>
              <li>
                <span className="sigla">MPV</span>
                <span>MANUTENÇÃO PREVENTIVA</span>
              </li>
              <li>
                <span className="sigla">RCP</span>
                <span>RASPAR CHAPAS</span>
              </li>
              <li>
                <span className="sigla">REF</span>
                <span>REFEIÇÕES, ALMOÇO/JANTAR</span>
              </li>
              <li>
                <span className="sigla">RNT</span>
                <span>REUNIÕES, TREINAMENTOS</span>
              </li>
              <li>
                <span className="sigla">TQC</span>
                <span>TROCA DE QUADROS/CORES</span>
              </li>
            </ul>
          </div>

          <div className="grupo naoprogramadas">
            <div className="titulo">Tipologia Paradas Não Programadas</div>
            <ul>
              <li>
                <span className="sigla">AJC</span>
                <span>AJUSTES DE CORES</span>
              </li>
              <li>
                <span className="sigla">CSQ</span>
                <span>CONSERTO DE QUADROS</span>
              </li>
              <li>
                <span className="sigla">FAP</span>
                <span>FALTA DE PESSOAL</span>
              </li>
              <li>
                <span className="sigla">FFR</span>
                <span>FALTA DE FERRAMENTAS</span>
              </li>
              <li>
                <span className="sigla">LCC</span>
                <span>LIMPEZA DE CISCO</span>
              </li>
              <li>
                <span className="sigla">FMP</span>
                <span>FALTA DE MATÉRIA PRIMA</span>
              </li>
              <li>
                <span className="sigla">MCR</span>
                <span>MANUTENÇÃO CORRETIVA</span>
              </li>
              <li>
                <span className="sigla">QBL</span>
                <span>FALTA DE PRODUÇÃO</span>
              </li>
            </ul>
          </div>
        </div>

        <table>
          <tbody>
            <tr>
              <td className="label text-center" colSpan="11">Peças 2ª Qualidade</td>
            </tr>
            <tr>
              <td className="label text-right" style={{width:"110px"}}>Tamanho:</td>
              <td className="value"></td>
              <td className="value"></td>
              <td className="value"></td>
              <td className="value"></td>
              <td className="value"></td>
              <td className="value"></td>
              <td className="value"></td>
              <td className="value"></td>
              <td className="value"></td>
              <td className="value"></td>
            </tr>
            <tr>
              <td className="label text-right">Variante:</td>
              <td className="value"></td>
              <td className="value"></td>
              <td className="value"></td>
              <td className="value"></td>
              <td className="value"></td>
              <td className="value"></td>
              <td className="value"></td>
              <td className="value"></td>
              <td className="value"></td>
              <td className="value"></td>
            </tr>
            <tr>
              <td className="label text-right">Quantidade:</td>
              <td className="value"></td>
              <td className="value"></td>
              <td className="value"></td>
              <td className="value"></td>
              <td className="value"></td>
              <td className="value"></td>
              <td className="value"></td>
              <td className="value"></td>
              <td className="value"></td>
              <td className="value"></td>
            </tr>
          </tbody>
        </table>

      </div>

    </LayoutLogged>
  );
}